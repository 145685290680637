import React from 'react'
import { T } from '@transifex/react'

import { CoreIoUserDatetimeFormatLocaleChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const dateTimeFormatLocalesLabelMap: Record<
  CoreIoUserDatetimeFormatLocaleChoices,
  React.ReactNode
> = {
  EN_GB: <T _str="English (UK)" />,
  EN: <T _str="English (US)" />,
  DA: <T _str="Danish" />,
  SV: <T _str="Swedish" />,
  NB: <T _str="Norwegian" />,
  AR: <T _str="Arabic" />,
}

export const dateTimeFormatLocalesOptions = createOptionsFromLabelMap(
  dateTimeFormatLocalesLabelMap
)
