import React, { FC } from 'react'
import { CarOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'

import {
  TVehicleStatus,
  vehicleStatusColorMap,
  vehicleStatusLabelMap,
} from 'src/api/consts'
import useDelayQuery from 'src/hooks/useDelayQuery'
import { useRoutingHistory } from 'src/reducers/routing/child-router-factory'
import { TagOption } from 'src/storybook/components'
import { array } from 'src/utils/array'

import {
  GetVehicleInfoPopover,
  GetVehicleInfoPopoverVariables,
} from './__generated__/types'
import { GET } from './api'
import { PopupContentProps } from '..'
import InfoCard from '../components/InfoCard'

const VehiclePopup: FC<PopupContentProps> = ({ id }) => {
  const [history, { routingMap }] = useRoutingHistory()
  const { data, loading } = useDelayQuery<
    GetVehicleInfoPopover,
    GetVehicleInfoPopoverVariables
  >(
    GET,
    {
      variables: {
        id,
      },
    },
    300
  )

  const status = data?.vehicle?.status ?? ''
  const vehicleType = data?.vehicle?.vehicleType.name ?? ''

  return (
    <InfoCard
      title={data?.vehicle?.name ?? ''}
      avatarProps={{
        src: data?.vehicle?.logo,
        shape: 'square',
      }}
      headIcon={<CarOutlined />}
      headText={<T _str="Vehicles" />}
      data={array(
        !!data?.vehicle?.activeDepot && {
          icon: (
            <EnvironmentOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />
          ),
          label: data?.vehicle?.activeDepot.name,
        }
      )}
      tags={array<TagOption | string>(
        status && {
          label: vehicleStatusLabelMap[status as TVehicleStatus],
          color: vehicleStatusColorMap[status as TVehicleStatus],
        },
        vehicleType && vehicleType
      )}
      onView={() => {
        history.push(
          routingMap.app['fleet-management'].vehicles[':vehicleId'](id)._
        )
      }}
      loading={loading}
    />
  )
}

export default VehiclePopup
