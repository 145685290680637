import React, { useEffect, useState } from 'react'
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router'
import { Spin } from 'src/antd'

import { GET_ACTIVE_PROJECTS_DETAILS, useLogoutMutation } from 'src/api'
import { GetActiveProjectsDetails } from 'src/api/__generated__/types'
import { setRouting, setUser } from 'src/reactive-variables'
import { routingVar } from 'src/reactive-variables/routing'
import { impersonatorTokenActiveProjectsKey } from 'src/utils/impersonator'

import { MUTATION_VERIFY_TOKEN, GET_ME } from './api'
import { checkForRedirects } from './utils'

const Router: React.FC = ({ children }) => {
  const history = useHistory()
  const { init } = useReactiveVar(routingVar)
  const [anyOtherActionRequired, setAnyOtherActionRequired] = useState(true)
  const client = useApolloClient()
  const [checkTokenMutationQuery] = useMutation(MUTATION_VERIFY_TOKEN)
  const [logoutMutationQuery] = useLogoutMutation()

  useEffect(() => {
    // init when the app is loaded;
    async function getUser() {
      try {
        const response = await client.query({ query: GET_ME })
        const lastActiveProjects = localStorage
          .getItem(impersonatorTokenActiveProjectsKey)
          ?.split(',')
        // Load impersonated active projects if they were stored
        const activeProjectsResponse =
          lastActiveProjects?.length &&
          (await client.query<GetActiveProjectsDetails>({
            query: GET_ACTIVE_PROJECTS_DETAILS,
            variables: { ids: lastActiveProjects },
          }))
        if (response.data && response.data.me) {
          setUser({
            ...response.data.me,
            activeProjects: activeProjectsResponse
              ? activeProjectsResponse.data?.allProjects
              : response.data.me.activeProjects,
            activeProjectsIds:
              lastActiveProjects || response.data.me.activeProjectsIds,
          })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('err', e)
      }
    }

    function setUserAsNonAuthenticated() {
      setRouting({
        roles: [`non-authenticated`],
        init: true,
      })
      window.localStorage.removeItem('reactQLJWT')
    }

    async function checkTokenRefreshTokenOrLogout() {
      const currentReactQLJWTToken = window.localStorage.getItem('reactQLJWT')
      // const rememberMe = window.localStorage.getItem('rememberMe')

      if (currentReactQLJWTToken) {
        checkTokenMutationQuery({
          variables: {
            token: currentReactQLJWTToken,
          },
        })
          .then(({ data: { verifyToken = null } = {} }) => {
            if (verifyToken) {
              getUser()
            } else {
              setUserAsNonAuthenticated()
            }
          })
          .catch(() => {
            setUserAsNonAuthenticated()
          })
      } else {
        setUserAsNonAuthenticated()
      }
    }

    checkTokenRefreshTokenOrLogout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTokenMutationQuery, client, history, logoutMutationQuery])

  useEffect(() => {
    if (init && anyOtherActionRequired) {
      // check for any other action otherwise set it to false.

      if (!checkForRedirects(history)) {
        setAnyOtherActionRequired(false)
      }
    }
  }, [anyOtherActionRequired, history, init])

  if (!init || anyOtherActionRequired)
    return (
      <div className="fill-parent center-child">
        <Spin size="large" />
      </div>
    )

  return <>{children}</>
}

export default Router
