import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdCompostFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.45606 10.6175C6.41463 10.4438 6.39119 10.2804 6.38177 10.1012L7.89728 11.236V12.5145C7.76403 12.4389 7.37771 12.205 7.08516 11.8791C6.70185 11.452 6.53358 10.9665 6.45606 10.6175ZM6.59659 8.71521L7.89728 9.68912V10.9793L6.3775 9.84129C6.38511 9.43753 6.4644 9.05124 6.59659 8.71521ZM6.88793 8.17115C7.04191 7.95628 7.24144 7.74452 7.3922 7.55784C7.53733 7.3782 7.73126 7.19551 7.83168 6.94512L7.89728 6.76625V9.43246L6.68112 8.5218C6.74248 8.39504 6.81179 8.27738 6.88793 8.17115ZM8.10273 11.236L9.61824 10.1012C9.60877 10.2817 9.58502 10.4461 9.54318 10.6207C9.46526 10.9695 9.29682 11.4531 8.91484 11.8787C8.6223 12.2047 8.23601 12.4385 8.10273 12.5141V11.236ZM8.10273 9.68912L9.4024 8.71597C9.53521 9.05249 9.6149 9.43672 9.62251 9.84129L8.10273 10.9793V9.68912ZM8.10273 6.76585L8.16836 6.94472C8.26875 7.19515 8.46272 7.37784 8.60784 7.55747C8.75989 7.74572 8.96353 7.95814 9.11602 8.17639C9.19015 8.28249 9.25772 8.3985 9.31776 8.52265L8.10273 9.43246V6.76585ZM7.89728 12.5145L8.10273 12.5153V14.0281H15.3693C15.0782 13.3744 14.2564 11.5843 13.78 11.1129C13.4745 10.8107 12.8208 10.431 12.6473 10.1003C12.4739 9.76962 12.5453 9.90608 12.1423 8.8011C11.7393 7.69636 10.9583 6.94782 10.2364 6.16679C9.877 5.77785 9.35936 5.22368 8.73803 4.95606C8.12552 4.69227 7.31364 4.62293 6.38559 5.52895C6.15091 5.7805 5.85635 6.10232 5.55974 6.56685C5.12574 7.24657 5.11237 7.36556 4.64398 8.00803C4.04415 8.83082 3.95963 8.9428 3.73356 9.15159C3.23549 9.61209 2.63063 9.59276 2.22884 10.3264C2.01993 10.7079 0.640204 14.0048 0.630737 14.0281H7.89728V12.5145Z" />
    </svg>
  )
})
