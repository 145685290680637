import color from 'color-alpha'

// --- antd v4 color theme ---

// gray
export const gray100 = '#FFFFFF'
export const gray200 = '#FAFAFA'
export const gray300 = '#F5F5F5'
export const gray400 = '#F0F0F0'
export const gray500 = '#D9D9D9'
export const gray600 = '#BFBFBF'
export const gray700 = '#8C8C8C'
export const gray800 = '#595959'
export const gray900 = '#262626'
export const gray1000 = '#000000'

// red
export const red100 = '#FFF1F0'
export const red200 = '#FFCCC7'
export const red300 = '#FFA39E'
export const red400 = '#FF7875'
export const red500 = '#FF4D4F'
export const red600 = '#F5222D'
export const red700 = '#CF1322'
export const red800 = '#A8071A'
export const red900 = '#820014'
export const red1000 = '#5C0011'

// orange
export const orange100 = '#FFF7E6'
export const orange200 = '#FFE7BA'
export const orange300 = '#FFD591'
export const orange400 = '#FFC069'
export const orange500 = '#FFA940'
export const orange600 = '#FA8C16'
export const orange700 = '#D46B08'
export const orange800 = '#AD4E00'
export const orange900 = '#873800'
export const orange1000 = '#612500'

// yellow
export const yellow100 = '#FEFFE6'
export const yellow200 = '#FFFFB8'
export const yellow300 = '#FFFB8F'
export const yellow400 = '#FFF566'
export const yellow500 = '#FFEC3D'
export const yellow600 = '#FADB14'
export const yellow700 = '#D4B106'
export const yellow800 = '#AD8B00'
export const yellow900 = '#876800'
export const yellow1000 = '#614700'

// green
export const green100 = '#F6FFED'
export const green200 = '#D9F7BE'
export const green300 = '#B7EB8F'
export const green400 = '#95DE64'
export const green500 = '#73D13D'
export const green600 = '#52C41A'
export const green700 = '#389E0D'
export const green800 = '#237804'
export const green900 = '#135200'
export const green1000 = '#092B00'

// geek blue
export const geekBlue100 = '#F0F5FF'
export const geekBlue200 = '#D6E4FF'
export const geekBlue300 = '#ADC6FF'
export const geekBlue400 = '#85A5FF'
export const geekBlue500 = '#597EF7'
export const geekBlue600 = '#2F54EB'
export const geekBlue700 = '#1D39C4'
export const geekBlue800 = '#10239E'
export const geekBlue900 = '#061178'
export const geekBlue1000 = '#030852'

// magenta
export const magenta100 = '#FFF0F6'
export const magenta200 = '#FFD6E7'
export const magenta300 = '#FFADD2'
export const magenta400 = '#FF85C0'
export const magenta500 = '#F759AB'
export const magenta600 = '#EB2F96'
export const magenta700 = '#C41D7F'
export const magenta800 = '#9E1068'
export const magenta900 = '#780650'
export const magenta1000 = '#520339'

// blue
export const blue100 = '#E6F7FF'
export const blue200 = '#BAE7FF'
export const blue300 = '#91D5FF'
export const blue400 = '#69C0FF'
export const blue500 = '#40A9FF'
export const blue600 = '#1890FF'
export const blue700 = '#096DD9'
export const blue800 = '#0050B3'
export const blue900 = '#003A8C'
export const blue1000 = '#002766'

// volcano
export const volcano100 = '#FFF2E8'
export const volcano200 = '#FFD8BF'
export const volcano300 = '#FFBB96'
export const volcano400 = '#FF9C6E'
export const volcano500 = '#FF7A45'
export const volcano600 = '#FA541C'
export const volcano700 = '#D4380D'
export const volcano800 = '#AD2102'
export const volcano900 = '#871400'
export const volcano1000 = '#610B00'

// gold
export const gold100 = '#FFFBE6'
export const gold200 = '#FFF1B8'
export const gold300 = '#FFE58F'
export const gold400 = '#FFD666'
export const gold500 = '#FFC53D'
export const gold600 = '#FAAD14'
export const gold700 = '#D48806'
export const gold800 = '#AD6800'
export const gold900 = '#874D00'
export const gold1000 = '#613400'

// lyme
export const lime100 = '#FCFFE6'
export const lime200 = '#F4FFBF'
export const lime300 = '#EAFF8F'
export const lime400 = '#D3F261'
export const lime500 = '#BAE637'
export const lime600 = '#A0D911'
export const lime700 = '#7CB305'
export const lime800 = '#5B8C00'
export const lime900 = '#3F6600'
export const lime1000 = '#254000'

// cyan
export const cyan100 = '#E6FFFB'
export const cyan200 = '#B5F5EC'
export const cyan300 = '#87E8DE'
export const cyan400 = '#5CDBD3'
export const cyan500 = '#36CFC9'
export const cyan600 = '#13C2C2'
export const cyan700 = '#08979C'
export const cyan800 = '#006D75'
export const cyan900 = '#00474F'
export const cyan1000 = '#002329'

// golden purple
export const purple100 = '#F9F0FF'
export const purple200 = '#EFDBFF'
export const purple300 = '#D3ADF7'
export const purple400 = '#B37FEB'
export const purple500 = '#9254DE'
export const purple600 = '#722ED1'
export const purple700 = '#531DAB'
export const purple800 = '#391085'
export const purple900 = '#22075E'
export const purple1000 = '#120338'

// extra
export const navy900 = '#111D2C'
export const navy500 = '#0F3F6B'
export const navy200 = '#ECEFF3'
export const navy100 = '#e8e9ea'
export const refaDarkBlue = '#034F77'
export const refaGray = '#E5E7E8'
export const refaLightBlue = '#77A3B6'
export const refaLightGreen = '#B8E5E1'

// --- tokens ---

// text
export const textPrimary = gray900
export const textSecondary = color(gray900, 0.5)
export const textEmpty = color(gray900, 0.3)
export const textRevPrimary = gray100
export const textRevSecondary = color(gray100, 0.5)
export const textLink = blue500
export const textDangerous = red500

// icon
export const iconPrimary = color(gray900, 0.8)
export const iconSecondary = color(gray900, 0.3)
export const iconLink = blue500
export const iconDangerous = red500

// border
export const bSection = gray300
export const bInputDefault = gray500
export const bInputHover = blue500

// background
export const bgSystem = navy200
export const bgInnerPrimary = gray100
export const bgInnerSecondary = gray300
export const bgTableHeader = gray200
export const bgMainTabs = gray300
export const bgCardHeader = navy900
export const bgOverlay = color(gray900, 0.6)
export const bgHover = color(gray900, 0.05)
export const bgTransp = 'transparent'
export const bgNotificationSection = '#F5F7F9'

// feedback
export const fbDefault = navy900
export const fbWarning = gold500
export const fbCritical = red500

// stop status
export const stopCompleted = green500
export const stopError = red500
export const stopNext = blue500

// priority
export const pHigh = red500
export const pMedium = gold500
export const pLow = blue500
export const pNone = gray500

// divider
export const divMain = color(gray900, 0.15)
export const divSecondary = color(gray900, 0.05)
export const divColor = '#ebeff3'
