import React from 'react'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ContentUtils } from 'braft-utils'

import ControlBarButton from '../Components/ControlBarButton'
import Variables from '../Components/Variables'
import { ContentProps } from '../types'

const RightContent = ({
  editorState,
  setEditorState,
  variables,
  expanded,
  setExpanded,
  showExpand = true,
}: ContentProps & {
  expanded?: boolean
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>
  variables?: ItemType[]
  /** default to true */
  showExpand?: boolean
}) => {
  return (
    <Row gutter={4} align="middle">
      {variables && (
        <Col>
          <Variables
            onVariableClick={(option) => {
              setEditorState(
                ContentUtils.insertText(
                  editorState,
                  option.key,
                  (editorState as $TSFixMe).getCurrentInlineStyle()
                )
              )
            }}
            variables={variables}
          />
        </Col>
      )}
      {showExpand && (
        <Col>
          <ControlBarButton
            onClick={() => setExpanded?.((prev) => !prev)}
            icon={
              !expanded ? <FullscreenOutlined /> : <FullscreenExitOutlined />
            }
            style={{
              pointerEvents: 'auto',
            }}
          />
        </Col>
      )}
    </Row>
  )
}

export default RightContent
