import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { MonthlyIntervalEnum } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const exportMonthlyIntervalLabelMap: Record<
  MonthlyIntervalEnum,
  ReactNode
> = {
  [MonthlyIntervalEnum.AT_THE_START_OF_THE_MONTH]: (
    <T _str="At the start of the month" />
  ),
  [MonthlyIntervalEnum.AT_THE_END_OF_THE_MONTH]: (
    <T _str="At the end of the month" />
  ),
}

export const exportMonthlyIntervalOptions = createOptionsFromLabelMap(
  exportMonthlyIntervalLabelMap
)
