import React from 'react'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { Col, Row, Skeleton } from 'antd'
import { Avatar, Tag } from 'src/antd'

import {
  ticketTicketPriorityChoicesIconMap,
  ticketTicketStatusChoicesSelectOptions,
} from 'src/api/consts'
import { Typography } from 'src/storybook/components'
import { divSecondary } from 'src/styles/color-theme'
import { array } from 'src/utils/array'
import extractIdFromEncodedString from 'src/utils/extractIdFromEncodedString'
import getFullName from 'src/utils/getFullName'

import {
  GetTicketPopupInfo,
  GetTicketPopupInfoVariables,
} from './__generated__/types'
import { GET_TICKET_POPUP_INFO } from './api'
import { PopupContentProps } from '..'

export default function TicketPopup({ id }: PopupContentProps) {
  const { data, loading } = useQuery<
    GetTicketPopupInfo,
    GetTicketPopupInfoVariables
  >(GET_TICKET_POPUP_INFO, { variables: { id } })

  const PriorityIcon = data?.ticket?.priority
    ? ticketTicketPriorityChoicesIconMap[data.ticket.priority]
    : null

  const ticketData = array(
    {
      label: <T _str="Ticket type" />,
      value: <Tag>{data?.ticket?.ticketType?.name}</Tag>,
    },
    {
      label: <T _str="Property" />,
      value: (
        <Typography variant="body-14">
          {data?.ticket?.location?.name}
        </Typography>
      ),
    },
    data?.ticket?.container && {
      label: <T _str="Container" />,
      value: (
        <Row gutter={[4, 4]}>
          <Col>
            <Typography variant="body-14">
              {data?.ticket?.container?.containerId}
            </Typography>
          </Col>
          <Col>
            <Tag style={{ margin: 0 }}>
              {data?.ticket?.container?.containerType?.name}
            </Tag>
          </Col>
          <Col>
            <Tag style={{ margin: 0 }}>
              {data?.ticket?.container?.wasteFraction?.name}
            </Tag>
          </Col>
          <Col>
            <Tag style={{ margin: 0 }}>
              {data?.ticket?.container?.pickupSetting?.name}
            </Tag>
          </Col>
        </Row>
      ),
    }
  )

  if (loading && !data?.ticket)
    return (
      <div style={{ width: 360, padding: '8px 12px' }}>
        <Skeleton active paragraph={{ rows: 3 }} />
      </div>
    )

  return (
    <div style={{ width: 360 }}>
      <div
        style={{
          padding: '8px 12px',
          borderBottom: `1px solid ${divSecondary}`,
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Typography variant="subHeader-16" weight="semi-bold">
              {PriorityIcon && (
                <span style={{ marginRight: 8 }}>
                  <PriorityIcon />
                </span>
              )}
              {extractIdFromEncodedString(data?.ticket?.id)}
            </Typography>
          </Col>
          <Col>
            {data?.ticket?.status &&
              ticketTicketStatusChoicesSelectOptions.find(
                (option) => option.value === data?.ticket?.status
              )?.label}
            <Avatar
              src={data?.ticket?.assignee?.logo}
              text={
                data?.ticket?.assignee
                  ? getFullName(data?.ticket?.assignee)
                  : ''
              }
              size={24}
            />
          </Col>
        </Row>
      </div>
      <div style={{ padding: '8px 12px' }}>
        <Row gutter={[8, 8]}>
          {ticketData.map(({ label, value }, index) => (
            <Col span={24} key={index}>
              <Row align="top" wrap={false}>
                <Col flex="0 0 100px">
                  <Typography variant="body-14" type="secondary">
                    {label}
                  </Typography>
                </Col>
                <Col flex="auto">{value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}
