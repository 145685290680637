import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { invert } from 'lodash'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TDay =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

export const dayLabelMap: Record<TDay, ReactNode> = {
  Monday: <T _str="Monday" />,
  Tuesday: <T _str="Tuesday" />,
  Wednesday: <T _str="Wednesday" />,
  Thursday: <T _str="Thursday" />,
  Friday: <T _str="Friday" />,
  Saturday: <T _str="Saturday" />,
  Sunday: <T _str="Sunday" />,
}
export const dayLabelMapShort: Record<TDay, ReactNode> = {
  Monday: <T _str="Mon" />,
  Tuesday: <T _str="Tue" />,
  Wednesday: <T _str="Wed" />,
  Thursday: <T _str="Thu" />,
  Friday: <T _str="Fri" />,
  Saturday: <T _str="Sat" />,
  Sunday: <T _str="Sun" />,
}
export const dayNumericMapMonday1Sunday7: Record<TDay, number> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}

export const dayNumericMapMonday1Sunday7Invert: Record<number, TDay> = invert(
  dayNumericMapMonday1Sunday7
) as Record<number, TDay>

export const dayColorMap: Record<TDay, string> = {
  Monday: '#1D39C4',
  Tuesday: '#F38804',
  Wednesday: '#6DC8EC',
  Thursday: '#36CFC9',
  Friday: '#9254DE',
  Saturday: '#F759AB',
  Sunday: '#262626',
}

export const dayOptions = createOptionsFromLabelMap(dayLabelMap)
export const dayOptionsShort = createOptionsFromLabelMap(dayLabelMapShort)
