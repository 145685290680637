import { setRoles } from 'src/reducers/routing/utils'

import { activeProjectsVar } from './active-projects'
import { routingVar, INITIAL_ROUTING_DATA, TRoutingVarType } from './routing'
import { userVar } from './user'

export const resetStore = () => {
  routingVar({
    ...INITIAL_ROUTING_DATA,
    init: true,
    roles: ['non-authenticated'],
  })
  userVar(null)
  activeProjectsVar([])
}

export const setUserEmail = (email: string) => {
  const user: $TSFixMe = userVar()
  userVar({ ...user, email })
}

export const setRouting = (data: Partial<TRoutingVarType>) => {
  routingVar({ ...routingVar(), ...data })
}

export const setUser = (user: $TSFixMe) => {
  const savedActiveProjects = user.activeProjectsIds
  const activeProjects =
    savedActiveProjects?.length && user.activeProjects
      ? user.activeProjects.edges.map(({ node }: $TSFixMe) => node)
      : []

  userVar(user)
  activeProjectsVar(activeProjects)
  setRouting({
    roles: setRoles(user),
    init: true,
  })
}
