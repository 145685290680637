import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react'
import { Select as AntdSelect, SelectProps, RefSelectProps } from 'antd'

type TSelect = ForwardRefExoticComponent<
  PropsWithoutRef<SelectProps> & RefAttributes<RefSelectProps>
> & {
  SECRET_COMBOBOX_MODE_DO_NOT_USE: string
  Option: typeof AntdSelect.Option
  OptGroup: typeof AntdSelect.OptGroup
}

const Select = forwardRef((props, ref) => (
  <AntdSelect
    ref={ref}
    getPopupContainer={(trigger) => trigger.parentElement}
    {...props}
  />
)) as TSelect

Select.SECRET_COMBOBOX_MODE_DO_NOT_USE =
  AntdSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE
Select.Option = AntdSelect.Option
Select.OptGroup = AntdSelect.OptGroup

export { Select }
