import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createContainerCreateRouteMap } from './container-create'
import { createContainerRouteMap } from './container-detailed-view'

export const createRoutesMapContainers = (prefix: string) => ({
  map: pathJoin(prefix)('map').chainWithTitle({
    title: <T _str="All containers map" />,
    breadCrumbTitle: <T _str="Containers map" />,
  }),
  create: pathJoin(prefix)('create').chainMapAuto(
    createContainerCreateRouteMap,
    {
      title: <T _str="New Container" />,
      breadCrumbTitle: <T _str="New" />,
    }
  ),
  'add-sensor': pathJoin(prefix)('add-sensor').chain,
  ':containerId': (containerId: string) =>
    pathJoin(prefix)(containerId).chainMapAuto(createContainerRouteMap, {
      title: <T _str="Container Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
})
