import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntdAvatar, AvatarProps } from 'antd'
import isNumber from 'lodash/isNumber'
import omit from 'lodash/omit'
import stringToColor from 'string-to-color'
import styled from 'styled-components'

import { gray700 } from 'src/styles/color-theme'
import findClosestColorMatch from 'src/utils/findClosestColorMatch'
import replaceStringWithLetters from 'src/utils/stringToLetters'

import { AVATAR_COLOR_STYLES, AvatarColorType } from './utils'

// TODO: improve text color with luminance when IPFE-7866 would be merged
const StyledAvatar = styled(AntdAvatar)<{
  $text?: string
  $color: AvatarColorType
  $colorByText?: boolean
}>(({ size, $text, $color, $colorByText }) => {
  const matchedBackgroundColor = findClosestColorMatch(
    stringToColor($text || ''),
    Object.values(omit(AVATAR_COLOR_STYLES, ['transparent'])).map(
      (i) => i.backgroundColor
    )
  )

  const avatarStyles = Object.values(AVATAR_COLOR_STYLES).find(
    (i) => i.backgroundColor === matchedBackgroundColor
  )
  return `
    font-weight: 500;
    font-size: ${isNumber(size) ? size * 0.45 : 16}px !important;
    background-color: ${
      $colorByText
        ? avatarStyles?.backgroundColor
        : AVATAR_COLOR_STYLES[$color].backgroundColor
    };
    color: ${
      $colorByText ? avatarStyles?.color : AVATAR_COLOR_STYLES[$color].color
    };
  `
})

export type TAvatarProps = {
  text?: string
  color?: AvatarColorType
  colorByText?: boolean
  userIconByDefault?: boolean
} & AvatarProps

function Avatar({
  text,
  color = 'gray',
  colorByText = true,
  userIconByDefault = true,
  ...props
}: TAvatarProps) {
  return (
    <StyledAvatar
      {...props}
      $color={props.src ? 'transparent' : color}
      $colorByText={props.src ? false : colorByText}
      $text={text}
      icon={
        props.icon ||
        (userIconByDefault && !text && (
          <UserOutlined style={{ color: gray700 }} />
        ))
      }
    >
      {typeof text === 'string' ? replaceStringWithLetters(text) : text}
    </StyledAvatar>
  )
}

Avatar.Group = AntdAvatar.Group

export { Avatar }
