import React from 'react'
import { T } from '@transifex/react'

import { createContainerRoutesMap } from './assets'
import { createPropertyRoutesMap } from './property'
import { createPropertyGroupsRouteMap } from './property-groups'
import { pathJoin } from '../../../utils'

export const createCustomerManagementRouteMap = (prefix: string) => ({
  'inquiry-management': pathJoin(prefix)('inquiry-management').chain,
  'property-groups': pathJoin(prefix)('property-groups').chainMapAuto(
    createPropertyGroupsRouteMap,
    {
      title: <T _str="All Property Groups" />,
      breadCrumbTitle: <T _str="All Property Groups" />,
    }
  ),
  properties: pathJoin(prefix)('properties').chainMapAuto(
    createPropertyRoutesMap,
    {
      title: <T _str="All Properties" />,
      breadCrumbTitle: <T _str="All Properties" />,
    }
  ),
  agreements: pathJoin(prefix)('agreements').chainMapAuto(
    createContainerRoutesMap,
    {
      title: <T _str="Agreement" />,
      breadCrumbTitle: <T _str="Agreement" />,
    }
  ),
})
