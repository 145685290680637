import { Row, Select } from 'antd'
import styled from 'styled-components'

import { Typography } from 'src/storybook/components'
import { divColor, gray200, gray900 } from 'src/styles/color-theme'

export const StyledSelect = styled(Select)`
  min-width: 100px;

  &.ant-select-single .ant-select-selection-item {
    display: none;
  }
`

export const StyledLabel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:has(+ .ant-select-disabled) {
    cursor: default;
  }
`

export const PresetWrapper = styled.div`
  min-width: 150px;
  padding: 4px 0;
  border-right: 1px solid ${divColor};
`

export const PresetRow = styled(Row)`
  padding: 5px 12px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${gray200};
  }
`

export const StyledCircle = styled.div<{ $color?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`

export const PresetName = styled(Typography)`
  color: ${gray900};
`
