import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'

export const GET_ALL_EXPORTS = gql`
  ${FrgUserForLink}
  query GetAllExports(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: String
    $order: String
    $offset: Int
    $exportType: [ExportTypes]
    $projects: [ID]
    $startDate: Date
    $endDate: Date
  ) {
    allExportTemplates(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      order: $order
      offset: $offset
      exportType: $exportType
      projects: $projects
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCount
      edges {
        node {
          id
          name
          createdAt
          createdBy {
            ...FrgUserForLink
          }
          exportType
          frozen
          latestExportCount
          latestExportedAt
          latestProgress
          latestStatus
          latestExportedBy {
            ...FrgUserForLink
          }
          projects {
            edges {
              node {
                id
                name
              }
            }
          }
          exportIntervalSettings
        }
      }
    }
  }
`

export const DELETE_EXPORT = gql`
  mutation DeleteExport($exportId: ID!) {
    deleteExportTemplate(selfId: $exportId) {
      status
    }
  }
`

export const GET_EXPORT_DATA = gql`
  query GetExportData($id: ID!) {
    exportTemplate(id: $id) {
      id
      frozen
      name
    }
  }
`

export const GET_EXPORT_HISTORY = gql`
  ${FrgUserForLink}
  query TableExportHistory(
    $id: ID!
    $offset: Int = 0
    $first: Int
    $before: String
    $after: String
    $status: [ExportHistoryStatuses]
    $users: [ID]
    $startDate: Date
    $endDate: Date
  ) {
    exportTemplate(id: $id) {
      id
      exportHistory(
        offset: $offset
        first: $first
        before: $before
        after: $after
        status: $status
        users: $users
        startDate: $startDate
        endDate: $endDate
      ) {
        totalCount
        edges {
          node {
            id
            user {
              ...FrgUserForLink
            }
            startedAt
            completedAt
            totalItems
            progress
            status
            fileUrl
          }
        }
      }
    }
  }
`

export const GET_EXPORT_SETTINGS = gql`
  ${FrgUserForLink}
  query GetExportSettings($id: ID!) {
    exportTemplate(id: $id) {
      id
      exportIntervalSettings
      exportIntervalValue
      dailyIntervalStartingFrom
      dailyIntervalStartTime
      monthlyIntervalSetting
      exportInternalRecipients {
        edges {
          node {
            ...FrgUserForLink
          }
        }
      }
      exportExternalRecipients
      automaticallySendExportToInternalRecepients
      automaticallySendExportToExternalRecepients
    }
  }
`

export const MUTATION_REGENERATE_EXPORT = gql`
  mutation MutationRegenerateExport(
    $exportTemplateId: ID!
    $externalRecipients: [String]
    $internalRecipients: [ID]
  ) {
    triggerExport(
      exportTemplateId: $exportTemplateId
      externalRecipients: $externalRecipients
      internalRecipients: $internalRecipients
    ) {
      exportHistory {
        id
      }
    }
  }
`
