import React from 'react'
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon'

const svg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8456_2893)">
      <rect width="12" height="12" rx="2" fill="#FAAD14" />
      <path d="M2.72461 6H9.27546" stroke="white" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_8456_2893">
        <rect width="12" height="12" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const MediumPriority = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={svg} {...props} />
)
