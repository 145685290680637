import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Row, Col, Button, ButtonProps, Space } from 'antd'
import moment, { Moment } from 'moment'

import { array } from 'src/utils/array'

import { SelectMonthSimple } from './SelectMonthSimple'
import { SelectYearSimple } from './SelectYearSimple'
import {
  TX_LOWERCASE_MONTH,
  TX_LOWERCASE_YEAR,
  UTX_LAST,
  UTX_NEXT,
} from '../../transifex'

type TMode = 'year' | 'month'

export const subscriptionMediaLabelMap: Record<TMode, React.ReactNode> = {
  month: TX_LOWERCASE_MONTH,
  year: TX_LOWERCASE_YEAR,
}

const BtnPrev = ({ mode, ...props }: ButtonProps & { mode: TMode }) => (
  <Button type="text" {...props}>
    <Space size={4}>
      <LeftOutlined />
      {UTX_LAST} {subscriptionMediaLabelMap[mode]}
    </Space>
  </Button>
)

const BtnNext = ({ mode, ...props }: ButtonProps & { mode: TMode }) => (
  <Button type="text" {...props}>
    <Space size={4}>
      {UTX_NEXT} {subscriptionMediaLabelMap[mode]}
      <RightOutlined />
    </Space>
  </Button>
)

type TSelectDatePaginatorStyleProps = {
  validRange?: [Moment, Moment]
  value: Moment
  onChange: (value: Moment) => void
  mode: TMode
}

/*
DEV:
- Todo:
1. Add date picker option.
2. Add prev/next buttons for years.
@saad will disucss with @xin later.
*/

export const SelectDatePaginatorStyle = ({
  validRange,
  value,
  onChange,
  mode,
}: TSelectDatePaginatorStyleProps) => {
  return (
    <Row gutter={8}>
      {array(
        <BtnPrev
          mode={mode}
          onClick={() => onChange(moment(value).subtract(1, mode))}
        />,
        <SelectYearSimple
          value={moment(value).year()}
          validRange={
            validRange?.map((range) => moment(range).year()) as [number, number]
          }
          onChange={(year: number) => onChange(moment(value).set('year', year))}
        />,
        mode === 'month' && (
          <SelectMonthSimple
            value={moment(value).month()}
            onChange={(month: number) =>
              onChange(moment(value).set('month', month))
            }
          />
        ),
        <BtnNext
          mode={mode}
          onClick={() => onChange(moment(value).add(1, mode))}
        />
      ).map((node) => (
        <Col>{node}</Col>
      ))}
    </Row>
  )
}

export default SelectDatePaginatorStyle
