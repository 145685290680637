import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { CollectionCalendarDateEventTypesGraphene } from 'src/gen-types'

export const collectionCalendarDateEventTypeLabelMap: Record<
  CollectionCalendarDateEventTypesGraphene,
  ReactNode
> = {
  COLLECTION_DATE_CHANGE: <T _str="Changed collection date" />,
  COLLECTION_CALENDAR_CHANGE: <T _str="Changed collection calendar" />,
  CREATED: null,
  DELETED: null,
  SOFT_DELETED: null,
}
