import { gql } from '@apollo/client'

import { FrgWasteFraction } from './waste-fraction'

export const FrgContainerGroup = gql`
  fragment FrgContainerGroup on ContainerGroupDef {
    id
    name
    containers {
      edges {
        node {
          id
        }
      }
    }
    project {
      id
      name
    }
    location {
      longitude
      latitude
      name
      placeId
    }
    locationCurb
  }
`

export const GET_CONTAINER_GROUP = gql`
  ${FrgContainerGroup}
  query GetContainerGroup($id: ID!) {
    containerGroup(id: $id) {
      ...FrgContainerGroup
    }
  }
`

export const GET_ALL_CONTAINER_GROUP = gql`
  ${FrgWasteFraction}
  query GetAllContainerGroups(
    $first: Int
    $offset: Int
    $activeProjects: String
    $search: String
    $orderBy: String
    $order: String
    $wasteFractions: [ID]
    $pickupSettings: [ID]
    $containerTypes: [ID]
  ) {
    allContainerGroups(
      first: $first
      offset: $offset
      activeProjects: $activeProjects
      search: $search
      orderBy: $orderBy
      order: $order
      wasteFractions: $wasteFractions
      pickupSettings: $pickupSettings
      containerTypes: $containerTypes
    ) {
      totalCount
      edges {
        node {
          id
          name
          createdAt
          location {
            name
          }
          wasteFractions {
            ...FrgWasteFraction
            wasteCategory
            wasteSubstance
            weightFactor
          }
          totalContainers
          containerTypes {
            id
            name
          }
          pickupSettings {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`

export const UPDATE_OR_CREATE_CONTAINER_GROUP = gql`
  ${FrgContainerGroup}
  mutation UpdateOrCreateContainerGroup(
    $input: UpdateOrCreateContainerGroupInput!
  ) {
    updateOrCreateContainerGroup(input: $input) {
      containerGroup {
        ...FrgContainerGroup
      }
    }
  }
`
