import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

export type TDriverStatus = `working` | `not_working` | `unknown`

export const driverStatusLabelMap: Record<TDriverStatus, ReactNode> = {
  working: <T _str="Working" />,
  not_working: <T _str="Not working" />,
  unknown: <T _str="Unknown" />,
}

export const driverStatusColorMap: Record<TDriverStatus, TagProps['color']> = {
  working: 'blue',
  not_working: 'red',
  unknown: 'gray',
}
