import { useMemo } from 'react'
import { gql } from '@apollo/client'
import pick from 'lodash/pick'

import { FrgCompanySettingsPinColors as FrgCompanySettingsPinColorsType } from './__generated__/types'
import { useMe } from './useMe'

export type TPinColorType = Omit<FrgCompanySettingsPinColorsType, '__typename'>

export const FrgCompanySettingsPinColors = gql`
  fragment FrgCompanySettingsPinColors on CompanySettingsType {
    colorPendingStop
    colorEmptiedStop
    colorEmptiedWithTicketStop
    colorNotEmptiedWithTicketStop
    colorSkippedStop
    colorNextStop
    colorWillReturnStop
    colorClosestStop
  }
`

export const useCompanyPinColors = () => {
  const me = useMe()
  const result = useMemo(() => {
    const colors = pick(me?.company?.companySettings || {}, [
      'colorPendingStop',
      'colorEmptiedStop',
      'colorEmptiedWithTicketStop',
      'colorNotEmptiedWithTicketStop',
      'colorSkippedStop',
      'colorNextStop',
      'colorWillReturnStop',
      'colorClosestStop',
    ])
    return Object.entries(colors).reduce<typeof colors>((acc, [key, value]) => {
      return {
        ...acc,
        // add # if needed
        [key]: value && !value.startsWith('#') ? `#${value}` : value,
      }
    }, {} as typeof colors)
  }, [me?.company?.companySettings])
  return result
}
