import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon'
import { Button, ButtonProps } from 'antd'

export type TDropdownSimpleTogglerProps = {
  iconProps?: AntdIconProps & { ref?: React.MutableRefObject<HTMLSpanElement> }
  Icon?: typeof DownOutlined
} & Omit<ButtonProps, 'title'>

const DropdownSimpleToggler = ({
  iconProps = {},
  Icon = DownOutlined,
  ...buttonProps
}: TDropdownSimpleTogglerProps) => {
  return (
    <Button {...buttonProps}>
      {buttonProps?.children} <Icon {...iconProps} />
    </Button>
  )
}

export default DropdownSimpleToggler
