import { gql, useQuery } from '@apollo/client'

import {
  TicketTicketStatusChoices,
  TicketTicketStatusChoicesTuple,
} from 'src/api/consts'
import { apiHelperTicketType } from 'src/api/helpers/ticketType'

import {
  GetTicketTypeStatusTransitions,
  GetTicketTypeStatusTransitionsVariables,
} from './__generated__/types'

export const GET_TICKET_TYPE_STATUS_TRANSITIONS = gql`
  fragment FrgGetTicketTypeStatusTransitions on TicketTypeDef {
    id
    statusTransitions {
      fromStatus
      toStatus
    }
  }

  query GetTicketTypeStatusTransitions($id: ID!, $isTicketId: Boolean!) {
    ticketType(id: $id) @skip(if: $isTicketId) {
      ...FrgGetTicketTypeStatusTransitions
    }
    ticket(id: $id) @include(if: $isTicketId) {
      id
      ticketType {
        ...FrgGetTicketTypeStatusTransitions
      }
    }
  }
`

export const getStatusTransitionMapFromTicketType = () => {}

const useTicketTypeStatusTransitionMap = (
  id?: string,
  isTicketId?: boolean
) => {
  const { data: dataIf, loading } = useQuery<
    GetTicketTypeStatusTransitions,
    GetTicketTypeStatusTransitionsVariables
  >(GET_TICKET_TYPE_STATUS_TRANSITIONS, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: id!,
      isTicketId: !!isTicketId,
    },
    skip: !id,
  })

  const data = isTicketId ? dataIf?.ticket : dataIf

  const statusTransitionMap =
    apiHelperTicketType.getTicketTypeStatusTransitionMap(data?.ticketType)

  const possibleAutomationStatusFlowTuples = Object.keys(
    statusTransitionMap
  ).reduce((prevValue, currentValue) => {
    return [
      ...prevValue,
      ...statusTransitionMap[currentValue as TicketTicketStatusChoices].reduce(
        (prevVal, currentVal) => {
          return [...prevVal, [currentValue, currentVal]]
        },
        [] as $TSFixMe
      ),
    ]
  }, [] as TicketTicketStatusChoicesTuple[])

  return {
    loading,
    statusTransitionMap,
    possibleAutomationStatusFlowTuples,
  }
}

export default useTicketTypeStatusTransitionMap
