import React from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type AlertRuleIntervalType = `DAY` | `MONTH` | `WEEK` | 'HOUR'

export const alertRuleIntervalLabelMap: Record<
  AlertRuleIntervalType,
  React.ReactNode
> = {
  HOUR: <T _str="Hourly" />,
  DAY: <T _str="Daily" />,
  WEEK: <T _str="Weekly" />,
  MONTH: <T _str="Monthly" />,
}

export const ALERT_RULE_OPTIONS = createOptionsFromLabelMap(
  alertRuleIntervalLabelMap
)
