import { times } from 'lodash'
import moment from 'moment'

/** List of the months in the current locale. */
export const months = moment.months()

export const monthsLabelMap = months.map((month, i) => ({
  label: month,
  value: i,
}))

export const monthsDuration = times(months.length, (i) =>
  // 2024 is set as a leap year to avoid issues with February
  moment().year(2024).month(i).daysInMonth()
)
