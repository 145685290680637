import React from 'react'
import { DefaultOptionType } from 'antd/es/select'
import moment from 'moment'
import { Select, SelectProps } from 'src/antd'

export type TSelectYearSimpleProps = {
  value: number
  validRange?: [number, number]
} & Omit<SelectProps, 'value' | 'options'>

export const SelectYearSimple = ({
  value,
  validRange: [start, end] = [
    moment().subtract(20, 'year').year(),
    moment().add(1, 'years').year(),
  ],
  ...props
}: TSelectYearSimpleProps) => {
  const yearsOptions: DefaultOptionType[] = Array.from(
    { length: Math.abs(start - end) },
    (_, i) => ({
      label: end - i,
      value: end - i,
    })
  )

  return <Select {...props} options={yearsOptions} value={value} />
}
