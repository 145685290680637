import React, { useState, useRef, useEffect, useCallback } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Alert, Button, AlertProps } from 'antd'
import styled from 'styled-components'

const TRANSITION_TIME = 200

const StyledAlert = styled(Alert)<{
  $open: boolean
  $descriptionHeight: number
}>`
  padding: 8px 16px;

  @keyframes open {
    0% {
      z-index: auto;
      user-select: auto;
      height: 0px;
      position: unset;
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    99% {
      opacity: 0;
      height: ${({ $descriptionHeight }) => $descriptionHeight}px;
    }
    100% {
      opacity: 1;
      height: auto;
    }
  }
  @keyframes close {
    0% {
      height: ${({ $descriptionHeight }) => $descriptionHeight}px;
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    99% {
      height: 0px;
      opacity: 0;
    }
    100% {
      z-index: -999;
      user-select: none;
      height: auto;
      position: absolute;
      opacity: 0;
    }
  }
  &&& {
    .ant-alert-message {
      margin-bottom: ${({ $open }) => ($open ? 4 : 0)}px;
      transition: all ${TRANSITION_TIME}ms ease-in-out;
      font-size: 14px;
      margin-top: 1px;
    }
    .ant-alert-description {
      animation: ${({ $open }) => ($open ? 'open' : 'close')} 200ms linear;
      animation-fill-mode: forwards;
    }

    .ant-alert-icon {
      font-size: 18px;
      margin-top: 3px;
    }
  }
`

type TAlertCollapsibleProps = {
  /** default to true. */
  collapsible?: boolean
  /** defaultl to false */
  destroyOnClose?: boolean
} & AlertProps

export const AlertCollapsible = ({
  action,
  collapsible = true,
  description,
  destroyOnClose = false,
  ...props
}: TAlertCollapsibleProps) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  const [open, setOpen] = useState(!collapsible)
  /** Tracks if the content is openeded once. */
  const [opended, setOpened] = useState(false)
  const [descriptionHeight, setDescriptionHeight] = useState(0)

  const getDescriptionHeight = () => {
    if (ref.current) {
      const node = ref.current.querySelector(
        '.ant-alert-description'
      ) as HTMLDivElement
      return node?.clientHeight || 0
    }
    return 0
  }

  const toggle = useCallback(() => {
    setOpen((p) => !p)
    /** getting description height */
    setDescriptionHeight(getDescriptionHeight())
  }, [])

  useEffect(() => {
    if (opended) {
      toggle()
    }
  }, [opended, toggle])

  useEffect(() => {
    if (destroyOnClose && !open) {
      setOpened(false)
    }
  }, [open, destroyOnClose])

  return (
    <div ref={ref}>
      <StyledAlert
        $descriptionHeight={descriptionHeight}
        $open={open}
        action={
          <>
            {action}
            {collapsible && (
              <Button
                type="text"
                size="small"
                icon={open ? <UpOutlined /> : <DownOutlined />}
                onClick={() => {
                  if (opended) {
                    toggle()
                  } else {
                    setOpened(true)
                  }
                }}
              />
            )}
          </>
        }
        description={opended ? description : <></>}
        {...props}
      />
    </div>
  )
}
