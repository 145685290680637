import React from 'react'
import { ApolloClient, ApolloLink, split, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { T } from '@transifex/react'
import { createUploadLink } from 'apollo-upload-client'
import { OperationDefinitionNode } from 'graphql'
import { message } from 'src/antd'

import { baseUrl } from 'src/api/baseUrl'
import { impersonatorTokenKey } from 'src/utils/impersonator'

import introspectionToPossibleTypes from './cache'
import introspectionQueryResultData from './fragmentTypes.json'
import { wsLink } from './ws'
import history from '../../history'

const errorMsg401 = 'You do not have permission to perform this action'

const httpLink = createUploadLink({
  uri: `${baseUrl}/graph-api/`,
}) as unknown

export const getJWTToken = () => {
  return localStorage.getItem('reactQLJWT')
}
export const getImpersonatorToken = () => {
  return localStorage.getItem(impersonatorTokenKey) || getJWTToken()
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const useImpersonatorTokenIfExist =
    operation.getContext()?.useImpersonatorTokenIfExist
  const token = useImpersonatorTokenIfExist
    ? getImpersonatorToken()
    : getJWTToken()
  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : '',
    },
  })

  return forward(operation)
})

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (
    (networkError &&
      (networkError as $TSFixMe).statusCode === 401 &&
      history.location.pathname !== '/login') ||
    (graphQLErrors?.length &&
      graphQLErrors.find((error) => error.message === errorMsg401))
  ) {
    history.push('/login')
  }
})

const requestFailedLink = onError(
  ({ networkError, graphQLErrors, operation }) => {
    const isQuery =
      (
        operation.query.definitions.find(
          (definition) => definition.kind === 'OperationDefinition'
        ) as OperationDefinitionNode
      )?.operation === 'query'

    // showNetworkError would be False by default for mutations and True for queries since in most mutations we already show a message
    const { showNetworkError = isQuery, showGraphqlError = isQuery } =
      operation.getContext()

    if (showNetworkError && networkError?.message) {
      message.error(
        networkError.message || (
          <T _str="Something went wrong (Network error)" />
        )
      )
    }
    if (showGraphqlError && graphQLErrors?.length) {
      graphQLErrors.forEach((error) => {
        message.error(
          <T
            _str="Something went wrong (GraphQL error: { path } query)"
            path={error.path?.join(', ')}
          />
        )
      })
    }
  }
)

const cache = new InMemoryCache({
  possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData),
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink as ApolloLink
)

const apolloClient = new ApolloClient({
  // TODO: add requestFailedLink when it would be necessary
  link: from([logoutLink, authMiddleware, link]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

export default apolloClient
