import React from 'react'

export const controlBarStyles: React.CSSProperties = {
  height: '48px',
  border: `2px solid #F5F5F5`,
  backgroundColor: `#F5F5F5`,
  borderTopLeftRadius: `5px`,
  borderTopRightRadius: `5px`,
  paddingLeft: '1rem',
  paddingRight: '1rem',
}

export const expandedControlBarStyles: React.CSSProperties = {
  height: '48px',
  border: `2px solid #F5F5F5`,
  backgroundColor: `#F5F5F5`,
  borderTopLeftRadius: `5px`,
  borderTopRightRadius: `5px`,
}

export const contentStyles: React.CSSProperties = {
  overflowY: `auto`,
  border: `2px solid #F5F5F5`,
  borderBottomLeftRadius: `5px`,
  borderBottomRightRadius: `5px`,
  // comes from the controlBar
  borderTop: `unset`,
}

export const emojis = [
  '🤣',
  '🙌',
  '💚',
  '💛',
  '👏',
  '😉',
  '💯',
  '💕',
  '💞',
  '💘',
  '💙',
  '💝',
  '🖤',
  '💜',
  '❤️',
  '😍',
  '😻',
  '💓',
  '💗',
  '😋',
  '😇',
  '😂',
  '😹',
  '😘',
  '💖',
  '😁',
  '😀',
  '🤞',
  '😲',
  '😄',
  '😊',
  '👍',
  '😌',
  '😃',
  '😅',
  '✌️',
  '🤗',
  '💋',
  '😗',
  '😽',
  '😚',
  '🤠',
  '😙',
  '😺',
  '👄',
  '😸',
  '😏',
  '😼',
  '👌',
  '😎',
  '😆',
  '😛',
  '🙏',
  '🤝',
  '🙂',
  '🤑',
  '😝',
  '😐',
  '😑',
  '🤤',
  '😤',
  '🙃',
  '🤡',
  '😶',
  '😪',
  '😴',
  '😵',
  '😓',
  '👊',
  '😦',
  '😷',
  '🤐',
  '😜',
  '🤓',
  '👻',
  '😥',
  '🙄',
  '🤔',
  '🤒',
  '🙁',
  '😔',
  '😯',
  '😰',
  '😩',
  '😖',
  '😕',
  '😒',
  '😣',
  '😢',
  '😮',
  '😿',
  '🤧',
  '😫',
  '🤥',
  '😞',
  '😬',
  '👎',
  '💀',
  '😳',
  '😨',
  '🤕',
  '🤢',
  '😱',
  '😭',
  '😠',
  '😈',
  '😧',
  '💔',
  '😟',
  '🙀',
  '💩',
  '👿',
  '😡',
  '😾',
]
