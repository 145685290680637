import deepmerge from 'deepmerge'

import { array } from './array'

export const createObjectFromKeyValues = <K extends string, V>(
  keys: K[],
  values: V[],
  all?: V
) =>
  keys.reduce(
    (acc, k, idx) => ({
      ...acc,
      [k]: all !== undefined ? all : values.at(idx),
    }),
    {} as Record<K, V>
  )

export const object = <TPair = Record<string, unknown>>(
  ...args: Array<
    Array<Partial<TPair>> | Partial<TPair> | undefined | false | null
  >
): TPair => {
  const all: Array<Partial<TPair>> = array<Partial<TPair>>(
    args as Partial<TPair>[]
  )
  return deepmerge.all(all) as unknown as TPair
}
