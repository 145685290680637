import React, { ReactNode, useState } from 'react'
import { Button, ButtonProps } from 'antd'

import Modal, { IModal } from '../Modal'

type R = Record<string, $TSFixMe>

type ModalUtils = {
  openModal: Func
  closeModal: Func
  toggleModal: Func
}

export type ModelProps<ModalPropsExtended> = {
  Component?: React.FC<IModal>
} & Omit<IModal, 'visible' | 'onClose' | `children`> &
  ModalPropsExtended

export type ModalWithButtonProps<ModalPropsExtended, UtilsExtended = R> = {
  modelProps?: ModelProps<ModalPropsExtended>
  buttonProps?: Omit<ButtonProps, 'onClick'>
  children: (args: ModalUtils & UtilsExtended) => ReactNode
}

type TUseModalStateProps = {
  init?: boolean
  onOpen?: () => void
  onClose?: () => void
}

export function useModelState({
  init = false,
  onOpen,
  onClose,
}: TUseModalStateProps): [
  { visible: boolean },
  { closeModal: () => void; openModal: () => void; toggleModal: () => void }
] {
  const [visible, setVisible] = useState(init)

  // handlers;
  const closeModal = () => {
    setVisible(false)
    onClose?.()
  }
  const openModal = () => {
    setVisible(true)
    onOpen?.()
  }
  const toggleModal = () => {
    setVisible((p) => !p)
    if (visible) {
      onClose?.()
    } else {
      onOpen?.()
    }
  }

  return [{ visible }, { closeModal, openModal, toggleModal }]
}

export function ModalWithButton<
  ModalPropsExtended extends Record<string, unknown>
>({
  children,
  modelProps,
  buttonProps = {},
}: ModalWithButtonProps<ModalPropsExtended>): JSX.Element {
  const [{ visible }, { closeModal, openModal, toggleModal }] = useModelState(
    {}
  )

  // conts;
  const { Component: ModalElement = Modal, ...restModalProps } =
    modelProps || {}

  return (
    <>
      <ModalElement {...restModalProps} visible={visible} onClose={closeModal}>
        {children({ closeModal, openModal, toggleModal })}
      </ModalElement>
      <Button {...buttonProps} onClick={openModal} />
    </>
  )
}

type Func = () => void

export type UseModalWithButtonProps<
  ModalPropsExtended extends Record<string, unknown> = Record<string, unknown>,
  State = unknown
> = {
  initialState?: State
  statefulProps?: (
    state: State
  ) => Pick<
    ModalWithButtonProps<ModalPropsExtended>,
    'buttonProps' | 'modelProps'
  >
  defaultVisible?: boolean
  onOpen?: TUseModalStateProps['onOpen']
  onClose?: TUseModalStateProps['onClose']
} & ModalWithButtonProps<ModalPropsExtended, { state: State }>

type UseModalWithButtonReturn<State> = {
  jsx: {
    modal: JSX.Element
    button: JSX.Element
  }
  utils: {
    setState: React.Dispatch<React.SetStateAction<State>>
  } & ModalUtils
  state: State
}

export function useModalWithButton<
  ModalPropsExtended extends Record<string, unknown>,
  State = unknown
>({
  children,
  modelProps,
  buttonProps = {},
  initialState,
  statefulProps,
  defaultVisible,
  onOpen,
  onClose,
}: UseModalWithButtonProps<
  ModalPropsExtended,
  State
>): UseModalWithButtonReturn<State> {
  const [state, setState] = useState<State>(initialState as State)
  const [{ visible }, { closeModal, openModal, toggleModal }] = useModelState({
    init: defaultVisible,
    onOpen,
    onClose,
  })

  // conts;
  const { Component: ModalElement = Modal, ...restModalProps } =
    modelProps || {}

  return {
    jsx: {
      button: (
        <Button
          {...buttonProps}
          {...(statefulProps?.(state)?.buttonProps || {})}
          onClick={openModal}
        />
      ),
      modal: (
        <ModalElement
          {...restModalProps}
          {...(statefulProps?.(state)?.modelProps || {})}
          visible={visible}
          onClose={closeModal}
        >
          {children({ closeModal, openModal, toggleModal, state })}
        </ModalElement>
      ),
    },
    state,
    utils: {
      closeModal,
      openModal,
      setState,
      toggleModal,
    },
  }
}
