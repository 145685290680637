import {
  blue200,
  blue900,
  cyan200,
  cyan900,
  gray500,
  gray900,
  green200,
  green800,
  orange200,
  orange900,
  purple200,
  purple900,
  red100,
  red800,
  yellow200,
  yellow900,
} from 'src/styles/color-theme'

export type AvatarColorType =
  | 'red'
  | 'green'
  | 'blue'
  | 'purple'
  | 'yellow'
  | 'gray'
  | 'orange'
  | 'cyan'
  | 'transparent'

export const AVATAR_COLOR_STYLES = {
  red: {
    backgroundColor: red100,
    color: red800,
  },
  green: {
    backgroundColor: green200,
    color: green800,
  },
  blue: {
    backgroundColor: blue200,
    color: blue900,
  },
  purple: {
    backgroundColor: purple200,
    color: purple900,
  },
  yellow: {
    backgroundColor: yellow200,
    color: yellow900,
  },
  orange: {
    backgroundColor: orange200,
    color: orange900,
  },
  cyan: {
    backgroundColor: cyan200,
    color: cyan900,
  },
  gray: {
    backgroundColor: gray500,
    color: gray900,
  },
  transparent: {
    backgroundColor: 'transparent',
    color: gray900,
  },
}
