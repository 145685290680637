import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createScheduledExportDetailViewRoutesMap } from './id'

export const createScheduledExportRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(
      createScheduledExportDetailViewRoutesMap,
      {
        title: <T _str="Export Details" />,
        breadCrumbTitle: <T _str="Export Details" />,
      }
    ),
  create: pathJoin(prefix)('create').chainMapAuto(
    (prefix2) => ({
      configuration: pathJoin(prefix2)('configuration').chain,
    }),
    {
      title: <T _str="New Export" />,
      breadCrumbTitle: <T _str="New Export" />,
    }
  ),
})
