import React from 'react'
import { Select, SelectProps } from 'src/antd'

import { monthsLabelMap } from 'src/api/consts/months'

export type TSelectMonthSimpleProps = {
  /** number of month starting from 0 as Jan. */
  value: number
} & Omit<SelectProps, 'value' | 'options'>

export const SelectMonthSimple = ({
  value,
  ...props
}: TSelectMonthSimpleProps) => (
  <Select
    {...props}
    style={{ minWidth: 100, ...props?.style }}
    options={monthsLabelMap}
    value={value}
  />
)
