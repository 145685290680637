import { createAlertRoutesMap } from './alert'
import { createBillingRunRoutesMap } from './billing-run'
import { createCollectionDeviationRoutesMap } from './collection-deviation'
import { createCompanyEmailAccountRoutesMap } from './company-email-account'
import { createHaulerRoutesMap } from './hauler'
import { createPickupSettingsRoutesMap } from './pickup-settings'
import { createScheduledExportRoutesMap } from './scheduled-export'
import { pathJoin } from '../../../utils'

export const createCompanyIdRoutesMap = (prefix: string) => ({
  'scheduled-export': pathJoin(prefix)('scheduled-export').chainMapAuto(
    createScheduledExportRoutesMap
  ),
  'company-email-account': pathJoin(prefix)(
    'company-email-account'
  ).chainMapAuto(createCompanyEmailAccountRoutesMap),
  hauler: pathJoin(prefix)('hauler').chainMapAuto(createHaulerRoutesMap),
  'collection-deviation': pathJoin(prefix)('collection-deviation').chainMapAuto(
    createCollectionDeviationRoutesMap
  ),
  alert: pathJoin(prefix)('alert').chainMapAuto(createAlertRoutesMap),
  'pickup-settings': pathJoin(prefix)('pickup-settings').chainMapAuto(
    createPickupSettingsRoutesMap
  ),
  'billing-run': pathJoin(prefix)('billing-run').chainMapAuto(
    createBillingRunRoutesMap
  ),
})
