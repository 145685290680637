import { gql } from '@apollo/client'

export const GET_PROJECT = gql`
  query GetProject($id: ID!, $withSettings: Boolean = false) {
    project(id: $id) {
      id
      name
      description
      userAccessLevel
      logo
      company {
        id
      }
      settings @include(if: $withSettings) {
        id
        country
        timezone
        emptyingMethod
        pickupTime
        cluster
        measuringDistance
        drivingDistance
        weight
        volume
        temperature
        clockFormat12
        currency
        dashboardLocation {
          id
          latitude
          longitude
          placeId
          name
        }
        crmEnabled
      }
    }
  }
`

export const UPDATE_OR_CREATE_PROJECT = gql`
  mutation UpdateOrCreateProject($input: UpdateOrCreateProjectInput!) {
    updateOrCreateProject(input: $input) {
      project {
        id
        name
        description
        userAccessLevel
        logo
        company {
          id
          name
        }
        wasteFractions {
          edges {
            node {
              id
              name
              wasteCategory
              wasteSubstance
              weightFactor
            }
          }
        }
        containerTypes {
          edges {
            node {
              id
              name
            }
          }
        }
        settings {
          id
          timezone
          emptyingMethod
          pickupTime
          cluster
          measuringDistance
          drivingDistance
          weight
          volume
          temperature
          clockFormat12
          currency
          dashboardLocation {
            id
            latitude
            longitude
            placeId
            name
          }
          crmEnabled
        }
      }
    }
  }
`
