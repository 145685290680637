function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  // Convert a hex color string to an RGB object.
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function colorDifference(
  color1: { r: number; g: number; b: number } | null,
  color2: { r: number; g: number; b: number } | null
): number {
  if (!color1 || !color2) throw new Error('Invalid color format')
  // Calculate the Euclidean distance between two RGB colors.
  return Math.sqrt(
    (color1.r - color2.r) ** 2 +
      (color1.g - color2.g) ** 2 +
      (color1.b - color2.b) ** 2
  )
}

export default function findClosestColorMatch<ColorType extends string[]>(
  hexColor: string,
  colors: ColorType
): ColorType[number] {
  // Convert the target hex color to RGB.
  const targetColor = hexToRgb(hexColor)
  if (!targetColor) {
    throw new Error('Invalid color format')
  }

  // Initialize with the first color in the list and its difference.
  let closestColor = colors[0]
  let minDifference = Number.MAX_VALUE

  // Iterate through the COLORS array to find the closest color.
  colors.forEach((color) => {
    const difference = colorDifference(targetColor, hexToRgb(color))
    if (difference < minDifference) {
      minDifference = difference
      closestColor = color
    }
  })

  // Return the closest color from the list.
  return closestColor
}
