import { useRef, MutableRefObject, useCallback } from 'react'

export type UseRefEnhancedReturn<T> = [
  () => T,
  (val: T) => void,
  MutableRefObject<T>
]

export const useRefEnhanced = <T extends $TSFixMe>(
  initialVal: T
): UseRefEnhancedReturn<T> => {
  const ref = useRef<T>(initialVal)
  const get = useCallback(() => ref.current, [])
  const set = useCallback((val: T) => {
    ref.current = val
  }, [])

  return [get, set, ref]
}
