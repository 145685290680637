import {
  LevelChoices,
  ScheduleOnRouteActionDef,
  TicketTypeStatusTransition,
} from 'src/gen-types'

import {
  invertTicketTicketStatusChoicesMutationValueMap,
  StatusTransitionMap,
  TicketTicketStatusChoices,
  ticketTicketStatusChoicesMutationValueMap,
  TicketTicketStatusChoicesWithNull,
} from '../consts'

const getTicketTypeStatusTransitionMap = (
  /**
   * GraphqlType: TicketTypeDef
   * {
   *   statusTransition {
   *     fromStatus
   *     toStatus
   *   }
   * }
   */
  ticketType: $TSFixMe
) => {
  const statusTransitions = ticketType?.statusTransitions || []
  const statusTranstionChocies = statusTransitions.map((s: $TSFixMe) => ({
    fromStatus: (s.fromStatus
      ? invertTicketTicketStatusChoicesMutationValueMap[+s.fromStatus]
      : 'A_null') as TicketTicketStatusChoicesWithNull,
    toStatus: invertTicketTicketStatusChoicesMutationValueMap[+s.toStatus],
  }))

  const statusTransitionMap: StatusTransitionMap = {
    A_null: [],
    A_1: [],
    A_2: [],
    A_3: [],
    A_4: [],
    A_5: [],
    A_6: [],
    A_7: [],
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const transition of statusTranstionChocies) {
    statusTransitionMap[transition.fromStatus as TicketTicketStatusChoices] = [
      ...(statusTransitionMap[
        transition.fromStatus as TicketTicketStatusChoices
      ] || []),
      transition.toStatus,
    ]
  }

  return statusTransitionMap
}

const getTicketTypeStatusTransitions = (
  /**
   * GraphqlType: TicketTypeDef
   * {
   *   statusTransition {
   *     fromStatus
   *     toStatus
   *   }
   * }
   */
  ticketType?: $TSFixMe
) => {
  const statusTransitionSet = new Set<TicketTicketStatusChoices>()
  const statusTransitions = ticketType?.statusTransitions || []

  // eslint-disable-next-line no-restricted-syntax
  for (const transition of statusTransitions) {
    if (transition.fromStatus && transition.toStatus) {
      statusTransitionSet.add(
        invertTicketTicketStatusChoicesMutationValueMap[+transition.fromStatus]
      )
      statusTransitionSet.add(
        invertTicketTicketStatusChoicesMutationValueMap[+transition.toStatus]
      )
    }
  }

  return Array.from(statusTransitionSet)
}

export const getTicketTypeLevel = (ticketType?: {
  isContainerLevel?: boolean
  isPropertyLevel?: boolean
}) => {
  if (ticketType?.isPropertyLevel && ticketType?.isContainerLevel)
    return LevelChoices.ALL
  if (ticketType?.isContainerLevel) return LevelChoices.ONLY_TICKETS
  if (ticketType?.isPropertyLevel) return LevelChoices.ONLY_CRM

  return LevelChoices.ALL
}

const getTicketTypeLevelFilters = (level: LevelChoices) => {
  switch (level) {
    case LevelChoices.ONLY_CRM:
      return { isContainerLevel: false, isPropertyLevel: true }
    case LevelChoices.ONLY_TICKETS:
      return { isContainerLevel: true, isPropertyLevel: false }
    case LevelChoices.ALL:
    default:
      return { isContainerLevel: true, isPropertyLevel: true }
  }
}

type TScheduleToRouteTransition = RecursivePartial<
  TicketTypeStatusTransition & {
    actions?: ScheduleOnRouteActionDef[]
  }
>

const getScheduleToRouteTransitions = (
  status?: TicketTicketStatusChoices,
  statusTransitions?: RecursivePartial<TicketTypeStatusTransition>[]
) => {
  return (
    (statusTransitions?.filter(
      (st) =>
        +(st.fromStatus || '') ===
          ticketTicketStatusChoicesMutationValueMap[
            status as TicketTicketStatusChoices
          ] &&
        // eslint-disable-next-line no-underscore-dangle
        st.actions?.[0]?.__typename === 'ScheduleOnRouteActionDef' &&
        st.actions?.[0]?.routeScheme &&
        st.actions?.[0]?.manual
    ) as TScheduleToRouteTransition[]) || []
  )
}

export const apiHelperTicketType = {
  getTicketTypeStatusTransitionMap,
  getTicketTypeStatusTransitions,
  getTicketTypeLevel,
  getTicketTypeLevelFilters,
  getScheduleToRouteTransitions,
}
