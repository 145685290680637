import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdCoffeeGroundFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.54393 14.0412C6.88516 14.0341 7.13459 13.7966 7.15802 13.7747C7.59561 13.3651 7.90795 12.8853 8.08711 12.3474C8.44573 11.2702 8.25172 9.99471 7.51042 8.55629C6.8964 7.36488 6.58888 6.22407 6.59644 5.16554C6.60259 4.29759 6.81844 3.48689 7.23794 2.75594C7.31419 2.623 7.49175 2.35719 7.49918 2.34589C7.49918 2.34589 7.58609 2.23042 7.52495 2.11399C7.45855 1.98772 7.31355 2.00035 7.31355 2.00035C4.76137 2.04682 3 5.30133 3 8.10009C3 10.8719 4.14544 12.999 5.88998 13.9124C5.93277 13.9348 6.17065 14.0489 6.54393 14.0412Z" />
      <path d="M9.56571 2.04119C9.19509 2.04049 8.84752 2.25373 8.64773 2.46507C8.44236 2.68244 8.24131 2.95231 8.05993 3.27633C7.73388 3.85886 7.56844 4.50653 7.56799 5.20142C7.56749 6.09318 7.83875 7.07217 8.37418 8.11115C8.83501 9.00533 9.11351 9.86738 9.20188 10.6734C9.27927 11.3797 9.21248 12.0518 9.00349 12.6711C8.86072 13.094 8.66392 13.4578 8.44661 13.7671C8.42858 13.8052 8.40091 13.8886 8.4508 13.9727C8.516 14.0826 8.63472 14.0771 8.65224 14.0756L8.65782 14.0753C11.2052 14.024 13.0255 11.1408 13.0255 8.13972C13.0255 5.25659 11.9324 3.11331 10.2206 2.1879C10.1703 2.16066 9.92141 2.04189 9.56571 2.04119Z" />
    </svg>
  )
})
