import React, { FC, ReactNode, CSSProperties } from 'react'
import { T } from '@transifex/react'
import { Tag, TagProps, TableColumnProps } from 'src/antd'

import { HighestPriority } from 'src/icons-v2/HighestPriority'
import { HighPriority } from 'src/icons-v2/HighPriority'
import { LowPriority } from 'src/icons-v2/LowPriority'
import { MediumPriority } from 'src/icons-v2/MediumPriority'
import { NonePriority } from 'src/icons-v2/NonePriority'
import { NO_DATA } from 'src/storybook/components/table-kit'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TicketTicketPriorityChoices = `A_0` | `A_1` | `A_2` | 'A_3' | 'A_4'

export const ticketTicketPriorityChoicesLabelMap: Record<
  TicketTicketPriorityChoices,
  ReactNode
> = {
  A_0: <T _str="None" />,
  A_1: <T _str="Low" />,
  A_2: <T _str="Medium" />,
  A_3: <T _str="High" />,
  A_4: <T _str="Critical" />,
}

export const ticketTicketPriorityChoicesTagColorMap: Record<
  TicketTicketPriorityChoices,
  TagProps['color']
> = {
  A_0: 'gray',
  A_1: 'blue',
  A_2: 'orange',
  A_3: 'red',
  A_4: 'red',
}

const ticketPriorityChoicesTagVariantMap: Record<
  TicketTicketPriorityChoices,
  TagProps['variant']
> = {
  A_0: 'subtle',
  A_1: 'subtle',
  A_2: 'subtle',
  A_3: 'subtle',
  A_4: 'bold',
}

/**
 * Use this map when you are sending data to server.
 */
export const ticketTicketPriorityChoicesMutationValueMap: Record<
  TicketTicketPriorityChoices,
  number
> = {
  A_0: 0,
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
}

export const ticketTicketPriorityChoicesSelectOptions =
  createOptionsFromLabelMap(ticketTicketPriorityChoicesLabelMap)

export const ticketTicketPriorityChoicesIconMap: Record<
  TicketTicketPriorityChoices,
  FC<{ style?: CSSProperties }> | undefined
> = {
  A_1: LowPriority,
  A_2: MediumPriority,
  A_3: HighPriority,
  A_4: HighestPriority,
  A_0: NonePriority,
}

type TPriorityTagProps = {
  priority: TicketTicketPriorityChoices
  iconOnly?: boolean
  noMargin?: boolean
}

export function PriorityTag({
  priority,
  iconOnly,
  noMargin,
}: TPriorityTagProps) {
  const Icon = ticketTicketPriorityChoicesIconMap[priority]
  return (
    <Tag
      variant={ticketPriorityChoicesTagVariantMap[priority]}
      color={ticketTicketPriorityChoicesTagColorMap[priority]}
      style={{
        padding: 4,
        maxWidth: '100%',
        lineHeight: 1,
        margin: noMargin ? 0 : undefined,
      }}
    >
      {Icon && <Icon />}
      {!iconOnly && (
        <span style={{ fontWeight: 500 }}>
          {ticketTicketPriorityChoicesLabelMap[priority]}
        </span>
      )}
    </Tag>
  )
}

export const getPriorityTagColumn =
  <RecordType extends RSA>(): TableColumnProps<RecordType>['render'] =>
  (priority?: TicketTicketPriorityChoices) => {
    return priority ? <PriorityTag priority={priority} /> : NO_DATA
  }
