/* eslint-disable no-underscore-dangle */
import React from 'react'
import { CalendarProps } from 'antd'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import { css } from 'styled-components'

import { Typography } from './Typography'

/**
 * these styles are automatically added to storybook global styles so no need to add them manually on FE.
 * src/styles.tsx
 */
export const GetCalendarPropsGlobalStyles = css`
  .whf-ant-calendar-base {
    padding-left: 32px;

    .ant-picker-week-number {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin-left: -28px;
      width: 24px;
      height: 24px;
      cursor: default;
      pointer-events: none;
    }
  }
`

const today = moment()

export const getCalendarProps = ({
  className,
  dateCellRender,
  fullscreen,
}: Pick<
  CalendarProps<Moment>,
  'className' | 'dateCellRender' | 'fullscreen'
>): Pick<CalendarProps<Moment>, 'dateFullCellRender' | 'className'> => {
  // @ts-expect-error WIP
  const firstDayOfWeek = moment().localeData()._week.dow
  return {
    dateFullCellRender: (current) => {
      return (
        <>
          {current.day() === firstDayOfWeek && (
            <div className="ant-picker-week-number">
              <Typography style={{ color: '#BFBFBF' }}>
                {moment(current).endOf('week').isoWeek()}
              </Typography>
            </div>
          )}
          <div
            className={classNames(
              `ant-picker-cell-inner`,
              `ant-picker-calendar-date`,
              {
                [`ant-picker-calendar-date-today`]: moment(current).isSame(
                  today,
                  'd'
                ),
              }
            )}
          >
            <div className="ant-picker-calendar-date-value">
              {moment(current).date()}
            </div>
            <div className="ant-picker-calendar-date-content">
              {fullscreen && dateCellRender && dateCellRender(current)}
            </div>
          </div>
        </>
      )
    },
    className: classNames(className, 'whf-ant-calendar-base'),
  }
}
