import { getOverrideApiUrl } from 'src/new-components/forms/app-config/state'

const { protocol } = window.location

const apiUrlCookie = getOverrideApiUrl()

export const baseUrl = `${
  process.env.REACT_APP_API_SSL === 'true' ? 'https:' : protocol
}//${apiUrlCookie || process.env.REACT_APP_API_URL}`

export const APIURLS = {
  production: process.env.REACT_APP_API_PRODUCTION_URL || 'not-set',
  staging: process.env.REACT_APP_API_STAGING_URL || 'not-set',
  development: process.env.REACT_APP_API_DEVELOPMENT_URL || 'not-set',
  preview: process.env.REACT_APP_API_PREVIEW_URL || 'not-set',
}
