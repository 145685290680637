import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

export const createPickupHistoryRouteMap = (prefix: string) => ({
  '/:id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(
      (prefix2: string) => ({
        history: pathJoin(prefix2)('history').chain,
      }),
      {
        title: <T _str="Pickup order details" />,
        breadCrumbTitle: <T _str="Details" />,
      }
    ),
})
