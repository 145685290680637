import { flatten } from 'lodash'

export const array = <T>(
  ...a: Array<
    Array<T | undefined | false | null> | T | undefined | false | null
  >
): Array<T> => flatten(a).filter(Boolean) as T[]

export function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const result = [...arr]
  const element = result[fromIndex]
  result.splice(fromIndex, 1)
  result.splice(toIndex, 0, element)

  return result
}
