import React from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TAgreementExportMode = `total` | `change`

export const agreementExportModeLableMap: Record<
  TAgreementExportMode,
  React.ReactNode
> = {
  change: <T _str="Change" />,
  total: <T _str="Total" />,
}

export const AGREEMENT_EXPORT_MODE_OPTIONS = createOptionsFromLabelMap(
  agreementExportModeLableMap
)
