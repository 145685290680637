import React from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'
import styled from 'styled-components'

import { LevelChoices } from 'src/gen-types'
import { Typography } from 'src/storybook/components'
import { gray700 } from 'src/styles/color-theme'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const ticketTypeLevelTypeLabelMap: Record<
  LevelChoices,
  React.ReactNode
> = {
  [LevelChoices.ALL]: <T _str="Container on property level" />,
  [LevelChoices.ONLY_CRM]: <T _str="Property level" />,
  [LevelChoices.ONLY_TICKETS]: <T _str="Container level" />,
}

export const ticketTypeLevelTypeColorMap: Record<
  LevelChoices,
  TagProps['color']
> = {
  [LevelChoices.ONLY_TICKETS]: 'orange',
  [LevelChoices.ONLY_CRM]: 'purple',
  [LevelChoices.ALL]: 'green',
}

export const ticketTypeLevelTypeOptions = createOptionsFromLabelMap(
  ticketTypeLevelTypeLabelMap
)

const Description = styled(Typography)`
  &.ant-typography {
    margin: 0;
    color: ${gray700};
  }
`

const ticketTypeLevelWithDescription: Record<LevelChoices, React.ReactNode> = {
  [LevelChoices.ONLY_TICKETS]: (
    <>
      {ticketTypeLevelTypeLabelMap[LevelChoices.ONLY_TICKETS]}
      <Description component="Paragraph" variant="body-12">
        <T _str="For containers without property information. (container=true, property=false)" />
      </Description>
    </>
  ),
  [LevelChoices.ONLY_CRM]: (
    <>
      {ticketTypeLevelTypeLabelMap[LevelChoices.ONLY_CRM]}
      <Description component="Paragraph" variant="body-12">
        <T _str="The ticket will contain only property information. (container=false, property=true)" />
      </Description>
    </>
  ),
  [LevelChoices.ALL]: (
    <>
      {ticketTypeLevelTypeLabelMap[LevelChoices.ALL]}
      <Description component="Paragraph" variant="body-12">
        <T _str="For containers attached to a property. (container=true, property=true)" />
      </Description>
    </>
  ),
}

export const ticketTypeLevelTypeOptionsWithDescription =
  createOptionsFromLabelMap(ticketTypeLevelWithDescription)
