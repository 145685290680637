import React from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TSubscriptionMedia = `sms` | `email`

export const subscriptionMediaColorMap: Record<
  TSubscriptionMedia,
  TagProps['color']
> = {
  sms: 'purple',
  email: 'orange',
}

export const subscriptionMediaLabelMap: Record<
  TSubscriptionMedia,
  React.ReactNode
> = {
  sms: <T _str="SMS" />,
  email: <T _str="Email" />,
}

export const SUBSCRIPTION_MEDIA_OPTIONS = createOptionsFromLabelMap(
  subscriptionMediaLabelMap
)
