import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { IntegrationsRfidVehicleConnectionProviderChoices } from 'src/gen-types'

export const IntegrationsRfidVehicleConnectionProviderChoicesLabelMap: Record<
  IntegrationsRfidVehicleConnectionProviderChoices,
  ReactNode
> = {
  POULTARP: <T _str="Poultarp" />,
  BOTEK: <T _str="Botek" />,
  SWECO: <T _str="Sweco" />,
}
