import React from 'react'
import { T } from '@transifex/react'

import { createCompanyRoutesMap } from './company'
import { createCompanyCrmPricingRoutesMap } from './company-crm-pricing'
import { createProjectRouteMap } from './project'
import { createProjectsDetailView } from './projects'
import { pathJoin } from '../../../utils'

export const createSettingsPageRouteMap = (prefix: string) => ({
  'company/:id': (id = ':id') =>
    pathJoin(prefix)('company')(id).chainMapAuto(createCompanyRoutesMap, {
      title: <T _str="Settings" />,
      breadCrumbTitle: <T _str="Settings" />,
    }),
  'project/:id': (id = '') =>
    pathJoin(prefix)('project')(id).chainMapAuto(createProjectRouteMap, {
      title: <T _str="Project Details" />,
      breadCrumbTitle: <T _str="Project Details" />,
    }),
  projects: pathJoin(prefix)('projects').chainMapAuto(
    createProjectsDetailView,
    {
      title: <T _str="All Projects" />,
      breadCrumbTitle: <T _str="All Projects" />,
    }
  ),
  'company-crm-pricing': pathJoin(prefix)('company-crm-pricing').chainMapAuto(
    createCompanyCrmPricingRoutesMap,
    {
      title: <T _str="CRM Pricing" />,
      breadCrumbTitle: <T _str="CRM Pricing" />,
    }
  ),
})
