import { pathJoin } from 'src/reducers/routing/utils'

export const createGeneralViewRoutesMap = (prefix: string) => ({
  company: pathJoin(prefix)('company').chain,
  'crm-portal': pathJoin(prefix)('crm-portal').chain,
  'email-accounts': pathJoin(prefix)('email-accounts').chain,
  'criipto-application': pathJoin(prefix)('criipto-application').chain,
  settings: pathJoin(prefix)('settings').chain,
  'digital-post': pathJoin(prefix)('digital-post').chain,
})
