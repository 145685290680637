import React, { useCallback, useMemo } from 'react'
import { useT } from '@transifex/react'
import { useLocation } from 'react-router-dom'
import { Menu } from 'src/antd'
import styled, { css } from 'styled-components'

import { useMe, useSavedActiveProjectsArray } from 'src/hooks'
import { useRoutingHistory } from 'src/reducers/routing/child-router-factory'
import {
  HISTORY_STORE_ACTIONS_KEY,
  RESET_HISTORY,
} from 'src/reducers/routing/store'

import getItems, { SELECTED_CLASS, SELECTED_SUBMENU_CLASS } from './getItems'
import { TSidebarItem } from '../../../types'

export const SubMenuStyle = css`
  .custom-submenu {
    .ant-menu {
      width: 220px;
      padding: 12px 8px;
      background-color: ${(p) => p.theme.navy100};
      border-radius: 4px;
    }

    .ant-menu-item-group-title {
      margin-bottom: 12px;
      padding: 0 8px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      color: ${(p) => p.theme.gray900};
    }

    .ant-menu-item {
      height: unset;
      margin: 0 !important;
      padding: 8px 12px;
      line-height: unset;
      border-radius: 6px;

      &:hover {
        background-color: rgba(17, 29, 44, 0.08);
        color: inherit;
      }

      &.${SELECTED_SUBMENU_CLASS} {
        background-color: ${(p) => p.theme.navy900};
        color: ${(p) => p.theme.gray100};
      }

      .anticon {
        font-size: 16px;
        line-height: 1;
      }

      &:last-child {
        margin-top: 12px !important;
        padding: 0 8px;
        font-size: 12px;
        line-height: 1.4;
        color: ${(p) => p.theme.gray700};
      }
    }
  }
`

const StyledMenu = styled(Menu)<{ $isSelected?: boolean }>`
  border: none;

  && .ant-menu-item,
  &&& .ant-menu-submenu {
    height: unset;
    margin: 4px;
    padding: 12px;
    text-align: center;
    border-radius: 6px;
    line-height: unset;
    color: ${(p) => p.theme.navy900};

    &.ant-menu-item-selected,
    &.ant-menu-submenu-selected,
    &.ant-menu-submenu-active,
    &:hover {
      background-color: rgba(17, 29, 44, 0.08);
      color: ${(p) => p.theme.navy900};
    }

    &.${SELECTED_CLASS} {
      background-color: ${(p) => p.theme.navy900};
      color: ${(p) => p.theme.gray100};
    }
  }

  &&& .ant-menu-submenu {
    padding: 0;
  }

  && .ant-menu-item .ant-menu-item-icon,
  &&& .ant-menu-submenu .ant-menu-item-icon {
    display: inline-flex;
    font-size: 22px;
    line-height: 1;

    + span {
      display: none;
    }
  }

  && .ant-badge .anticon {
    display: inline-flex;
    font-size: inherit;
    line-height: inherit;
  }

  &&& .ant-menu-submenu-title {
    height: unset;
    margin: 0;
    padding: 12px;
    line-height: unset;

    &:hover {
      color: inherit;
    }
  }
`

type TNavigationMenuProps = {
  openedKey?: string
  onClick: (item?: TSidebarItem) => void
}

const NavigationMenu: React.FC<TNavigationMenuProps> = ({
  openedKey,
  onClick,
}) => {
  const t = useT()
  const [history, { roles }] = useRoutingHistory()
  const { pathname } = useLocation()
  const me = useMe()
  const selectedProjects = useSavedActiveProjectsArray().length || 0
  const opened = Boolean(openedKey)

  const onLinkClick = useCallback(
    (path: string, state?: Record<string, unknown>) => {
      history.push(path, {
        ...state,
        [HISTORY_STORE_ACTIONS_KEY]: [RESET_HISTORY],
      })
      onClick(undefined)
    },
    [history, onClick]
  )

  const items = useMemo(
    () =>
      getItems(
        t,
        opened,
        selectedProjects,
        me.company.id,
        roles,
        pathname,
        onClick,
        onLinkClick
      ),
    [
      t,
      opened,
      selectedProjects,
      me.company.id,
      roles,
      pathname,
      onClick,
      onLinkClick,
    ]
  )

  return (
    <StyledMenu
      items={items}
      selectedKeys={openedKey ? [openedKey] : []}
      openKeys={openedKey ? [] : undefined}
    />
  )
}

export default NavigationMenu
