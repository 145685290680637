import React from 'react'
import { T } from '@transifex/react'

import { createDashboardsRoutesMap } from './dashboards'
import { createExportsRoutesMap } from './exports'
import { createImportsRoutesMap } from './imports'
import { pathJoin } from '../../../utils'

export const createAnalyticsRoutesMap = (prefix: string) => ({
  'classic-exports': pathJoin(prefix)('classic-exports').chainWithTitle({
    title: <T _str="Analytics Classic Exports" />,
    breadCrumbTitle: <T _str="Classic Exports" />,
  }),
  exports: pathJoin(prefix)('exports').chainMapAuto(createExportsRoutesMap, {
    title: <T _str="Analytics Exports" />,
    breadCrumbTitle: <T _str="Exports" />,
  }),
  imports: pathJoin(prefix)('imports').chainMapAuto(createImportsRoutesMap, {
    title: <T _str="Analytics Imports" />,
    breadCrumbTitle: <T _str="Imports" />,
  }),
  dashboards: pathJoin(prefix)('dashboards').chainMapAuto(
    createDashboardsRoutesMap
  ),
})
