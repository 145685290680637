import React from 'react'
import { Divider as AntdDivider, DividerProps } from 'antd'

// Recreated component with strict props to prevent warnings when put Divider into Collapse component
export function Divider({
  prefixCls,
  type,
  orientation,
  orientationMargin,
  className,
  children,
  dashed,
  style,
  plain,
}: DividerProps) {
  return (
    <AntdDivider
      prefixCls={prefixCls}
      type={type}
      orientation={orientation}
      orientationMargin={orientationMargin}
      className={className}
      dashed={dashed}
      style={style}
      plain={plain}
    >
      {children}
    </AntdDivider>
  )
}
