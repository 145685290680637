import React from 'react'
import { useTX } from '@transifex/react'

import { TCoreIoUserLanguageChoices } from '../api/consts'
import { useMe } from '../hooks'

const transifexLangMap: Record<TCoreIoUserLanguageChoices, string> = {
  DA: 'da',
  EN: 'en',
}

/**
 * Set transifex language based on user setting
 */
const Transifex = () => {
  const me = useMe()
  const tx = useTX()

  const userLanguage = me?.language as TCoreIoUserLanguageChoices

  if (userLanguage && me) {
    const isLangSupported = transifexLangMap[userLanguage]
    if (isLangSupported) {
      tx.setCurrentLocale(isLangSupported)
    } else {
      // eslint-disable-next-line no-console
      console.log(
        new Error(`
      User language: ${userLanguage} is not supported by transifex.
      Setting language to en as fallback.
      `)
      )
    }
  }

  return <></>
}

export default Transifex
