import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type WasteFractionWasteType =
  | `food`
  | `paper`
  | `cardboard`
  | `metal`
  | `glass`
  | `plastic`
  | `textile`
  | `beverage_food_cartons`
  | `residual`
  | `hazardous`
  | 'bulk_waste'

export const wasteFractionWasteTypeLabelMap: Record<
  WasteFractionWasteType,
  ReactNode
> = {
  beverage_food_cartons: <T _str="Beverage and food cartons" />,
  hazardous: <T _str="Hazardous" />,
  residual: <T _str="Residual" />,
  cardboard: <T _str="Cardboard" />,
  food: <T _str="Food" />,
  glass: <T _str="Glass" />,
  metal: <T _str="Metal" />,
  paper: <T _str="Paper" />,
  plastic: <T _str="Plastic" />,
  textile: <T _str="Textile" />,
  bulk_waste: <T _str="Bulk waste" />,
}
