import React from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type THaulerPriceActiveStatus = `active` | `inactive`

export const haulerPriceActiveStatusLabelMap: Record<
  THaulerPriceActiveStatus,
  React.ReactNode
> = {
  active: <T _str="Active" />,
  inactive: <T _str="Inactive" />,
}
export const haulerPriceActiveStatusTagColorMap: Record<
  THaulerPriceActiveStatus,
  TagProps['color']
> = {
  active: 'green',
  inactive: 'gray',
}

export const haulerPriceActiveStatusOptions = createOptionsFromLabelMap(
  haulerPriceActiveStatusLabelMap
)
