import { pathJoin } from 'src/reducers/routing/utils'

import { createPlanRouteMap } from './subscription'

export const createSubscriptionRoutesMap = (prefix: string) => ({
  billingInformation: pathJoin(prefix)('billingInformation').chain,
  paymentMethods: pathJoin(prefix)('paymentMethods').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  subscription:
    pathJoin(prefix)('subscription').chainMapAuto(createPlanRouteMap),
})
