import React, { FC } from 'react'
import { Dropdown, DropDownProps, MenuProps } from 'src/antd'

import { DropdownSimpleToggler, TDropdownSimpleTogglerProps } from './togglers'

export type TDropdownSimpleProps = {
  items: MenuProps['items']
  dropdownProps?: Omit<DropDownProps, 'overlay'>
  menuProps?: MenuProps
}

export type TDropdownSimpleComponentsProps = {
  TDropdownSimpleTogglerProps: TDropdownSimpleTogglerProps
}

type DropdownSimpleWithTogglers = FC<TDropdownSimpleProps> & {
  DropdownSimpleToggler: typeof DropdownSimpleToggler
}

const DropdownSimple: DropdownSimpleWithTogglers = ({
  children,
  items,
  dropdownProps = {},
  menuProps = {},
}) => (
  <Dropdown
    className="wh-styled-simple-dropdown"
    {...dropdownProps}
    menu={{ items, ...menuProps }}
  >
    {children}
  </Dropdown>
)

DropdownSimple.DropdownSimpleToggler = DropdownSimpleToggler

export { DropdownSimple }
