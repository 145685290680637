import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { Typography } from 'src/storybook/components'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TEventTicketEventEventTypeChoices =
  | 'A_1'
  | 'A_2'
  | 'A_3'
  | `A_4`
  | 'A_10'
  | 'A_11'
  | 'A_12'
  | 'A_13'

export const eventTicketEventEventTypeChoicesLabelMap: Record<
  TEventTicketEventEventTypeChoices,
  ReactNode
> = {
  A_1: <T _str="Change Status" />,
  A_2: <T _str="Change Assignee" />,
  A_3: <T _str="Change Priority" />,
  A_4: <T _str="Change Assigned Team" />,
  A_10: <T _str="Updated ticket detail" />,
  A_11: <T _str="Scan barcode" />,
  A_12: <T _str="Print label" />,
  A_13: <T _str="Pair RFID" />,
}

const ActionWithThe = ({
  children,
  action,
}: {
  children: JSX.Element
  action: JSX.Element
}) => (
  <T
    _str="{action} the {value}"
    value={<Typography weight="semi-bold">{children}</Typography>}
    action={action}
  />
)

export const eventTicketEventEventTypeChoicesLabelMap2: Record<
  TEventTicketEventEventTypeChoices,
  ReactNode
> = {
  A_1: (
    <ActionWithThe action={<T _str="changed" />}>
      <T _str="Status" />
    </ActionWithThe>
  ),
  A_2: (
    <ActionWithThe action={<T _str="changed" />}>
      <T _str="Assignee" />
    </ActionWithThe>
  ),
  A_3: (
    <ActionWithThe action={<T _str="changed" />}>
      <T _str="Priority" />
    </ActionWithThe>
  ),
  A_4: (
    <ActionWithThe action={<T _str="changed" />}>
      <T _str="Assigned Team" />
    </ActionWithThe>
  ),
  A_10: (
    <Typography weight="semi-bold">
      <T _str="Modified" />
    </Typography>
  ),
  A_11: (
    <ActionWithThe action={<T _str="scanned" />}>
      <T _str="Barcode" />
    </ActionWithThe>
  ),
  A_12: (
    <ActionWithThe action={<T _str="requested" />}>
      <T _str="Print label" />
    </ActionWithThe>
  ),
  A_13: (
    <ActionWithThe action={<T _str="paired" />}>
      <T _str="RFID" />
    </ActionWithThe>
  ),
}

export const ticketEventTypeOptions = createOptionsFromLabelMap(
  eventTicketEventEventTypeChoicesLabelMap
)
