import React from 'react'
import { T } from '@transifex/react'

import { CoreExportExportTypeChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const classicExportTypeLabelMap: Record<
  CoreExportExportTypeChoices,
  React.ReactNode
> = {
  [CoreExportExportTypeChoices.CONTAINERS]: <T _str="Containers" />,
  [CoreExportExportTypeChoices.CONTAINER_AVAILABLE_DATES]: (
    <T _str="Container available dates" />
  ),
  [CoreExportExportTypeChoices.AGREEMENTS]: <T _str="Agreements" />,
  [CoreExportExportTypeChoices.AGREEMENT_AVAILABLE_DATES]: (
    <T _str="Agreement available dates" />
  ),
  [CoreExportExportTypeChoices.PICK_UP_ORDERS]: <T _str="Pick up orders" />,
  [CoreExportExportTypeChoices.FILL_LEVEL_MEASUREMENTS]: (
    <T _str="Fill level measurements" />
  ),
  [CoreExportExportTypeChoices.TICKETS]: <T _str="Tickets" />,
  [CoreExportExportTypeChoices.ROUTES]: <T _str="Routes" />,
  [CoreExportExportTypeChoices.STATIC_ROUTES]: <T _str="Static routes" />,
  [CoreExportExportTypeChoices.PROPERTIES]: <T _str="Properties" />,
  [CoreExportExportTypeChoices.HAULER_PRICES]: <T _str="Hauler prices" />,
  [CoreExportExportTypeChoices.PRODUCTS]: <T _str="Products" />,
}

export const classicExportTypeOptions = createOptionsFromLabelMap(
  classicExportTypeLabelMap
)
