import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createExportsDetailViewRoutesMap } from './id'

export const createExportsRoutesMap = (prefix: string) => ({
  ':id/generate': (id = ':id') =>
    pathJoin(prefix)(`${id}/generate`).chainWithTitle({
      title: <T _str="Create export" />,
      breadCrumbTitle: <T _str="Create" />,
    }),
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createExportsDetailViewRoutesMap, {
      title: <T _str="Export detail" />,
      breadCrumbTitle: <T _str="Export detail" />,
    }),
})
