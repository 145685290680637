import React from 'react'
import { CaretDownFilled } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'

import { bgHover, gray600 } from 'src/styles/color-theme'

const StyledButton = styled(Button)`
  padding: 0 12px;
  height: 40px;
  border: none;
  box-shadow: none;
  width: auto;
  display: inline-flex;
  align-items: center;
  gap: 6px;

  &:hover {
    background-color: ${bgHover};
  }

  svg {
    font-size: 16px;
  }
`

const StyledCaretDownFilled = styled(CaretDownFilled)`
  svg {
    font-size: 12px;
    color: ${gray600};
  }
`

type TFilterMenuButtonProps = ButtonProps & {
  withCaretIcon?: boolean
}

export function FilterMenuButton({
  children,
  withCaretIcon,
  ...props
}: TFilterMenuButtonProps) {
  return (
    <StyledButton type="text" {...props}>
      {children}
      {withCaretIcon && (
        <div style={{ fontSize: 0 }}>
          <StyledCaretDownFilled />
        </div>
      )}
    </StyledButton>
  )
}
