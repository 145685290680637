import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { ImportTypes } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const importTypeLabelMap: Record<ImportTypes, ReactNode> = {
  [ImportTypes.COLLECTION_CALENDAR]: <T _str="Collection calendars" />,
  [ImportTypes.COLLECTION_CALENDAR_DATE]: (
    <T _str="Collection calendar dates" />
  ),
  [ImportTypes.CONTAINER]: <T _str="Containers" />,
  [ImportTypes.CONTAINER_TYPE]: <T _str="Container types" />,
  [ImportTypes.PICKUP_SETTING]: <T _str="Pickup settings" />,
  [ImportTypes.TICKET]: <T _str="Tickets" />,
  [ImportTypes.VEHICLE]: <T _str="Vehicles" />,
  [ImportTypes.WASTE_FRACTION]: <T _str="Waste fractions" />,
  [ImportTypes.PROPERTY]: <T _str="Properties" />,
}

export const importTypesOptions = createOptionsFromLabelMap(importTypeLabelMap)

export const importTypeColorMap: Record<ImportTypes, TagProps['color']> = {
  [ImportTypes.COLLECTION_CALENDAR]: 'cyan',
  [ImportTypes.COLLECTION_CALENDAR_DATE]: 'green',
  [ImportTypes.CONTAINER]: 'orange',
  [ImportTypes.CONTAINER_TYPE]: 'volcano',
  [ImportTypes.PICKUP_SETTING]: 'yellow',
  [ImportTypes.TICKET]: 'blue',
  [ImportTypes.VEHICLE]: 'pink',
  [ImportTypes.WASTE_FRACTION]: 'gold',
  [ImportTypes.PROPERTY]: 'purple',
}
