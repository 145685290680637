import React from 'react'
import { T } from '@transifex/react'

import { createAnalyticsRoutesMap } from './analytics'
import { createRoutesMapAssetManagement } from './asset-management'
import { createCompanyIdRoutesMap } from './companyId'
import { createControlCenterRoutesMap } from './control-center'
import { createCustomerManagementRouteMap } from './customer-management'
import { createDashboardsRoutesMap } from './dashboards'
import { createRoutesMapFleetManagement } from './fleet-management'
import { createMarketPlaceRouteMap } from './marketplace'
import { createNotificationsRoutesMap } from './notifications'
import { createOperationManagementRouteMap } from './operation-management'
import { createProjectIdRoutesMap } from './projectId'
import { createSettingsPageRouteMap } from './settings'
import { createTicketsRouteMap } from './tickets'
import { createUsersRouteMap } from './user'
import { pathJoin } from '../../utils'

export const createRouteMapMain = (prefix: string) => ({
  dashboard: pathJoin(prefix)(`dashboard`).chainWithTitle({
    title: <T _str="Dashboard" />,
    breadCrumbTitle: <T _str="Dashboard" />,
  }),
  dashboards: pathJoin(prefix)('dashboards').chainMapAuto(
    createDashboardsRoutesMap,
    {
      title: <T _str="Dashboards" />,
      breadCrumbTitle: <T _str="Dashboards" />,
    }
  ),
  tickets: pathJoin(prefix)('tickets').chainMapAuto(createTicketsRouteMap, {
    title: <T _str="All Tickets" />,
    breadCrumbTitle: <T _str="All Tickets" />,
  }),
  'asset-management': pathJoin(prefix)('asset-management').chainMapAuto(
    createRoutesMapAssetManagement
  ),
  'fleet-management': pathJoin(prefix)('fleet-management').chainMapAuto(
    createRoutesMapFleetManagement
  ),
  'operation-management': pathJoin(prefix)('operation-management').chainMap(
    createOperationManagementRouteMap(
      pathJoin(prefix)('operation-management').value.slice(1)
    )
  ),
  'control-center': pathJoin(prefix)('control-center').chainMap(
    createControlCenterRoutesMap(
      pathJoin(prefix)('control-center').value.slice(1)
    )
  ),
  reports: pathJoin(prefix)('reports').chain,
  support: pathJoin(prefix)('support').chain,
  settings: pathJoin(prefix)('settings').chainMap(
    createSettingsPageRouteMap(pathJoin(prefix)('settings').value.slice(1))
  ),
  notifications: pathJoin(prefix)('notifications').chainMapAuto(
    createNotificationsRoutesMap,
    {
      title: <T _str="Notifications" />,
      breadCrumbTitle: <T _str="Notifications" />,
    }
  ),
  'send-mqtt': pathJoin(prefix)('send-mqtt').chain,
  analytics: pathJoin(prefix)('analytics').chainMapAuto(
    createAnalyticsRoutesMap,
    {
      title: <T _str="Analytics" />,
      breadCrumbTitle: <T _str="Analytics" />,
    }
  ),
  'customer-management': pathJoin(prefix)('customer-management').chainMap(
    createCustomerManagementRouteMap(
      pathJoin(prefix)('customer-management').value.slice(1)
    )
  ),
  'market-place': pathJoin(prefix)('market-place').chainMapAuto(
    createMarketPlaceRouteMap,
    {
      title: <T _str="Marketplace" />,
      breadCrumbTitle: <T _str="Marketplace" />,
    }
  ),
  profile: pathJoin(prefix)('profile').chainMapAuto(createUsersRouteMap, {
    title: <T _str="User Profile" />,
    breadCrumbTitle: <T _str="User Profile" />,
  }),
  'users/:userId': (userId: string) =>
    pathJoin(prefix)('users')(userId).chainMapAuto(createUsersRouteMap, {
      title: <T _str="User Details" />,
      breadCrumbTitle: <T _str="User Details" />,
    }),
  'project/:id': (id = ':id') =>
    pathJoin(prefix)(`project/${id}`).chainMapAuto(createProjectIdRoutesMap),
  'company/:id': (id = ':id') =>
    pathJoin(prefix)(`company/${id}`).chainMapAuto(createCompanyIdRoutesMap),
})
