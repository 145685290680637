import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createDetailRoutesMap } from './detailed-view'

export const createPropertyRoutesMap = (prefix: string) => ({
  map: pathJoin(prefix)('map').chainWithTitle({
    title: <T _str="Properties map" />,
    breadCrumbTitle: <T _str="Properties map" />,
  }),
  '/:id': (id: string) =>
    pathJoin(prefix)('id')(id).chainMapAuto(createDetailRoutesMap, {
      title: <T _str="Property Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  'contact/:contactId': (id: string) =>
    pathJoin(prefix)('contact')(id).chainWithTitle({
      title: <T _str="Contact Details" />,
      breadCrumbTitle: <T _str="Contact Details" />,
    }),
  'create-contact/:propertyId': (id: string) =>
    pathJoin(prefix)('create-contact')(id).chainWithTitle({
      title: <T _str="New Contact" />,
      breadCrumbTitle: <T _str="New Contact" />,
    }),
  search: pathJoin(prefix)('search').chainWithTitle({
    title: <T _str="Search Property" />,
    breadCrumbTitle: <T _str="Search" />,
  }),
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="New Property" />,
    breadCrumbTitle: <T _str="New Property" />,
  }),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainWithTitle({
      title: <T _str="Edit Property" />,
      breadCrumbTitle: <T _str="Edit" />,
    }),
})
