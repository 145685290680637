import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TCrmScheduledExportFilterTicketDateChoices =
  | `CREATED_AT`
  | `OPEN_AT`
  | 'PENDING_AT'
  | 'IN_PROGRESS_AT'
  | 'COMPLETED_AT'
  | 'PREFERRED_DATE'

export const crmScheduledExportFilterTicketDateChoices: Record<
  TCrmScheduledExportFilterTicketDateChoices,
  ReactNode
> = {
  OPEN_AT: <T _str="Open at" />,
  CREATED_AT: <T _str="Created at" />,
  IN_PROGRESS_AT: <T _str="In progress at" />,
  PENDING_AT: <T _str="Pending at" />,
  COMPLETED_AT: <T _str="Completed at" />,
  PREFERRED_DATE: <T _str="Preferred date" />,
}

export const crmScheduledExportFilterTicketDateChoicesOptions =
  getOptsFromValueMap(crmScheduledExportFilterTicketDateChoices)
