import { gql } from '@apollo/client'

export const GET = gql`
  query GetLocationInfoPopover($id: ID!) {
    depot(id: $id) {
      id
      logo
      name
      depotType
      location {
        id
        name
      }
      openingTime
      closingTime
      contactPerson {
        name
      }
    }
  }
`
