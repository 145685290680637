import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

export const createAlertRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainWithTitle({
      title: <T _str="Alert Rule Detail" />,
      breadCrumbTitle: <T _str="Alert Rule Detail" />,
    }),
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="Create Alert Rule" />,
    breadCrumbTitle: <T _str="Create Alert Rule" />,
  }),
})
