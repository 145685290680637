import { makeVar } from '@apollo/client'

import { routingMap } from 'src/reducers/routing/routing-map'

export type TRoutingVarType = {
  roles: string[]
  routingMap: typeof routingMap
  init: boolean
}

export const INITIAL_ROUTING_DATA = {
  roles: [],
  routingMap,
  init: false,
}

export const routingVar = makeVar<TRoutingVarType>(INITIAL_ROUTING_DATA)
