import React from 'react'
import Icon from '@ant-design/icons'
import BraftEditor from 'braft-editor'
import { Dropdown } from 'src/antd'

import ControlBarButton from './ControlBarButton'
import { TX_TEXT_COLOR } from '../../../transifex'
import { TwitterColorPicker } from '../../ColorPicker'

type TextColorProps = {
  editorState: BraftEditor
  onColorChange: (color: string) => void
}

const DEFAULT_COLOR = '#000000'

const TextColor = ({ editorState, onColorChange }: TextColorProps) => {
  let currentColor: string | null = null

  const selectionStyles = (editorState as $TSFixMe)
    .getCurrentInlineStyle()
    .toJS()

  selectionStyles.forEach((style: $TSFixMe) => {
    if (style.indexOf('COLOR-') === 0) {
      currentColor = `#${style.split('-')[1]}`
    }
  })

  if (!currentColor) {
    currentColor = DEFAULT_COLOR
  }

  return (
    <Dropdown
      placement="top"
      dropdownRender={() => (
        <TwitterColorPicker
          value={currentColor || ''}
          onChange={onColorChange}
        />
      )}
      trigger={['click']}
    >
      <ControlBarButton
        color={currentColor}
        tooltip={TX_TEXT_COLOR}
        icon={
          <Icon
            component={() => (
              <svg
                style={{ height: 14, width: 14 }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.5 13.47"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      style={{ fill: currentColor! }}
                      d="M12.38,12H.12a.12.12,0,0,0-.12.12v1.25a.13.13,0,0,0,.12.13H12.38a.13.13,0,0,0,.12-.13V12.09A.12.12,0,0,0,12.38,12Z"
                    />
                    <path
                      style={{ fill: 'black' }}
                      d="M10.45,9.55,7.19.1A.18.18,0,0,0,7.14,0a.16.16,0,0,0-.09,0H5.45a.15.15,0,0,0-.14.1L2.05,9.55a.43.43,0,0,0,0,0,.16.16,0,0,0,.16.15H3.52a.15.15,0,0,0,.15-.11l.84-2.59H7.93l.84,2.59a.14.14,0,0,0,.14.11h1.44a.05.05,0,0,0,.05,0,.1.1,0,0,0,0-.05.07.07,0,0,0,0-.05S10.45,9.57,10.45,9.55ZM4.87,5.81,6.19,1.69h.07L7.57,5.81Z"
                    />
                  </g>
                </g>
              </svg>
            )}
          />
        }
      />
    </Dropdown>
  )
}

export default TextColor
