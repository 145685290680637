import React from 'react'
import { OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'

import ControlBarButton from './ControlBarButton'
import { TX_BULLET_LIST, TX_NUMBER_LIST } from '../../../transifex'

const blocks = [
  {
    block: 'ordered-list-item',
    icon: <OrderedListOutlined />,
    tooltip: TX_NUMBER_LIST,
  },
  {
    block: 'unordered-list-item',
    icon: <UnorderedListOutlined />,
    tooltip: TX_BULLET_LIST,
  },
]

type BlockProps = {
  editorState: BraftEditor
  toggleBlock: (block: string) => void
}

const Block = ({ editorState, toggleBlock }: BlockProps) => {
  return (
    <Row gutter={8}>
      {blocks.map(({ icon, tooltip, block }) => (
        <Col key={block}>
          <ControlBarButton
            active={ContentUtils.getSelectionBlockType(editorState) === block}
            onClick={() => toggleBlock(block)}
            tooltip={tooltip}
            icon={icon}
          />
        </Col>
      ))}
    </Row>
  )
}

export default Block
