/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { message } from 'antd'
import { useControl, ControlPosition } from 'react-map-gl'
import { css } from 'styled-components'

import { TX_SEARCH_REQUEST_FAILED } from 'src/storybook/transifex'

import { getMapboxBaseProps } from '../shared'
import { TLngLat } from '../types'

export const MapboxGeocoderStyle = css`
  .mapboxgl-ctrl-geocoder .suggestions {
    max-height: 300px;
    overflow-y: auto;
  }
`

export const MUTATION_SEARCH_LOCATIONS = gql`
  mutation MapboxSearchLocations2($country: String, $search: String!) {
    searchLocation(country: $country, search: $search) {
      locations {
        placeId
        name
        longitude
        latitude
        municipalityCode
        postalCode
        postalCodeName
        street
        streetNumber
      }
    }
  }
`

export type GeocoderControlProps = Omit<
  GeocoderOptions,
  'accessToken' | 'mapboxgl' | 'marker'
> & {
  position: ControlPosition
  onLoading?: (e: object) => void
  onResults?: (e: object) => void
  onResult?: (e: object, location?: TLngLat) => void
  onError?: (e: object) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

/* eslint-disable complexity,max-statements */
export function useGeocoderControl(props: GeocoderControlProps) {
  const apollo = useApolloClient()
  const geocoder = useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: false,
        accessToken: getMapboxBaseProps().mapboxAccessToken,
        // no limit as the limit is from the API results - we don't want to limit this. See: https://ioteelab.atlassian.net/browse/IPFE-7859
        limit: Infinity,
        externalGeocoder: async (query) => {
          try {
            const { data } = await apollo.mutate({
              mutation: MUTATION_SEARCH_LOCATIONS,
              variables: {
                search: query,
                // Todo: Fix this: https://ioteelab.atlassian.net/browse/IPFE-4157?focusedCommentId=14299
                // country: controls?.geoCoderCountry || "dk",
              },
            })
            const locations = data?.searchLocation?.locations || []
            // return features
            return locations.map(
              ({
                name,
                longitude,
                latitude,
                placeId,
                municipalityCode,
                postalCode,
                postalCodeName,
                street,
                streetNumber,
              }: $TSFixMe) => ({
                center: [longitude, latitude],
                geometry: {
                  type: 'Point',
                  coordinates: [longitude, latitude],
                },
                properties: {
                  accuracy: 'street',
                },
                type: 'Feature',
                place_name: name,
                placeId,
                municipalityCode,
                postalCode,
                postalCodeName,
                street,
                streetNumber,
                name,
                longitude,
                latitude,
              })
            )
          } catch (e: $TSFixMe) {
            message.error(e?.message || TX_SEARCH_REQUEST_FAILED)
            return []
          }
        },
        clearOnBlur: true,
        localGeocoderOnly: true,
        localGeocoder: () => [],
      })

      ctrl.on('loading', props.onLoading || noop)
      ctrl.on('results', props.onResults || noop)
      ctrl.on('result', (evt) => {
        const { result } = evt
        const location =
          result &&
          (result.center ||
            (result.geometry?.type === 'Point' && result.geometry.coordinates))
        props.onResult?.(evt, location)
        ctrl.clear()
      })
      ctrl.on('error', props.onError || noop)
      return ctrl
    },
    {
      position: props.position,
    }
  )

  useEffect(() => {
    if (geocoder) {
      if (
        geocoder.getProximity() !== props.proximity &&
        props.proximity !== undefined
      ) {
        geocoder.setProximity(props.proximity)
      }
      if (
        geocoder.getRenderFunction() !== props.render &&
        props.render !== undefined
      ) {
        geocoder.setRenderFunction(props.render)
      }
      if (
        geocoder.getLanguage() !== props.language &&
        props.language !== undefined
      ) {
        geocoder.setLanguage(props.language)
      }
      if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
        geocoder.setZoom(props.zoom)
      }
      if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
        geocoder.setFlyTo(props.flyTo)
      }
      if (
        geocoder.getPlaceholder() !== props.placeholder &&
        props.placeholder !== undefined
      ) {
        geocoder.setPlaceholder(props.placeholder)
      }
      if (
        geocoder.getCountries() !== props.countries &&
        props.countries !== undefined
      ) {
        geocoder.setCountries(props.countries)
      }
      if (geocoder.getTypes() !== props.types && props.types !== undefined) {
        geocoder.setTypes(props.types)
      }
      if (
        geocoder.getMinLength() !== props.minLength &&
        props.minLength !== undefined
      ) {
        geocoder.setMinLength(props.minLength)
      }
      if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
        geocoder.setLimit(props.limit)
      }
      if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
        geocoder.setFilter(props.filter)
      }
      if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
        geocoder.setOrigin(props.origin)
      }
      // Types missing from @types/mapbox__mapbox-gl-geocoder
      // if (geocoder.getAutocomplete() !== props.autocomplete && props.autocomplete !== undefined) {
      //   geocoder.setAutocomplete(props.autocomplete);
      // }
      // if (geocoder.getFuzzyMatch() !== props.fuzzyMatch && props.fuzzyMatch !== undefined) {
      //   geocoder.setFuzzyMatch(props.fuzzyMatch);
      // }
      // if (geocoder.getRouting() !== props.routing && props.routing !== undefined) {
      //   geocoder.setRouting(props.routing);
      // }
      // if (geocoder.getWorldview() !== props.worldview && props.worldview !== undefined) {
      //   geocoder.setWorldview(props.worldview);
      // }
    }
  }, [
    geocoder,
    props.countries,
    props.filter,
    props.flyTo,
    props.language,
    props.limit,
    props.minLength,
    props.origin,
    props.placeholder,
    props.proximity,
    props.render,
    props.types,
    props.zoom,
  ])
}

export const GeocoderControl = (props: GeocoderControlProps) => {
  useGeocoderControl(props)
  return null
}
