import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createRouteMapCreateDriver } from './create'
import { createDriverManagementRouteMap } from './detailed-view'

export const createDriverManagementRouter = (prefix: string) => ({
  ':driverId': (driverId: string) =>
    pathJoin(prefix)(driverId).chainMapAuto(createDriverManagementRouteMap, {
      title: <T _str="Driver Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  create: pathJoin(prefix)('create').chainMapAuto(createRouteMapCreateDriver, {
    title: <T _str="New Driver" />,
    breadCrumbTitle: <T _str="New" />,
  }),
})
