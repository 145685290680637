import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { ExportIntervalEnum } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const exportIntervalLabelMap: Record<ExportIntervalEnum, ReactNode> = {
  [ExportIntervalEnum.DAILY]: <T _str="By days" />,
  [ExportIntervalEnum.MONTHLY]: <T _str="By months" />,
}

export const exportIntervalOptions = createOptionsFromLabelMap(
  exportIntervalLabelMap
)
