import React from 'react'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

import { WhIconAntdExternalLink } from 'src/storybook/wh-icons-antd'

export { WasteheroFullOutlined } from './WasteheroFullOutlined'
export { CircleFilled } from './CircleFilled'
export { CircleOutlined } from './CircleOutlined'

export const IconExternalLink = (tf: Partial<CustomIconComponentProps>) => (
  <WhIconAntdExternalLink style={{ fontSize: '15px' }} {...tf} />
)
