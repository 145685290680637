import { useReactiveVar } from '@apollo/client'

import {
  getSavedActiveProjects,
  getSavedActiveProjectsString,
} from 'src/components/shared/utils'
import { activeProjectsVar } from 'src/reactive-variables'

export const useSavedActiveProjects = () =>
  getSavedActiveProjectsString(useReactiveVar(activeProjectsVar))

export const useSavedActiveProjectsArray = () =>
  getSavedActiveProjects(useReactiveVar(activeProjectsVar))
