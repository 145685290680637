import React, { useMemo, useState } from 'react'
import { BookOutlined, SearchOutlined } from '@ant-design/icons'
import { useApolloClient, useQuery } from '@apollo/client'
import { T, useT } from '@transifex/react'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { Checkbox, Divider, Input, Row, Spin } from 'src/antd'
import styled from 'styled-components'

import { GET_ACTIVE_PROJECTS_DETAILS } from 'src/api'
import { GetActiveProjectsDetails } from 'src/api/__generated__/types'
import { useSavedActiveProjectsArray } from 'src/hooks'
import { activeProjectsVar } from 'src/reactive-variables'
import { useRouting } from 'src/reducers/routing/child-router-factory'
import { Typography } from 'src/storybook/components'
import { impersonatorTokenActiveProjectsKey } from 'src/utils/impersonator'

import { SidebarProjectSelectGet } from './__generated__/types'
import { GET_ALL_PROJECTS, UPDATE_ACTIVE_PROJECTS } from './api'

const StyledCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    margin-top: 4px;
    display: flex;
  }
`

const ProjectsSelect = () => {
  const [search, setSearch] = useState('')
  const [updating, setUpdating] = useState(false)
  const t = useT()
  const client = useApolloClient()
  const selectedProjects: string[] = useSavedActiveProjectsArray()
  const { roles } = useRouting()

  const { data, loading } = useQuery<SidebarProjectSelectGet>(
    GET_ALL_PROJECTS,
    {
      variables: { search },
    }
  )

  const options = useMemo(
    () =>
      (data?.allProjects?.edges || []).map((edge) => ({
        value: edge?.node?.id || '',
        label: edge?.node?.name || '',
        disabled:
          selectedProjects.length === 1 &&
          edge?.node?.id === selectedProjects[0],
      })),
    [data, selectedProjects]
  )
  const isChecked = options.every(({ value }) =>
    selectedProjects.includes(value)
  )
  const isIndeterminate =
    !isChecked && options.some(({ value }) => selectedProjects.includes(value))

  const submit = async (activeProjects: CheckboxValueType[]) => {
    setUpdating(true)
    // No back-end saving in impersonator mode
    if (roles.includes('impersonator')) {
      localStorage.setItem(
        impersonatorTokenActiveProjectsKey,
        activeProjects.join(',')
      )
    } else {
      await client.mutate({
        mutation: UPDATE_ACTIVE_PROJECTS,
        variables: { activeProjects },
      })
    }
    const { data: newProjects } = await client.query<GetActiveProjectsDetails>({
      query: GET_ACTIVE_PROJECTS_DETAILS,
      variables: { ids: activeProjects },
    })
    activeProjectsVar(
      newProjects?.allProjects?.edges.map((edge) => edge!.node!)
    )
    setUpdating(false)
  }

  const handleAllChange = () => {
    const optionsProjects = options.map(({ value }) => value)
    const activeProjects = isChecked
      ? selectedProjects.filter((id) => !optionsProjects.includes(id))
      : [...new Set([...selectedProjects, ...optionsProjects])]
    if (!activeProjects?.length) {
      // Add the first project in case of empty array
      activeProjects.push(options[0]?.value)
    }

    submit(activeProjects)
  }

  const handleChange = (checkedValue: CheckboxValueType[]) => {
    const optionsProjects = options.map(({ value }) => value)
    const activeProjects = selectedProjects.filter(
      (id) => !optionsProjects.includes(id)
    )
    submit([...activeProjects, ...checkedValue])
  }

  return (
    <>
      <Row wrap={false} align="top" style={{ margin: '12px 0 20px' }}>
        <BookOutlined style={{ fontSize: 24, marginRight: 12 }} />
        <Typography variant="subHeader-16" weight="semi-bold" ellipsis={false}>
          <T _str="Projects ({count})" count={selectedProjects.length} />
        </Typography>
      </Row>
      <Input
        value={search}
        style={{ marginBottom: 20 }}
        prefix={<SearchOutlined />}
        placeholder={t('Search')}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
      <Checkbox
        indeterminate={isIndeterminate}
        checked={isChecked}
        onChange={handleAllChange}
      >
        <T _str="Select all" />
      </Checkbox>
      <Divider style={{ margin: '10px 0' }} />
      <Spin spinning={loading || updating}>
        <StyledCheckboxGroup
          options={options}
          value={selectedProjects}
          onChange={handleChange}
        />
      </Spin>
    </>
  )
}

export default ProjectsSelect
