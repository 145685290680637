import { useRef } from 'react'

export const useOnce = (): [(fn: $TSFixMeFunction) => void, () => void] => {
  const called = useRef(false)

  return [
    (cb: $TSFixMeFunction) => {
      if (!called.current) {
        cb()
        called.current = true
      }
    },
    () => {
      called.current = false
    },
  ]
}
