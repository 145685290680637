import React from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type DriverType = `primary` | `substitute`

export const driverLabelMap: Record<DriverType, React.ReactNode> = {
  primary: <T _str="Primary" />,
  substitute: <T _str="Substitute" />,
}

export const DRIVER_TYPES_OPTIONS = createOptionsFromLabelMap(driverLabelMap)
