import { gql } from '@apollo/client'

export const FrgUserAbsence = gql`
  fragment FrgUserAbsence on UserAbsenceType {
    id
    project {
      id
      name
    }
    user {
      id
      firstName
      lastName
    }
    replacementUser {
      id
      firstName
      lastName
    }
    start
    end
    absenceType
  }
`

export const GET_ALL_USER_ABSENCE = gql`
  query GetAllUserAbsence(
    $activeProjects: String!
    $users: String
    $offset: Int
    $first: Int
    $start_Gte: Date
    $end_Lte: Date
  ) {
    allUserAbsences(
      activeProjects: $activeProjects
      users: $users
      offset: $offset
      first: $first
      start_Gte: $start_Gte
      end_Lte: $end_Lte
    ) {
      totalCount
      edges {
        node {
          id
          project {
            id
            name
          }
          user {
            id
            firstName
            lastName
          }
          replacementUser {
            id
            firstName
            lastName
          }
          start
          end
        }
      }
    }
  }
`

export const GET_USER_ABSENCE = gql`
  ${FrgUserAbsence}
  query GetUserAbsence($id: ID!) {
    userAbsence(id: $id) {
      ...FrgUserAbsence
    }
  }
`

export const CREATE_OR_UPDATE_USER_ABSENCE = gql`
  ${FrgUserAbsence}
  mutation CreateOrUpdateUserAbsence(
    $selfId: ID
    $projectId: ID!
    $userId: String!
    $replacementUserId: String!
    $start: Date!
    $end: Date!
    $absenceType: String!
  ) {
    updateOrCreateUserAbsence(
      selfId: $selfId
      projectId: $projectId
      userId: $userId
      replacementUserId: $replacementUserId
      start: $start
      end: $end
      absenceType: $absenceType
    ) {
      userAbsence {
        ...FrgUserAbsence
      }
    }
  }
`
