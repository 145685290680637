import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CustomizeComponent } from 'rc-table/lib/interface'
import { Table as AntdTable, TableProps } from 'src/antd'
import styled, { CSSProperties } from 'styled-components'

import { array } from 'src/utils/array'

// eslint-disable-next-line import/no-cycle
import { useOptContext, variantConfig } from '.'

const CustomTable: typeof Table = styled(AntdTable)`
  .ant-table-thead .ant-table-cell {
    background-color: transparent;
  }

  .ant-table-cell {
    border: none;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }

  thead > tr > th {
    color: #8c8c8c;
    font-weight: 400;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  & .selectable-search-table-item-disabled {
    opacity: 0.8;
    background-color: #f1f1f1 !important;
    & .ant-table-cell-row-hover {
      background-color: #f1f1f1 !important;
    }
    pointer-events: none;
    cursor: not-allowed;
  }
`

const Styledtr = styled.tr<{
  $selected: boolean
  $background: CSSProperties['background']
  $disabled?: boolean
  $noHover?: boolean
}>`
  cursor: pointer;

  ${({ $background }) => `
    background: ${$background};
  `}

  & .ant-table-cell-row-hover {
    background: whitesmoke !important;
  }

  ${({ $disabled, $background }) =>
    $disabled
      ? `
      & .ant-table-cell-row-hover {
        background: ${$background} !important;
      }
  `
      : ``}

  ${({ $selected, $background }) =>
    $selected
      ? `
      background: ${$background} !important;
      & .ant-table-cell-row-hover {
        background: ${$background} !important;
      }
  `
      : ``}

  &.ant-table-placeholder {
    background: transparent !important;
  }
`

const CustomRow: CustomizeComponent = ({ ...restProps }) => {
  const {
    value: currentValue,
    disabled: listDisabled,
    onChange,
    mode,
  } = useOptContext()

  const key = restProps['data-row-key']
  const selected = currentValue?.includes(key as string)

  const onRowClick = () => {
    if (mode === 'simple') {
      onChange?.([key as string])
    }
    if (mode === 'multiple') {
      if (selected) {
        onChange?.(currentValue?.filter((val) => val !== key))
      } else {
        onChange?.([...(currentValue ?? []), key])
      }
    }
  }

  return (
    <Styledtr
      $disabled={listDisabled}
      $background={
        array(
          selected && variantConfig.default.optionBackground,
          'transparent'
        )[0]
      }
      $selected={selected}
      {...restProps}
      onClick={onRowClick}
    />
  )
}

export const Table = <RecordType extends Record<string, $TSFixMe> = $TSFixMe>({
  ...tableProps
}: TableProps<RecordType>) => {
  return (
    <CustomTable
      {...tableProps}
      size="small"
      pagination={false}
      components={{
        body: {
          row: CustomRow,
        },
      }}
      onRow={(record) => {
        if (record.disabled) {
          return {
            className: 'selectable-search-table-item-disabled',
          }
        }
        return {}
      }}
    />
  )
}
