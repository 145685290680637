import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createDetailRoutesMap } from './detailed-view'

export const createContainerRoutesMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createDetailRoutesMap, {
      title: <T _str="Asset Detail" />,
      breadCrumbTitle: <T _str="Detail" />,
    }),
})
