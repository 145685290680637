import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { ExportFormats } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const exportFormatLabelMap: Record<ExportFormats, ReactNode> = {
  [ExportFormats.CSV]: <T _str="CSV" />,
  [ExportFormats.XLSX]: <T _str="XLSX" />,
}

export const exportFormatOptions =
  createOptionsFromLabelMap(exportFormatLabelMap)
