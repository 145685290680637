import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TCoreCompanySettingsCrmLoginMethodUserChoices =
  | `PRE_AUTH_SEARCH`
  | `POST_AUTH_SEARCH`

export const coreCompanySettingsCrmLoginMethodUserChoicesLabelMap: Record<
  TCoreCompanySettingsCrmLoginMethodUserChoices,
  ReactNode
> = {
  POST_AUTH_SEARCH: <T _str="After authentication" />,
  PRE_AUTH_SEARCH: <T _str="Before authentication" />,
}

export const coreCompanySettingsCrmLoginMethodUserChoicesOptions =
  createOptionsFromLabelMap(
    coreCompanySettingsCrmLoginMethodUserChoicesLabelMap
  )
