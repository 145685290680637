import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdPaperFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.77629 5.91788C7.83152 5.91788 7.8763 5.8731 7.87629 5.81786L7.8754 1.97378C7.87538 1.88349 7.76517 1.83946 7.70294 1.90488L4.04642 5.74896C3.98584 5.81264 4.03098 5.91788 4.11887 5.91788H7.77629Z" />
      <path d="M8.22376 1.50101C8.16853 1.50101 8.12376 1.54578 8.12376 1.60101V6.07827C8.12376 6.13349 8.07898 6.17827 8.02376 6.17827H3.76736C3.71213 6.17827 3.66736 6.22304 3.66736 6.27826V14.399C3.66736 14.4542 3.71213 14.499 3.76736 14.499H12.2327C12.2879 14.499 12.3327 14.4542 12.3327 14.399V1.60101C12.3327 1.54578 12.2879 1.50101 12.2327 1.50101H8.22376Z" />
    </svg>
  )
})
