import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TCoreIoUserLanguageChoicesAll = `EN` | `DA` | `EL`
// we only support 2 for now.
export type TCoreIoUserLanguageChoices = `EN` | `DA`

export const coreIoUserLanguageChoicesLabelMap: Record<
  TCoreIoUserLanguageChoices,
  ReactNode
> = {
  DA: <T _str="Danish" />,
  EN: <T _str="English" />,
}
