import React, { CSSProperties, FC, ReactNode } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Menu, Dropdown, DropdownProps } from 'src/antd'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
`

type TActionButtonActionArgsProps = {
  danger?: boolean
  disabled?: boolean
  cond?: (d?: $TSFixMe) => boolean
}

export type MenuAction = [
  label: ReactNode,
  action: (d?: $TSFixMe) => void,
  args?: TActionButtonActionArgsProps
]

export type MenuActions =
  | MenuAction
  | [
      label: ReactNode,
      childs: MenuAction[],
      args?: TActionButtonActionArgsProps
    ]

const isAction = (a: MenuActions): a is MenuAction => {
  return typeof a[1] === 'function'
}

export type TActionButtonsType = {
  actions: MenuActions[]
  data?: $TSFixMe
  dropdownOnly?: boolean
  dropdownProps?: DropdownProps
  size?: ButtonProps['size']
  style?: CSSProperties
  className?: string
}

export const ActionButtons: FC<TActionButtonsType> = ({
  actions: [firstAction, ...actionsArray],
  data,
  size = 'middle',
  dropdownOnly = false,
  dropdownProps,
  className,
  style,
}) => {
  const restArray = dropdownOnly ? [firstAction, ...actionsArray] : actionsArray

  const filteredArray = restArray.filter((a) =>
    !a[2]?.cond ? true : a[2]?.cond?.(data)
  )

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', gap: 4, ...style }}
      className={className}
    >
      {!dropdownOnly && firstAction && (
        <StyledButton
          danger={firstAction?.[2]?.danger}
          onClick={() => (firstAction as MenuAction)?.[1](data)}
          size={size}
          disabled={firstAction?.[2]?.disabled}
        >
          {firstAction?.[0]}
        </StyledButton>
      )}
      {!!filteredArray.length && (
        <Dropdown
          {...dropdownProps}
          trigger={['hover']}
          getPopupContainer={() => document.body}
          dropdownRender={() => (
            <Menu
              items={filteredArray?.map((action, index) => {
                if (isAction(action)) {
                  return {
                    key: index,
                    onClick: () => action?.[1](data),
                    danger: action?.[2]?.danger,
                    disabled: action?.[2]?.disabled,
                    label: (
                      <div
                        style={{
                          minWidth: '100px',
                          width: 'auto',
                        }}
                      >
                        {action?.[0]}
                      </div>
                    ),
                  }
                }
                return {
                  type: 'group',
                  label: action[0],
                  children: action[1].map((act, subIndex) => ({
                    key: `${index}_${subIndex}`,
                    onClick: () => act?.[1](data),
                    danger: act?.[2]?.danger,
                    disabled: act?.[2]?.disabled,
                    label: (
                      <div
                        style={{
                          minWidth: '100px',
                          width: 'auto',
                        }}
                      >
                        {act?.[0]}
                      </div>
                    ),
                  })),
                }
              })}
            />
          )}
        >
          <StyledButton size={size} type="text">
            <MoreOutlined />
          </StyledButton>
        </Dropdown>
      )}
    </div>
  )
}
