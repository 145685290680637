import React from 'react'
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon'

const svg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8456_696)">
      <rect width="12" height="12" rx="2" fill="#FF4D4F" />
      <path
        d="M2.66675 7.33332L5.37539 5.16641C5.7406 4.87424 6.25956 4.87424 6.62478 5.16641L9.33341 7.33332"
        stroke="white"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8456_696">
        <rect width="12" height="12" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const HighPriority = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={svg} {...props} />
)
