import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdShoesFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.66039 9.70888H9.00398C10.5191 9.70888 11.8001 9.61686 12.8113 9.43537C13.6182 9.29056 14.0623 9.12044 14.2687 9.02306C14.2651 9.01564 14.2629 9.01149 14.2627 9.0112C13.9379 8.4051 13.167 8.42267 11.9583 8.20634C10.7139 7.98367 10.144 7.48661 9.15532 6.93525L9.06433 6.88412C9.0637 6.88378 9.06292 6.88398 9.06253 6.88456L8.35666 7.8956L8.13964 7.7628L8.84854 6.74737C8.67085 6.63818 8.43384 6.49245 8.18629 6.34018H8.18614L7.47637 7.35683L7.25936 7.22398L7.96947 6.20679C7.42275 5.8704 6.89315 5.54434 6.89315 5.54434C6.89315 5.54434 6.48015 6.19982 6.18484 6.49128C5.67349 6.99604 4.90229 7.31741 4.03946 7.31741C3.25236 7.31741 2.54156 7.04991 2.0346 6.61979C2.03167 6.62237 2.02894 6.62496 2.02631 6.62754C1.59209 7.06269 1.64995 8.28894 1.66039 8.46189V9.70888Z" />
      <path d="M12.8553 9.68038C11.8296 9.86451 10.5338 9.95784 9.00398 9.95784H1.66039V9.99848C1.66039 10.251 1.86462 10.4557 2.11661 10.4557H9.10244C10.9349 10.4557 12.4135 10.3225 13.4971 10.0601C13.8566 9.97297 14.115 9.88442 14.2949 9.81006C14.3559 9.58442 14.3554 9.40039 14.3366 9.26597C14.0734 9.38536 13.6152 9.54397 12.8553 9.68038Z" />
    </svg>
  )
})
