import { gql } from '@apollo/client'

export const GET_ALL_PROJECTS = gql`
  query SidebarProjectSelectGet($search: String) {
    allProjects(search: $search) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_ACTIVE_PROJECTS = gql`
  mutation updateActiveProjects($activeProjects: [ID]!) {
    updateActiveProjects(activeProjects: $activeProjects) {
      status
    }
  }
`
