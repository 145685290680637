import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

export type TFleetManagementVehicleAbsenceAbsenceTypeChoices =
  | `OTHER`
  | `BROKEN`

export const fleetManagementVehicleAbsenceAbsenceTypeChoicesLabelMap: Record<
  TFleetManagementVehicleAbsenceAbsenceTypeChoices,
  ReactNode
> = {
  BROKEN: <T _str="Broken" />,
  OTHER: <T _str="Other" />,
}

export const fleetManagementVehicleAbsenceAbsenceTypeChoicesColorMap: Record<
  TFleetManagementVehicleAbsenceAbsenceTypeChoices,
  TagProps['color']
> = {
  BROKEN: 'red',
  OTHER: 'blue',
}
