import React from 'react'
import { T } from '@transifex/react'

import { createApprovalCollectionRouteMap } from './approval-collection'
import { createNotificationRouteMap } from './notifications'
import { createPickupHistoryRouteMap } from './pickup-history'
import { createRouteProblemErrorsRoutesMap } from './route-problem-errors'
import { createRouteSchemeRouteMap } from './route-schemes'
import { createRoutesRouteMap } from './routes'
import { createWeightControlRouteMap } from './weight-control'
import { pathJoin } from '../../../utils'

export const createOperationManagementRouteMap = (prefix: string) => ({
  routes: pathJoin(prefix)(`routes`).chainMapAuto(createRoutesRouteMap, {
    title: <T _str="All Routes" />,
    breadCrumbTitle: <T _str="All Routes" />,
  }),
  'routes/create': pathJoin(prefix)('routes')('create').chain,
  'route-schemes': pathJoin(prefix)('route-schemes').chainMapAuto(
    createRouteSchemeRouteMap,
    {
      title: <T _str="All Route Schemes" />,
      breadCrumbTitle: <T _str="All Route Schemes" />,
    }
  ),
  'pickup-history': pathJoin(prefix)('pickup-history').chainMapAuto(
    createPickupHistoryRouteMap,
    {
      title: <T _str="Pickup History" />,
      breadCrumbTitle: <T _str="Pickup History" />,
    }
  ),
  'route-problem-errors': pathJoin(prefix)(`route-problem-errors`).chainMapAuto(
    createRouteProblemErrorsRoutesMap,
    {
      title: <T _str="Route Problem Errors" />,
      breadCrumbTitle: <T _str="Route Problem Errors" />,
    }
  ),
  'weight-control': pathJoin(prefix)(`weight-control`).chainMapAuto(
    createWeightControlRouteMap,
    {
      title: <T _str="Weight Control" />,
      breadCrumbTitle: <T _str="Weight Control" />,
    }
  ),
  'approval-collection': pathJoin(prefix)(`approval-collection`).chainMapAuto(
    createApprovalCollectionRouteMap,
    {
      title: <T _str="Approval collection" />,
      breadCrumbTitle: <T _str="Approval collection" />,
    }
  ),
  notification: pathJoin(prefix)('notification').chainMapAuto(
    createNotificationRouteMap,
    {
      title: <T _str="Notification" />,
      breadCrumbTitle: <T _str="Notification" />,
    }
  ),
  'live-monitoring': pathJoin(prefix)('live-monitoring').chainWithTitle({
    title: <T _str="Live monitoring" />,
    breadCrumbTitle: <T _str="Live monitoring" />,
  }),
})
