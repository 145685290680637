import { gql } from '@apollo/client'

export const FrgDepot = gql`
  fragment FrgDepot on DepotType {
    id
    company {
      id
      name
    }
    location {
      id
      latitude
      longitude
      placeId
      name
    }
    logo
    name
    openingTime
    closingTime
    contactPerson {
      id
      email
      name
      phone
    }
    depotType
    wasteFractions {
      edges {
        node {
          id
          name
          wasteCategory
          wasteSubstance
          weightFactor
        }
      }
    }
  }
`
