import React, { CSSProperties, ReactNode } from 'react'
import { LinkProps } from 'react-router-dom'
import { Button } from 'src/antd'

import { IconExternalLink } from 'src/icons'
import { routingMap } from 'src/reducers/routing/routing-map'
import { TTypographyProps, Typography } from 'src/storybook/components'

export const LinkedLabel = ({
  label,
  prefix,
  url,
  typographyProps,
  style,
}: {
  label: LinkProps['children']
  prefix?: ReactNode
  url: string | ((r: typeof routingMap) => string)
  typographyProps?: TTypographyProps
  style?: CSSProperties
}) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        columnGap: 2,
        ...style,
      }}
    >
      <Typography variant="body-12" {...typographyProps}>
        {label}
      </Typography>
      <Button
        style={{
          height: 'auto',
          width: 15,
          border: 'none',
          marginTop: '-1px',
        }}
        size="small"
        type="text"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          window.open(
            typeof url === 'function' ? url(routingMap) : url,
            '_blank',
            'noreferrer'
          )
        }}
        icon={<IconExternalLink />}
      />
      {prefix && <Typography {...typographyProps}>{prefix}</Typography>}
    </div>
  )
}
