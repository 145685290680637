import { gql, useQuery } from '@apollo/client'
import { omit } from 'lodash'
import { create } from 'zustand'

type TDefaultConfigStore = {
  defaultLocation: {
    latitude: number
    longitude: number
    name: string
    placeId: string
  }
  loading?: boolean
}

const GET = gql`
  query GetProjectConfigStore {
    me {
      company {
        id
        dashboardLocation {
          id
          latitude
          longitude
          name
          placeId
        }
      }
    }
  }
`

export const useDefaultConfigStore = create<TDefaultConfigStore>(() => ({
  defaultLocation: { latitude: 0, longitude: 0, name: '', placeId: '' },
}))

export const DefaultConfigInit = ({ children }: { children: JSX.Element }) => {
  const { loading, called } = useQuery(GET, {
    onCompleted: (data: $TSFixMe) => {
      if (data?.me)
        useDefaultConfigStore.setState({
          defaultLocation: omit(data.me.company.dashboardLocation, [
            'id',
            '__typename',
          ]) as TDefaultConfigStore['defaultLocation'],
        })
    },
  })

  if (loading || !called) return null

  return children || null
}
