import React, { FC } from 'react'
import { Popover, PopoverProps } from 'src/antd'

import DepotPopup from './depot'
import DriverPopup from './driver'
import TicketPopup from './ticket'
import UserPopup from './user'
import VehiclePopup from './vehicle'

type TInfoPopoverType = 'driver' | 'vehicle' | 'user' | 'depot' | 'ticket'

export type PopupContentProps = {
  id: string
}

export type TInfoPopoverProps = {
  type: TInfoPopoverType
  id: string
} & PopoverProps

const popupContentMap: Record<TInfoPopoverType, React.FC<PopupContentProps>> = {
  driver: DriverPopup,
  vehicle: VehiclePopup,
  user: UserPopup,
  depot: DepotPopup,
  ticket: TicketPopup,
}

const InfoPopover: FC<TInfoPopoverProps> = ({ type, id, ...rest }) => {
  const PopupContent = popupContentMap[type]

  return (
    <Popover
      mouseEnterDelay={0.5}
      overlayClassName="whf-ant-popover no-content-padding no-arrow"
      showArrow={false}
      content={<PopupContent id={id} />}
      {...rest}
    />
  )
}

export default InfoPopover
