import React from 'react'
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon'

const svg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8456_2512)">
      <rect width="12" height="12" rx="2" fill="#40A9FF" />
      <path
        d="M9.33325 5.14287L6.62461 7.30978C6.25939 7.60196 5.74044 7.60196 5.37522 7.30978L2.66658 5.14287"
        stroke="white"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8456_2512">
        <rect width="12" height="12" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const LowPriority = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={svg} {...props} />
)
