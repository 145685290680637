import React from 'react'
import { T } from '@transifex/react'

import { createRoutesMapContainerGroup } from './container-group'
import { createRoutesMapContainers } from './containers'
import { pathJoin } from '../../../utils'

export const createRoutesMapAssetManagement = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chainMapAuto(
    createRoutesMapContainers,
    {
      title: <T _str="All Containers" />,
      breadCrumbTitle: <T _str="All Containers" />,
    }
  ),
  'container-group': pathJoin(prefix)(`container-group`).chainMapAuto(
    createRoutesMapContainerGroup,
    {
      title: <T _str="All Container Groups" />,
      breadCrumbTitle: <T _str="All Container Groups" />,
    }
  ),
})
