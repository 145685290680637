import { ReactNode } from 'react'

import { Opt } from '../new-components'

export const createOptionsFromLabelMap = <T extends string>(
  map: Record<T, ReactNode>,
  setOptionDisabled?: (key: T) => boolean
): Opt<T>[] =>
  (Object.keys(map) as T[]).map((k) => ({
    label: map[k],
    value: k,
    disabled: setOptionDisabled ? setOptionDisabled(k) : false,
  }))
