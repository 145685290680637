import React, { ReactElement } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { getTagsColumn } from 'src/storybook/components/table-kit'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TVehicleStatus = `working` | `off_work` | `unavailable`

export const vehicleStatusLabelMap: Record<TVehicleStatus, React.ReactNode> = {
  working: <T _str="Working" />,
  off_work: <T _str="Off work" />,
  unavailable: <T _str="Unavailable" />,
}

export const vehicleStatusColorMap: Record<TVehicleStatus, TagProps['color']> =
  {
    working: 'blue',
    off_work: 'yellow',
    unavailable: 'red',
  }

export const vehicleStatusOptions = createOptionsFromLabelMap(
  vehicleStatusLabelMap
)

type TTagsVehicleStatus = {
  ids: string[]
}

export const TagsVehicleStatus = ({ ids }: TTagsVehicleStatus) => {
  return getTagsColumn(
    vehicleStatusColorMap,
    (id) => vehicleStatusLabelMap[id as TVehicleStatus]
  )?.(ids, {}, 0) as ReactElement
}
