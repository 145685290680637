import { IntegrationsRfidVehicleConnectionProtocolChoices } from 'src/gen-types'

export const IntegrationsRfidVehicleConnectionProtocolChoicesLabelMap: Record<
  Exclude<
    IntegrationsRfidVehicleConnectionProtocolChoices,
    IntegrationsRfidVehicleConnectionProtocolChoices.FTP
  >,
  string
> = {
  SSH: 'SSH',
  MODBUS: 'MODBUS',
}
