import { pathJoin } from 'src/reducers/routing/utils'

export const createRouteMapContainerGroupDetail = (prefix: string) => ({
  property: pathJoin(prefix)('property').chain,
  'collection-log': pathJoin(prefix)('collection-log').chain,
  history: pathJoin(prefix)('history').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  overview: pathJoin(prefix)('overview').chain,
})
