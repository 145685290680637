import { createRouteMapMain } from './app'
import { createPrivacyPolicyRoutesMap } from './privacy-policy'
import { createPageRegisterRoutesMap } from './register'
import { pathJoin } from '../utils'

export const createWasteheroPlatformRouteMap = (prefix: string) => ({
  login: pathJoin(prefix)(`login`).chain,
  register: pathJoin(prefix)(`register`).chainMapAuto(
    createPageRegisterRoutesMap
  ),
  company: {
    create: pathJoin(prefix)(`company`)(`create`).chain,
  },
  forgetPassword: pathJoin(prefix)(`forgot-password`).chain,
  app: pathJoin(prefix)(`app`).chainMapAuto(createRouteMapMain),
  'inquiries-upload': {
    ':driverId/:inquiryId': (driverId: string, inquiryId: string) =>
      pathJoin(prefix)(`inquiries-upload`)(driverId)(inquiryId).chain,
  },
  'privacy-policy': pathJoin(prefix)(`privacy-policy`).chainMap(
    createPrivacyPolicyRoutesMap(
      pathJoin(prefix)(`privacy-policy`).value.slice(1)
    )
  ),
  reset: pathJoin(prefix)(`reset`).chain,
  'user-register/:email/:id': (email: string, id: string) =>
    pathJoin(prefix)(`user-register`)(email)(id).chain,
  'user-invite/:email/:id': (email: string, id: string) =>
    pathJoin(prefix)('user-invite')(email)(id).chain,
})
