import React, { useEffect } from 'react'
import message, { MessageInstance } from 'antd/es/message'
import { create } from 'zustand'

type TStore = {
  getMessage: () => MessageInstance
}

export const useAntdMessageWithContext = create<TStore>(() => ({
  getMessage: () => {
    throw new Error(`
      useAntdMessageWithContext is used without initializing: AntdMessageWithContextInit
    `)
  },
}))

export const AntdMessageWithContextInit = () => {
  const [messageAPI, messageJSX] = message.useMessage()

  useEffect(() => {
    useAntdMessageWithContext.setState({ getMessage: () => messageAPI })
  }, [messageAPI])

  return <>{messageJSX}</>
}
