import React from 'react'
import Icon from '@ant-design/icons'
import BraftEditor from 'braft-editor'
import { Dropdown } from 'src/antd'

import ControlBarButton from './ControlBarButton'
import { TX_BACKGROUND_COLOR } from '../../../transifex'
import { TwitterColorPicker } from '../../ColorPicker'

type BackgroundColorProps = {
  editorState: BraftEditor
  onColorChange: (color: string) => void
}

const DEFAULT_COLOR = '#000000'

const BackgroundColor = ({
  editorState,
  onColorChange,
}: BackgroundColorProps) => {
  let currentColor: string | null = null

  const selectionStyles = (editorState as $TSFixMe)
    .getCurrentInlineStyle()
    .toJS()

  selectionStyles.forEach((style: $TSFixMe) => {
    if (style.indexOf('BGCOLOR-') === 0) {
      currentColor = `#${style.split('-')[1]}`
    }
  })

  if (!currentColor) {
    currentColor = DEFAULT_COLOR
  }

  return (
    <Dropdown
      placement="top"
      dropdownRender={() => (
        <TwitterColorPicker
          value={currentColor || ''}
          onChange={onColorChange}
        />
      )}
      trigger={['click']}
    >
      <ControlBarButton
        color={currentColor}
        tooltip={TX_BACKGROUND_COLOR}
        icon={
          <Icon
            component={() => (
              <svg
                style={{ height: 15, width: 14 }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.5 13"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M10.22,10.38a1.26,1.26,0,0,0,1.25-1.26c0-.84-1.25-2.2-1.25-2.2S9,8.28,9,9.12A1.26,1.26,0,0,0,10.22,10.38Z" />
                    <path d="M4.33,9.69a.28.28,0,0,0,.4,0l4-4a.27.27,0,0,0,0-.4l-4-4,0,0L3.48,0a.14.14,0,0,0-.2,0L2.53.79a.13.13,0,0,0,0,.1.13.13,0,0,0,0,.1L3.58,2,.33,5.29a.28.28,0,0,0,0,.4Zm.2-7,2.8,2.8H1.74Z" />
                    <rect
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      style={{ fill: currentColor! }}
                      y="11.5"
                      width="12.5"
                      height="1.5"
                      rx="0.13"
                    />
                  </g>
                </g>
              </svg>
            )}
          />
        }
      />
    </Dropdown>
  )
}

export default BackgroundColor
