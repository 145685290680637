import { gql } from '@apollo/client'

export const GET = gql`
  query GetDriverInfoPopover($id: ID!) {
    driver(id: $id) {
      id
      driverType
      user {
        id
        logo
        firstName
        lastName
        phoneNumber
        email
        username
      }
    }
  }
`
