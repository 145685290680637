import { createGlobalStyle } from 'styled-components'

import { DISABLED_ROW_WITH_OPACITY_CLASSNAME } from 'src/consts/table'
import { gray200, navy900 } from 'src/styles/color-theme'

import { GetCalendarPropsGlobalStyles } from './components/CalendarBase'
import { GetDatePickerBasePropsGlobalStyles } from './components/DatePickerBase'

export const GlobalStyles = createGlobalStyle`
  ${GetCalendarPropsGlobalStyles}
  ${GetDatePickerBasePropsGlobalStyles}
  .ant-table {
    .ant-table-row.${DISABLED_ROW_WITH_OPACITY_CLASSNAME} {
      opacity: 0.5;
    }
  }
  .mapboxgl-ctrl-top-left {
    max-width: 35%;
    .mapboxgl-ctrl-geocoder {
      width: calc(100% - 10px);
      min-width: 1px;
      margin-right: 0;
    }
  }
  .mapboxgl-ctrl-top-right {
    max-width: calc(65% - 9px);
    margin-left: 10px;
  }
  .wh-vertical-scroller-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &> .wh-vertical-scroller-container-scroll {
      flex: 1;
      min-height: 0;
    }
  }

  .wh-spin-100 {
    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }

  .wh-select-hide-selected-option {
    &&& {
      .ant-select-item-option-selected {
        display: none;
      }
    }
  }


  .wh-antd-form {
    &.disabled {
       .ant-picker-input > input[disabled] {
          color: ${navy900};
        }
        
        .ant-checkbox-disabled + span {
          color: black;
        }

        .projectIdCol {
          display: none;
        }
        
        .selectAllDaysCheckboxCol {
          display: none;
        }
        
        .ant-checkbox-group {
          margin-left: 0 !important;
          cursor: default !important;
        }
        
        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-checkbox-disabled {
          cursor: default !important;
        } 
        
        .ant-checkbox-disabled + span {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
          cursor: default !important;
        }
        
        .ant-picker-range {
          border-color: ${gray200} !important;
          background: ${gray200} !important;
          color: ${navy900};
          cursor: default !important;
        }
        
        .ant-picker-input > input[disabled] {
          cursor: default !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: inherit;
        }
        
        .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled, .ant-radio-button-wrapper-disabled::before {
          display: none;
        }
        
        .button-col-24 {
          display: none;
        }

        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
          border-color: #1890ff !important;
        }

        .ant-checkbox-disabled .ant-checkbox-inner {
          border-color: #fff !important;
          background: #fff;
        }

        .ant-picker-disabled {
          border-color: ${gray200} !important;
          background: ${gray200} !important;
          color: ${navy900};
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          background: ${gray200};
          border-color: ${gray200};
          color: ${navy900};
        }

        .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
          background: ${gray200};
          border: 1px solid #d9d9d9;
          color: ${navy900};
          border-radius: 2px;
        }

        .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
          background: ${gray200};
          /* Daybreak Blue / blue-6 */

          border: 1px solid ${gray200};
          box-sizing: border-box;
          border-radius: 2px;
          display: inherit;

          color: ${navy900};
        }
        
        .ant-input-group-addon {
          border: 0px;
          background: #f0f0f0;
        }
        
        .ant-select-selection-placeholder {
          font-size: 0
        }
        
        .ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
          display: none
        }
        
        .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
          width: 100% !important;
        }
        .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder.mapboxgl-ctrl input {
          display: none;
        }
        
        .mapboxgl-ctrl-geocoder--icon-search {
          display: none
        }
        
        .mapboxgl-marker {
          pointer-events: none
        }
        
        .ant-select-single .ant-select-selector .ant-select-selection-item {
          user-select: text;
        }
        
        .ant-picker ant-picker-range ant-picker-disabled {
        
        }
        
        .ant-picker-input ant-picker-input-active {
          cursor: default !important;
        }
        
        .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
          cursor: default !important;
        }
        
        .ant-radio-button-wrapper-disabled {
          cursor: default !important;
        }
        
        .ant-checkbox-disabled {
          cursor: default !important;
        }
        
        .ant-switch-loading, .ant-switch-disabled {
          cursor: default !important;
        }
        
        .ant-switch-loading *, .ant-switch-disabled * {
          cursor: default !important;  
        }
        
        .ant-picker-input > input[disabled] {
          cursor: default !important;    
        }
        
        
        /* SELECTS */ 
        .ant-select {
          border-color: ${gray200} !important;
          background: ${gray200} !important;
          color: ${navy900};
          cursor: default !important;
        }
        
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          cursor: default;
        }
        
        .ant-input {
          cursor: default !important;
        }
        
        .ant-select .ant-select-arrow {
          color: transparent;
        }
        
        .ant-select-selector {
          border-color: ${gray200} !important;
          background: ${gray200} !important;
          color: ${navy900};
        }
        
        /* INPUTS */
        .ant-input, .ant-input-affix-wrapper  {
          cursor: default !important;
          border-color: ${gray200} !important;
          background: ${gray200} !important;
          color: ${navy900};
        }
      
      .ant-input-number-input {
        cursor: default !important;
        border-color: ${gray200} !important;
        background: ${gray200} !important;
        color: ${navy900};
      }
      .ant-input-number-group {
        cursor: default !important;
        border-color: ${gray200} !important;
        background: ${gray200} !important;
        color: ${navy900};
      }
      .ant-input-number {
        cursor: default !important;
        border-color: ${gray200} !important;
        background: ${gray200} !important;
        color: ${navy900};
      }
      .ant-input-number-group-addon{
        cursor: default !important;
        border-color: ${gray200} !important;
        background: ${gray200} !important;
        color: ${navy900};
      }
      
        /* PHONE INPUT */
        .react-tel-input {
          input {
            cursor: default !important;
            border-color: ${gray200} !important;
            background: ${gray200} !important;
            color: ${navy900};
          }
          .flag-dropdown {
            cursor: default !important;
            border-color: ${gray200} !important;
            background: ${gray200} !important;
            color: ${navy900};
          }
        }
    }
    
    .wh-styled-simple-dropdown {
      .ant-dropdown-trigger > .anticon.anticon-down,
      .ant-dropdown-link > .anticon.anticon-down,
      .ant-dropdown-button > .anticon.anticon-down {
        font-size: 14px;
        line-height: 0;
        margin-left: 4px;
      }
      .ant-btn-link {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

`
