import { useTxStrings } from '../../transifex'

const UNTRANSLATED = {
  base: {
    remove: 'Remove',
    cancel: 'Cancel',
    confirm: 'Confirm',
    inert: 'Insert',
    width: 'Width',
    height: 'Height',
  },
  controls: {
    clear: 'Clear',
    undo: 'Undo',
    redo: 'Redo',
    fontSize: 'Font Size',
    color: 'Color',
    textColor: 'Text',
    tempColors: 'Temp Colors',
    backgroundColor: 'Background',
    bold: 'Bold',
    lineHeight: 'Line Height',
    letterSpacing: 'Letter Spacing',
    textIndent: 'Text Indent',
    increaseIndent: 'Increase Indent',
    decreaseIndent: 'Decrease Indent',
    italic: 'Italic',
    underline: 'Underline',
    strikeThrough: 'Strike Through',
    fontFamily: 'Font Family',
    textAlign: 'Text Alignment',
    alignLeft: 'Left Alignment',
    alignCenter: 'Center Alignment',
    alignRight: 'Right Alignment',
    alignJustify: 'Justify Alignment',
    floatLeft: 'Left Float',
    floatRight: 'Right Float',
    superScript: 'Super Script',
    subScript: 'Sub Script',
    removeStyles: 'Remove Styles',
    headings: 'Headings',
    header: 'Header',
    normal: 'Normal',
    orderedList: 'Ordered List',
    unorderedList: 'Unordered List',
    blockQuote: 'Quote',
    code: 'Code',
    link: 'Link',
    unlink: 'Unlink',
    hr: 'Horizontal Line',
    media: 'Media',
    mediaLibirary: 'Media Library',
    emoji: 'Emoji',
    fullscreen: 'Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
  },
  linkEditor: {
    textInputPlaceHolder: 'Input link text',
    linkInputPlaceHolder: 'Input link URL',
    inputWithEnterPlaceHolder: 'Input link URL and press Enter',
    openInNewWindow: 'Open in new window',
    removeLink: 'Remove Link',
  },
  audioPlayer: {
    title: 'Play Audio',
  },
  videoPlayer: {
    title: 'Play Video',
    embedTitle: 'Embed Media',
  },
  media: {
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    embed: 'Embed',
  },
}

type LanguageValues = typeof UNTRANSLATED

export default function useLanguageParser() {
  const {
    txRemove,
    txConfirm,
    txCancel,
    txInert,
    txWidth,
    txHeight,
    txClear,
    txUndo,
    txRedo,
    txFontSize,
    txColor,
    txTextColor,
    txTempColors,
    txBackgroundColor,
    txBold,
    txLineHeight,
    txLetterSpacing,
    txTextIndent,
    txIncreaseIndent,
    txDecreaseIndent,
    txItalic,
    txUnderline,
    txStrikeThrough,
    txFontFamily,
    txTextAlign,
    txAlignCenter,
    txAlignJustifytxAlignJustify,
    txAlignLeft,
    txAlignRight,
    txFloatLeft,
    txFloatRight,
    txSuperScript,
    txSubScript,
    txRemoveStyles,
    txHeadings,
    txHeader,
    txNormal,
    txOrderedList,
    txUnorderedList,
    txBlockQuote,
    txCode,
    txLink,
    txUnlink,
    txHr,
    txMedia,
    txMediaLibirary,
    txEmoji,
    txExitFullscreen,
    txFullscreen,
    txTextInputPlaceHolder,
    txLinkInputPlaceHolder,
    txInputWithEnterPlaceHolder,
    txOpenInNewWindow,
    txRemoveLink,
    txAudioTitle,
    txEmbedTitle,
    txVideoTitle,
    txImage,
    txVideo,
    txAudio,
    txEmbed,
  } = useTxStrings()

  const base: LanguageValues['base'] = {
    remove: txRemove(),
    cancel: txCancel(),
    confirm: txConfirm(),
    inert: txInert(),
    width: txWidth(),
    height: txHeight(),
  }

  const controls: LanguageValues['controls'] = {
    clear: txClear(),
    undo: txUndo(),
    redo: txRedo(),
    fontSize: txFontSize(),
    color: txColor(),
    textColor: txTextColor(),
    tempColors: txTempColors(),
    backgroundColor: txBackgroundColor(),
    bold: txBold(),
    lineHeight: txLineHeight(),
    letterSpacing: txLetterSpacing(),
    textIndent: txTextIndent(),
    increaseIndent: txIncreaseIndent(),
    decreaseIndent: txDecreaseIndent(),
    italic: txItalic(),
    underline: txUnderline(),
    strikeThrough: txStrikeThrough(),
    fontFamily: txFontFamily(),
    textAlign: txTextAlign(),
    alignLeft: txAlignLeft(),
    alignCenter: txAlignCenter(),
    alignRight: txAlignRight(),
    alignJustify: txAlignJustifytxAlignJustify(),
    floatLeft: txFloatLeft(),
    floatRight: txFloatRight(),
    superScript: txSuperScript(),
    subScript: txSubScript(),
    removeStyles: txRemoveStyles(),
    headings: txHeadings(),
    header: txHeader(),
    normal: txNormal(),
    orderedList: txOrderedList(),
    unorderedList: txUnorderedList(),
    blockQuote: txBlockQuote(),
    code: txCode(),
    link: txLink(),
    unlink: txUnlink(),
    hr: txHr(),
    media: txMedia(),
    mediaLibirary: txMediaLibirary(),
    emoji: txEmoji(),
    fullscreen: txFullscreen(),
    exitFullscreen: txExitFullscreen(),
  }

  const linkEditor: LanguageValues['linkEditor'] = {
    textInputPlaceHolder: txTextInputPlaceHolder(),
    linkInputPlaceHolder: txLinkInputPlaceHolder(),
    inputWithEnterPlaceHolder: txInputWithEnterPlaceHolder(),
    openInNewWindow: txOpenInNewWindow(),
    removeLink: txRemoveLink(),
  }

  /** Audio Player */
  const audioPlayer: LanguageValues['audioPlayer'] = {
    title: txAudioTitle(),
  }

  /** Video Player */
  const videoPlayer: LanguageValues['videoPlayer'] = {
    embedTitle: txEmbedTitle(),
    title: txVideoTitle(),
  }

  /** Media Player */
  const mediaPlayer: LanguageValues['media'] = {
    image: txImage(),
    video: txVideo(),
    audio: txAudio(),
    embed: txEmbed(),
  }

  const translated: LanguageValues = {
    base,
    controls,
    linkEditor,
    audioPlayer,
    videoPlayer,
    media: mediaPlayer,
  }

  return translated
}
