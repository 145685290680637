import { useState } from 'react'

export const useLoadingStateAction = () => {
  const [loading, setLoading] = useState(false)

  return {
    loading,
    call: async (val: Promise<$TSFixMe>) => {
      setLoading(true)
      return val.finally(() => {
        setLoading(false)
      })
    },
  }
}

export const useLoadingStateAction2 = () => {
  const [loading, setLoading] = useState(false)

  return {
    loading,
    call: async (val: () => $TSFixMe) => {
      setLoading(true)
      await val()
      setLoading(false)
    },
  }
}
