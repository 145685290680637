import { gql } from '@apollo/client'

export const GET_ALL_USERS = gql`
  query GetAllUsers(
    $offset: Int
    $first: Int
    $activeProjects: String
    $isDriver: Boolean
    $q: String
    $orderBy: String
    $order: String
    $isActive: Boolean
    $company: ID
  ) {
    allUsers(
      activeProjects: $activeProjects
      isDriver: $isDriver
      q: $q
      order: $order
      orderBy: $orderBy
      offset: $offset
      first: $first
      isActive: $isActive
      company: $company
    ) {
      totalCount
      edges {
        node {
          id
          logo
          username
          firstName
          lastName
          email
          phoneNumber
          isMaster
          isAdmin
          isSuperuser
          isActive
          companyRole {
            id
            name
          }
          teams {
            edges {
              node {
                id
                name
              }
            }
          }
          userprojectSet {
            edges {
              node {
                id
                isSuper
                project {
                  id
                  name
                }
              }
            }
          }
          company {
            id
            name
          }
          jobtitle {
            id
            title
          }
        }
      }
    }
  }
`

const FrgUser = gql`
  fragment FrgUser on UserType {
    id
    firstName
    lastName
    username
    logo
    email
    phoneNumber
    isActive
    language
    datetimeFormatLocale
    company {
      id
    }
    isAdmin
    isMaster
    isDemo
    isReseller
    jobtitle {
      id
      title
      pendingTitle
    }
    userprojectSet {
      edges {
        node {
          project {
            name
          }
          apiKey
        }
      }
    }
    companyRole {
      id
      name
    }
  }
`

export const GET_USER = gql`
  ${FrgUser}
  query GetUser($id: ID!) {
    user(id: $id) {
      ...FrgUser
    }
  }
`

export const UPDATE_USER = gql`
  ${FrgUser}
  mutation UpdateUser(
    $userId: ID!
    $phoneNumber: String
    $firstName: String!
    $lastName: String!
    $logo: String
    $language: String!
    $isMaster: Boolean
    $datetimeFormatLocale: String!
  ) {
    updateUser(
      selfId: $userId
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      language: $language
      logo: $logo
      isMaster: $isMaster
      datetimeFormatLocale: $datetimeFormatLocale
    ) {
      user {
        ...FrgUser
      }
    }
  }
`

export const ASSIGN_USER_ROLE = gql`
  ${FrgUser}
  mutation AssignUserRole($userId: ID!, $role: ID!) {
    assignUserCompanyRole(userId: $userId, roleId: $role) {
      user {
        ...FrgUser
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
    }
  }
`

export const MUTATION_RESEND_INVITATION = gql`
  mutation MutationResendInvitation($userProjectId: ID!) {
    resendInvitation(userId: $userProjectId) {
      status
    }
  }
`

export const MUTATION_INVITE_USER_EXTENDED = gql`
  mutation MutationInviteUserExtended(
    $emails: [String!]!
    $companyId: ID!
    $projectIds: [ID!]!
    $isSuper: Boolean!
    $isMaster: Boolean!
    $roleId: ID
    $teamId: ID
  ) {
    inviteUsers(
      emails: $emails
      projectIds: $projectIds
      isMaster: $isMaster
      isSuper: $isSuper
      companyId: $companyId
      roleId: $roleId
      teamId: $teamId
    ) {
      users {
        id
      }
    }
  }
`

export const FrgUserForLink = gql`
  fragment FrgUserForLink on UserType {
    id
    firstName
    lastName
    username
    logo
  }
`

export const GET_ALL_USERS_BY_IDS = gql`
  ${FrgUserForLink}
  query GetAllUsersByIds($ids: [ID], $skip: Boolean = false) {
    allUsers(ids: $ids) @skip(if: $skip) {
      edges {
        node {
          ...FrgUserForLink
        }
      }
    }
  }
`
