import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdReuseFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.0228 3.73897C6.62582 3.39553 7.31053 3.21277 8.02325 3.21277C9.10561 3.21277 10.1232 3.63425 10.8885 4.39959C11.1337 4.64492 11.3437 4.9162 11.5158 5.20718L10.1715 6.0055L13.6973 7.27438L14.1444 3.64616L12.9091 4.37984C12.6674 3.97086 12.3749 3.5938 12.0345 3.25348C11.5138 2.73279 10.9072 2.32386 10.2316 2.03808C9.53175 1.74207 8.78869 1.59198 8.02325 1.59198C7.2578 1.59198 6.5148 1.74207 5.8149 2.03808C5.78657 2.05004 5.75841 2.06232 5.73035 2.07471L6.0228 3.73897Z" />
      <path d="M6.00547 10.7804C5.69941 10.6042 5.41463 10.3864 5.15812 10.13C4.39284 9.36461 3.97136 8.3471 3.97136 7.26474C3.97136 6.54398 4.1583 5.85196 4.50921 5.24407L5.87423 6.01455L5.22626 2.3274L1.85571 3.74627L3.09817 4.44755C2.98599 4.64349 2.8854 4.84663 2.79667 5.05639C2.50066 5.75629 2.35057 6.49929 2.35057 7.26474C2.35057 8.03024 2.50066 8.77324 2.79667 9.47314C3.08245 10.1487 3.49133 10.7553 4.01207 11.2761C4.22638 11.4903 4.45531 11.6855 4.69752 11.8611L6.00547 10.7804Z" />
      <path d="M12.0752 7.26533C12.0751 8.34743 11.6536 9.36478 10.8884 10.13C10.1324 10.8859 9.13036 11.3064 8.06282 11.3165L8.06303 9.78467L5.17177 12.17L8.06224 14.408L8.0625 12.9374C8.81413 12.9323 9.54368 12.7824 10.2316 12.4915C10.9072 12.2057 11.5138 11.7968 12.0345 11.2761C12.5552 10.7553 12.9642 10.1488 13.2499 9.47315C13.4715 8.94928 13.6112 8.40127 13.6675 7.8378L12.0752 7.26533Z" />
    </svg>
  )
})
