import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdFlatGlassFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.75192 10.8465H7.47508L10.254 6.87739H10.5308L7.75192 10.8465ZM5.47544 11.4175L10.2392 4.61323H10.5161L5.75228 11.4175H5.47544ZM8.24918 5.18359H8.52597L5.74712 9.15275H5.47024L8.24918 5.18359ZM4.03076 13.6702H11.9691V2.32977H4.03076V13.6702Z" />
    </svg>
  )
})
