import { useMemo } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useT } from '@transifex/react'
import { message } from 'antd'

import {
  ContainerByPropertyGet,
  ContainerByPropertyGetVariables,
  LabelTicketsGet,
  LabelTicketsGetVariables,
  PrintContainerLabel,
  PrintContainerLabelVariables,
} from './__generated__/types'

const PRINT_CONTAINER_LABEL = gql`
  mutation PrintContainerLabel(
    $barcodeGenerationInputList: [BarcodeGenerationInput!]!
  ) {
    generateBarcodesForContainers(
      barcodeGenerationInputList: $barcodeGenerationInputList
    ) {
      linkToPdfFile
    }
  }
`

const GET_ALL_TICKETS_FOR_LABELS = gql`
  query LabelTicketsGet($ids: [ID]) {
    allTickets(ids: $ids) {
      edges {
        node {
          id
          container {
            id
          }
          possibleContainerId
        }
      }
    }
  }
`

const GET_ALL_CONTAINERS_BY_PROPERTY = gql`
  query ContainerByPropertyGet($propertyIds: [ID]) {
    allContainers(propertyIds: $propertyIds) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export type UsePrintContainerLabelArgs = {
  ticketIds?: string[]
  propertyIds?: string[]
}

export default function usePrintContainerLabel({
  ticketIds,
  propertyIds,
}: UsePrintContainerLabelArgs = {}) {
  const t = useT()

  const [printRequest, { loading }] = useMutation<
    PrintContainerLabel,
    PrintContainerLabelVariables
  >(PRINT_CONTAINER_LABEL)

  const { data: ticketData, loading: ticketLoading } = useQuery<
    LabelTicketsGet,
    LabelTicketsGetVariables
  >(GET_ALL_TICKETS_FOR_LABELS, {
    variables: {
      ids: ticketIds,
    },
    skip: !ticketIds?.length,
  })

  const { data: containerByPropertyData, loading: containerByPropertyLoading } =
    useQuery<ContainerByPropertyGet, ContainerByPropertyGetVariables>(
      GET_ALL_CONTAINERS_BY_PROPERTY,
      {
        variables: {
          propertyIds,
        },
        skip: !propertyIds?.length,
      }
    )

  const isAvailable = useMemo(() => {
    // more different ids can be added in future
    if (ticketIds) {
      return !ticketLoading && ticketData
        ? ticketData?.allTickets?.edges?.every((ticket) =>
            Boolean(
              ticket?.node?.container?.id || ticket?.node?.possibleContainerId
            )
          )
        : false
    }

    if (propertyIds) {
      return (
        !containerByPropertyLoading &&
        !!containerByPropertyData?.allContainers?.edges?.length
      )
    }

    return true
  }, [
    propertyIds,
    ticketIds,
    containerByPropertyData,
    ticketData,
    containerByPropertyLoading,
    ticketLoading,
  ])

  const printLabel = (
    ids?: PrintContainerLabelVariables['barcodeGenerationInputList']
  ) => {
    let queryIds: PrintContainerLabelVariables['barcodeGenerationInputList'] =
      []

    if (ticketIds) {
      queryIds =
        ticketData?.allTickets?.edges?.map((ticket) => ({
          containerId: ticket?.node?.container?.id || '',
          ticket: ticket?.node?.id || '',
        })) || []
    }

    if (propertyIds) {
      queryIds =
        containerByPropertyData?.allContainers?.edges?.map((container) => ({
          containerId: container?.node?.id || '',
        })) || []
    }

    const barcodeGenerationInputList = ids || queryIds
    return printRequest({
      variables: { barcodeGenerationInputList },
    })
      .then((resp) => {
        message.success(t('Print label request sent successfully'))
        const fileLink =
          resp?.data?.generateBarcodesForContainers?.linkToPdfFile
        if (fileLink) {
          window.open(fileLink, '_blank')
        }
        return false
      })
      .catch(() => {
        message.error(t('Something went wrong'))
      })
  }

  return {
    loading,
    disabled: !isAvailable,
    printLabel,
  }
}
