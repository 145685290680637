type TProps = {
  firstName?: string
  lastName?: string
  username?: string
  [key: string]: $TSFixMe
}

export default function getFullName(user?: TProps) {
  const { firstName, lastName, username } = user || {}
  return [firstName, lastName].filter(Boolean).join(' ') || username || ''
}
