import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createDashboardsDetailViewRoutesMap } from './id'

export const createDashboardsRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createDashboardsDetailViewRoutesMap, {
      title: <T _str="Dashboard Detail View" />,
      breadCrumbTitle: <T _str="Dashboard Detail View" />,
    }),
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="Create Dashboard" />,
    breadCrumbTitle: <T _str="Create" />,
  }),
})
