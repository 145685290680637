import React from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { RoutesPickUpOrderAutoCompletedByChoices } from 'src/gen-types'

export const autocompleteColorMap: Record<
  RoutesPickUpOrderAutoCompletedByChoices,
  TagProps['color']
> = {
  RFID: 'orange',
  RADIUS: 'blue',
  MANUAL: 'gray',
}

export const autocompleteLabelMap: Record<
  RoutesPickUpOrderAutoCompletedByChoices,
  React.ReactNode
> = {
  RFID: <T _str="RFID" />,
  RADIUS: <T _str="Radius" />,
  MANUAL: <T _str="Manual" />,
}
