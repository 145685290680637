import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type AutomationActionTypes =
  | `send_message_to_related_customer`
  | 'schedule_route'
  | 'assign_to_user'
  | 'assign_to_team'
  | 'create_ticket'
  | 'change_status'
  | 'change_priority'
  | 'send_confirmation_message_to_property_user'
  | 'sendEmailAction'
  | 'containerAction'

export const automationActionTypesLabelMap: Record<
  AutomationActionTypes,
  ReactNode
> = {
  send_message_to_related_customer: (
    <T _str="Send message to related customers" />
  ),
  schedule_route: <T _str="Schedule in route" />,
  assign_to_user: <T _str="Assign to user" />,
  assign_to_team: <T _str="Assign to team" />,
  create_ticket: <T _str="Create a ticket" />,
  change_status: <T _str="Change status" />,
  change_priority: <T _str="Change priority" />,
  send_confirmation_message_to_property_user: (
    <T _str="Send confirmation message to property user" />
  ),
  containerAction: (
    <T _str="Prompt container-related requirements for status transit" />
  ),
  sendEmailAction: <T _str="Send email" />,
}

export const automationActionTypesSelectOptions = createOptionsFromLabelMap(
  automationActionTypesLabelMap
)

export const automationActionTypesAsArray = Object.keys(
  automationActionTypesLabelMap
) as AutomationActionTypes[]

export const automationActionTypeDefMap: Record<string, AutomationActionTypes> =
  {
    SendMessageToPropertyUserActionDef: 'send_message_to_related_customer',
    ScheduleOnRouteActionDef: 'schedule_route',
    AssignToUserActionDef: 'assign_to_user',
    AssignToTeamActionDef: 'assign_to_team',
    CreateTicketActionDef: 'create_ticket',
    ChangeStatusActionDef: 'change_status',
    ChangePriorityActionDef: 'change_priority',
    SendConfirmationMessageToPropertyUserActionDef:
      'send_confirmation_message_to_property_user',
    SendEmailActionDef: 'sendEmailAction',
    ContainerActionDef: 'containerAction',
  }

export const automationActionTypeMutationKeys: Record<
  AutomationActionTypes,
  string
> = {
  send_message_to_related_customer: 'sendMessageToPropertyUserAction',
  schedule_route: 'scheduleOnRouteAction',
  assign_to_user: 'assignToUserAction',
  assign_to_team: 'assignToTeamAction',
  create_ticket: 'createTicketAction',
  change_status: 'changeStatusAction',
  change_priority: 'changePriorityAction',
  send_confirmation_message_to_property_user:
    'sendConfirmationMessageToPropertyUserAction',
  sendEmailAction: 'sendEmailAction',
  containerAction: 'containerAction',
}
