import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TPublicUpdateUnconfirmedUserSegment =
  | 'LOCAL_AUTHORITY'
  | 'WASTE_COLLECTOR'
  | 'ORGANIZATION'
  | 'PARTNER'
  | 'OTHER'

export const publicUpdateUnconfirmedUserSegmentLabelMap: Record<
  TPublicUpdateUnconfirmedUserSegment,
  ReactNode
> = {
  LOCAL_AUTHORITY: <T _str="Local Authority" />,
  ORGANIZATION: <T _str="Organization" />,
  OTHER: <T _str="Other" />,
  PARTNER: <T _str="Partner" />,
  WASTE_COLLECTOR: <T _str="Waste Collector" />,
}

export const publicUpdateUnconfirmedUserSegmentOptions =
  createOptionsFromLabelMap<TPublicUpdateUnconfirmedUserSegment>(
    publicUpdateUnconfirmedUserSegmentLabelMap
  )
