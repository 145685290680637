import React, { FC } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { T, useTX } from '@transifex/react'
import { Button, Result } from 'src/antd'

import { useMe } from './hooks'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const getFallback: (locale: string, me: boolean) => Sentry.FallbackRender =
  (locale) =>
  ({ resetError, eventId }) => {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="warning"
          title={<T _str="Oops! Something went wrong" />}
          extra={
            <>
              <Button onClick={resetError} type="primary" key="try">
                <T _str="Try again" />
              </Button>
              <Button
                onClick={() => {
                  Sentry.showReportDialog({
                    eventId: eventId || undefined,
                    dsn: process.env.REACT_APP_SENTRY_DSN,
                    lang: locale,
                  })
                }}
                type="primary"
                key="console"
              >
                <T _str="Report" />
              </Button>
            </>
          }
        />
      </div>
    )
  }

export const ErrorBoundary: FC = ({ children }) => {
  const tx = useTX()
  const currentLocale = tx.getCurrentLocale() || 'en-IN'
  const me = useMe()
  const fallback = getFallback(currentLocale, !!me)
  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  )
}
