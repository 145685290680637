import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TAllowedArcValue =
  | `urn:grn:authn:dk:mitid:substantial`
  | `urn:grn:authn:dk:nemid:poces`
  | 'urn:grn:authn:dk:nemid:moces'
  | 'urn:grn:authn:dk:nemid:moces:codefile'

export const allowedArcValueLabelMap: Record<TAllowedArcValue, ReactNode> = {
  'urn:grn:authn:dk:mitid:substantial': <T _str="MitID Substantial" />,
  'urn:grn:authn:dk:nemid:poces': <T _str="NemID Poces" />,
  'urn:grn:authn:dk:nemid:moces': <T _str="NemID Moces" />,
  'urn:grn:authn:dk:nemid:moces:codefile': <T _str="NemID Moces Code file" />,
}

export const allowedArcValueOptions = createOptionsFromLabelMap(
  allowedArcValueLabelMap
)
