import { pathJoin } from 'src/reducers/routing/utils'

import { createAssetManagementRouteMap } from './asset-management'

export const createProjectRouteMap = (prefix: string) => ({
  'general-settings': pathJoin(prefix)('general-settings').chain,
  'asset-management': pathJoin(prefix)('asset-management').chainMap(
    createAssetManagementRouteMap(
      pathJoin(prefix)('asset-management').value.slice(1)
    )
  ),
})
