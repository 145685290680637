import React from 'react'
import { Popover, PopoverProps, Radio } from 'antd'
import styled from 'styled-components'

import { blue100, textPrimary } from 'src/styles/color-theme'

const PopoverWithOverlayClassName = ({
  className,
  ...props
}: PopoverProps & { className?: string }) => (
  <Popover {...props} overlayClassName={className} />
)

export const StyledDatePickerWrapper = styled.div`
  position: relative;
  .ant-picker-range {
    opacity: 0;
    height: 0;
    overflow: hidden;
    & + div {
      position: static !important;
      margin-top: -27px;
      .ant-picker-dropdown {
        position: static;
      }
      .ant-picker-panel-container {
        box-shadow: none;
        width: 100%;
      }
      .ant-picker-range-arrow {
        display: none;
      }
      .ant-picker-panel {
        border-bottom: none;
        width: 50%;
      }
      .ant-picker-dropdown {
        top: 0 !important;
      }
      .ant-picker-panel-container .ant-picker-panels {
        display: flex;
      }
      .ant-picker-date-panel {
        width: 100% !important;
        .ant-picker-content {
          width: 100%;
        }
      }
      .ant-picker-header {
        button {
          color: ${textPrimary};
        }
      }
    }
  }
`

export const StyledPopover = styled(PopoverWithOverlayClassName)`
  padding-top: 0px;
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
`

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    border: none;
    padding: 8px;
    margin: 0 2px;
    height: auto;
    line-height: 20px;
    border-radius: 4px;
    &::before {
      display: none;
    }
    &.ant-radio-button-wrapper-checked {
      &,
      &:hover {
        background-color: ${blue100};
        color: ${textPrimary};
      }
    }
  }
`
