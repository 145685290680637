import React from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TAlertEventStatus = `NEW` | `OPEN` | `CLOSED`

export const alertEventStatusLabelMap: Record<
  TAlertEventStatus,
  React.ReactNode
> = {
  NEW: <T _str="New" />,
  OPEN: <T _str="Open" />,
  CLOSED: <T _str="Closed" />,
}

export const alertEventStatusColorMap: Record<
  TAlertEventStatus,
  TagProps['color']
> = {
  NEW: 'orange',
  OPEN: 'red',
  CLOSED: 'green',
}

export const ALERT_EVENT_STATUS_OPTIONS = createOptionsFromLabelMap(
  alertEventStatusLabelMap
)
