import { matchPath } from 'react-router-dom'

import {
  TSidebarLinkItem,
  TSidebarLinkChildrenItem,
} from 'src/components/layout/types'

// Calculate whether the item children is active or not excluding some links
export function isChildrenSelected(
  pathname: string,
  item: TSidebarLinkChildrenItem
) {
  return !!(
    matchPath(pathname, { path: item.link }) &&
    !item.skipLinks?.some((path) => matchPath(pathname, { path }))
  )
}

// Calculate whether the item itself or one of his children is active or not
export function isItemSelected(pathname: string, item: TSidebarLinkItem) {
  return (
    !!matchPath(pathname, { path: item.link }) ||
    !!item.children?.some(({ children }) =>
      children.some((child) => isChildrenSelected(pathname, child))
    )
  )
}
