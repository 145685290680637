import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdNaturalStoneFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.9294 11.2538C7.77887 11.0088 7.60053 10.8605 7.42807 10.717C7.26311 10.5798 7.10725 10.4501 7.00876 10.2585C6.89819 10.0434 6.8825 9.79336 6.95937 9.47174C7.02996 9.17649 7.04126 8.98114 7.05032 8.82416C7.06001 8.65605 7.06837 8.51087 7.14682 8.35161C7.22225 8.19844 7.3512 8.05177 7.59197 7.84536C8.01749 7.48086 8.40273 7.22217 8.76976 7.05443C9.1016 6.90275 9.41138 6.82906 9.71689 6.82906C10.1956 6.82906 10.571 7.01328 10.9021 7.17585C11.0784 7.26241 11.2449 7.34417 11.4108 7.39082C11.7642 7.49018 12.0741 7.55215 12.3546 7.59864C12.3429 7.40564 12.2595 7.18218 12.0421 6.80724C10.2267 3.67922 8.8208 4.72492 7.56551 4.64635C5.85551 4.53938 5.02322 4.11439 3.79902 4.16912C2.20849 4.2402 2.13116 5.12689 1.82888 6.10842C1.34801 7.66992 1.89094 8.09251 1.87268 9.19579C1.86142 9.87495 2.09395 10.1577 2.44525 10.353C2.51508 10.335 2.58848 10.3214 2.66639 10.3121C2.77307 10.2995 2.88952 10.2767 3.0128 10.2526C3.22409 10.2112 3.44255 10.1684 3.65338 10.1684C3.85909 10.1684 4.02724 10.2097 4.16742 10.2946C4.31897 10.3865 4.46775 10.4118 4.61161 10.4364C4.75228 10.4604 4.89775 10.4852 5.02633 10.5717C5.17275 10.6701 5.26684 10.829 5.314 11.0575C5.34211 11.1938 5.38946 11.2972 5.4396 11.4067C5.48691 11.5101 5.53721 11.6201 5.57285 11.7592C6.14517 11.8053 6.75487 11.7866 7.27838 11.8401C7.65766 11.8789 7.98098 11.8593 8.27221 11.7931C8.16474 11.6369 8.06104 11.4682 7.95009 11.2875L7.9294 11.2538Z" />
      <path d="M4.05798 10.4753C3.70155 10.2593 3.13631 10.4691 2.69125 10.5219C2.16041 10.5849 1.95032 10.8608 1.64328 11.2504C1.3362 11.64 0.946421 11.5647 1.00931 12.095C1.07226 12.6259 0.889255 12.6143 1.22559 12.9786C2.2387 14.0752 2.71087 13.5272 3.22362 13.4458C3.92216 13.3349 4.29573 13.4311 4.78397 13.2988C5.4183 13.1268 5.36957 12.7627 5.40294 12.34C5.45597 11.6677 5.19918 11.5463 5.10716 11.1002C4.98189 10.493 4.55107 10.774 4.05798 10.4753Z" />
      <path d="M14.0779 8.1765C13.2433 7.84189 12.5639 7.9345 11.3536 7.59417C10.4652 7.34429 9.74488 6.27931 7.72945 8.00578C7.0603 8.57942 7.38581 8.59669 7.16487 9.52088C6.9441 10.4443 7.64785 10.392 8.10939 11.1432C8.57097 11.8946 8.88476 12.4252 9.80896 12.6461C10.5838 12.8313 11.5383 13.3187 12.2122 13.0112C13.1446 12.5857 13.8471 13.171 14.1938 12.1248C14.4486 11.3563 14.927 11.1952 14.9724 9.99716C15.001 9.24406 15.1623 8.61122 14.0779 8.1765Z" />
    </svg>
  )
})
