import { gql } from '@apollo/client'

export const FrgTicketCustomField = gql`
  fragment FrgTicketCustomField on TicketCustomFieldDef {
    label
    id
    name
    inputType
    options {
      id
      metaValue
      preSelected
      order
    }
    company {
      id
    }
    required
    helpText
    icon
    interval
    helpText
    displayHelpText
    prefilledValue
    displayPrefilledValue
    unit
    displayUnit
    placeholder
  }
`

export const FrgTicketCustomFieldConnection = gql`
  ${FrgTicketCustomField}
  fragment FrgTicketCustomFieldConnection on TicketTypeCustomFieldsConnection {
    id
    order
    ticketCustomField {
      ...FrgTicketCustomField
    }
  }
`

export const FrgTicketCustomValue = gql`
  ${FrgTicketCustomFieldConnection}
  fragment FrgTicketCustomValue on CustomTicketValuesDef {
    textValue
    numberValue
    selectValue {
      id
      metaValue
    }
    multiSelectValue {
      id
      metaValue
    }
    customFieldConnection {
      ...FrgTicketCustomFieldConnection
    }
  }
`
