import React, { ReactNode } from 'react'
import { Button, ButtonProps, Tooltip } from 'antd'
import styled from 'styled-components'

import { Typography } from '../../Typography'

const StyledButton = styled(Button)<{
  $active?: boolean
  $color?: string
}>`
  border: none;
  background: transparent;
  box-shadow: none;
  color: ${({ $active, $color }) => $color || ($active ? '#1890FF' : 'black')};

  &:hover {
    color: ${({ $active, $color }) =>
      $color || ($active ? '#1890FF' : '#8C8C8C')};
  }

  &[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

type ControlBarButtonProps = {
  color?: string
  active?: boolean
  tooltip?: ReactNode
} & ButtonProps

const ControlBarButton = ({
  tooltip,
  active,
  color,
  ...rest
}: ControlBarButtonProps) => {
  return (
    <>
      {tooltip ? (
        <Tooltip
          mouseEnterDelay={0.5}
          title={<Typography>{tooltip}</Typography>}
          color="white"
        >
          <StyledButton
            size="small"
            $color={color}
            $active={active}
            {...rest}
          />
        </Tooltip>
      ) : (
        <StyledButton size="small" $color={color} $active={active} {...rest} />
      )}
    </>
  )
}

export default ControlBarButton
