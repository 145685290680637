import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from '../../../utils'

export const createNotificationsRoutesMap = (prefix: string) => ({
  alerts: pathJoin(prefix)('alerts').chainMapAuto(
    (prefix2) => ({
      ':id': (id = ':id') =>
        pathJoin(prefix2)(id).chainWithTitle({
          title: <T _str="Alert Events" />,
          breadCrumbTitle: <T _str="Alert Events" />,
        }),
    }),
    {
      title: <T _str="All Alerts" />,
      breadCrumbTitle: <T _str="All Alerts" />,
    }
  ),
})
