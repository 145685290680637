import { gql } from '@apollo/client'

export const FrgWasteFraction = gql`
  fragment FrgWasteFraction on WasteFractionType {
    id
    name
    color
    icon
    iconPreset
    style
  }
`
