import React, { ReactNode } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Space, Tag } from 'src/antd'

import { TX_FROM, TX_TO } from '../../transifex'

export type TFromToProps = {
  from: ReactNode
  to: ReactNode
  icon?: ReactNode
  short?: boolean
  /** default to true
   * Will break to new line.
   */
  wrap?: boolean
  wrapChildInTag?: boolean
}

export const FromTo = ({
  from,
  to,
  icon = <ArrowRightOutlined style={{ color: 'gray' }} />,
  short,
  wrap = true,
  wrapChildInTag,
}: TFromToProps) => (
  <Space size={6} wrap={wrap}>
    {!short && from && TX_FROM}
    {wrapChildInTag ? <Tag>{from}</Tag> : from}
    {from && to && icon} {to && !short && TX_TO}
    {wrapChildInTag ? <Tag>{to}</Tag> : to}
  </Space>
)
