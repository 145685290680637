import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { ExportHistoryStatuses } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const exportStatusLabelMap: Record<ExportHistoryStatuses, ReactNode> = {
  [ExportHistoryStatuses.NEW]: <T _str="Draft" />,
  [ExportHistoryStatuses.IN_PROGRESS]: <T _str="In progress" />,
  [ExportHistoryStatuses.FAILED]: <T _str="Failed" />,
  [ExportHistoryStatuses.COMPLETED]: <T _str="Completed" />,
}

export const exportStatusColorMap: Record<
  ExportHistoryStatuses,
  TagProps['color']
> = {
  [ExportHistoryStatuses.NEW]: 'gray',
  [ExportHistoryStatuses.IN_PROGRESS]: 'blue',
  [ExportHistoryStatuses.FAILED]: 'red',
  [ExportHistoryStatuses.COMPLETED]: 'green',
}

export const exportStatusOptions =
  createOptionsFromLabelMap(exportStatusLabelMap)
