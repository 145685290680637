import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createCreateRouteMap } from './general'

export const createProjectsDetailView = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(createCreateRouteMap, {
    title: <T _str="New Project" />,
    breadCrumbTitle: <T _str="New" />,
  }),
})
