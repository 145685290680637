import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'

const DividerDiv = styled.div`
  margin-left: 3px;
  margin-right: 3px;
  height: 20px;
  width: 1px;
  background-color: #bfbfbf;
`

const Divider = () => (
  <Col>
    <DividerDiv />
  </Col>
)

export default Divider
