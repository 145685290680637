import React, { ReactElement, ReactNode } from 'react'
import { T } from '@transifex/react'

import { RoutesPickUpOrderStatusChoices } from 'src/gen-types'
import { getTagsColumn } from 'src/storybook/components/table-kit'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const routesPickUpOrderStatusChoicesLabelMap: Record<
  RoutesPickUpOrderStatusChoices,
  ReactNode
> = {
  COMPLETED: <T _str="Completed" />,
  CONNECTED_TO_ROUTE: <T _str="Connected to route" />,
  SKIPPED: <T _str="Skipped" />,
}

export const routesPickUpOrderStatusOptions = createOptionsFromLabelMap(
  routesPickUpOrderStatusChoicesLabelMap
)

type TTagsPickUpOrderStatus = {
  ids: string[]
}

export const TagsPickUpOrderStatus = ({ ids }: TTagsPickUpOrderStatus) => {
  return getTagsColumn(
    undefined,
    (id) =>
      routesPickUpOrderStatusChoicesLabelMap[
        id as RoutesPickUpOrderStatusChoices
      ]
  )?.(ids, {}, 0) as ReactElement
}
