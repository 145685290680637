import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdBicycleFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.46315 9.0849C6.41595 8.42481 6.13606 7.82914 5.70501 7.37945L4.15214 9.07917L6.46315 9.0849Z" />
      <path d="M5.40778 7.11122C4.94135 6.74792 4.35485 6.53143 3.71779 6.53143C2.19766 6.53143 0.965332 7.76378 0.965332 9.28389C0.965332 10.804 2.19766 12.0364 3.71779 12.0364C5.17023 12.0364 6.35989 10.9114 6.46299 9.48524L3.24613 9.47735L5.40778 7.11122Z" />
      <path d="M12.9261 6.53143C12.6094 6.53143 12.3052 6.58499 12.022 6.68342L13.2225 9.48881H12.787L11.6544 6.8422C10.7744 7.30146 10.1736 8.22253 10.1736 9.28389C10.1736 10.804 11.4059 12.0364 12.9261 12.0364C14.4462 12.0364 15.6786 10.804 15.6786 9.28389C15.6786 7.76378 14.4462 6.53143 12.9261 6.53143Z" />
      <path d="M7.89354 8.91644L6.79953 6.33252L11.0497 5.42903L11.0573 5.4468L7.89354 8.91644ZM10.8454 3.93391L12.6153 3.92908L12.6142 3.52875L10.2393 3.53518L10.8892 5.05385L6.64052 5.95702L5.95532 4.33867C6.44989 4.17468 7.44366 3.845 7.50003 3.82574C7.56732 3.80271 7.60553 3.74971 7.60553 3.68234C7.60553 3.60007 7.53931 3.53334 7.45727 3.53232L4.62619 3.53224C4.62619 4.02971 5.0295 4.43303 5.52697 4.43303C5.53812 4.43303 5.54918 4.43275 5.5602 4.43236L6.28699 6.14887L5.40778 7.11122C5.51328 7.19341 5.61262 7.28306 5.70501 7.37945L6.45872 6.55446L7.53118 9.08749L6.46315 9.0849C6.46786 9.15062 6.47029 9.21696 6.47029 9.28389C6.47029 9.35165 6.46778 9.41878 6.46299 9.48524L7.91343 9.48881L11.2304 5.85121L11.6544 6.8422C11.7722 6.78076 11.895 6.72756 12.022 6.68342L10.8454 3.93391Z" />
    </svg>
  )
})
