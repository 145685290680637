import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from '../../utils'

export const createMarketPlaceRouteMap = (prefix: string) => ({
  'checkout/:stripeQuoteId': (stripeQuoteId: string) =>
    pathJoin(prefix)('checkout')(stripeQuoteId).chainWithTitle({
      title: <T _str="Marketplace Checkout" />,
      breadCrumbTitle: <T _str="Checkout" />,
    }),
  'shop/:stripeQuoteId': (stripeQuoteId: string) =>
    pathJoin(prefix)('shop')(stripeQuoteId).chainWithTitle({
      title: <T _str="Shop Marketplace" />,
      breadCrumbTitle: <T _str="Shop" />,
    }),
})
