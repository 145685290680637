import React from 'react'
import { useT } from '@transifex/react'
import ar from 'antd/lib/locale/ar_EG'
import da from 'antd/lib/locale/da_DK'
import enGB from 'antd/lib/locale/en_GB'
import enUS from 'antd/lib/locale/en_US'
import nbNo from 'antd/lib/locale/nb_NO'
import svSE from 'antd/lib/locale/sv_SE'
import { Locale } from 'antd/lib/locale-provider'
import { lowerCase } from 'lodash'
import { Empty, ConfigProvider } from 'src/antd'

import { CoreIoUserDatetimeFormatLocaleChoices } from 'src/gen-types'

import { TCoreIoUserLanguageChoices } from '../api/consts'
import { useMe } from '../hooks'
import { array } from '../utils/array'

const renderEmpty = () => <Empty />

const AntdI18n: React.FC = ({ children }) => {
  const me = useMe()
  const t = useT()
  const optionalString = `(${lowerCase(t('optional'))})`

  const meLanguage: TCoreIoUserLanguageChoices = me?.language
  const meDateTimeFormat: CoreIoUserDatetimeFormatLocaleChoices =
    me?.datetimeFormatLocale

  let locale: Locale = array<Locale>(
    meLanguage === 'DA' && {
      ...da,
      Pagination: {
        // Options.jsx
        items_per_page: t('/ page'),
        jump_to: t('Jump to'),
        jump_to_confirm: t('confirm'),
        page: t('Page'),

        // Pagination.jsx
        prev_page: t('Previous page'),
        next_page: t('Next page'),
        prev_5: t('Previous {count} pages', { count: 5 }),
        next_5: t('Next {count} pages', { count: 5 }),
        prev_3: t('Previous {count} pages', { count: 3 }),
        next_3: t('Next {count} pages', { count: 3 }),
      },
      Form: {
        ...da.Form,
        defaultValidateMessages: {
          ...da.Form?.defaultValidateMessages,
        },
        optional: optionalString,
      },
    },
    meLanguage === 'EN' && meDateTimeFormat === 'EN' && enUS,
    meLanguage === 'EN' && meDateTimeFormat === 'EN_GB' && enGB
  )?.[0]

  const momentLocale: Locale = array<Locale>(
    meDateTimeFormat === 'DA' && da,
    meDateTimeFormat === 'EN' && enUS,
    meDateTimeFormat === 'EN_GB' && enGB,
    meDateTimeFormat === 'SV' && svSE,
    meDateTimeFormat === 'NB' && nbNo,
    meDateTimeFormat === 'AR' && ar,
    enGB
  )?.[0]

  if (!locale) {
    locale = enUS
  }

  return (
    <ConfigProvider
      form={{ requiredMark: 'optional' }}
      locale={{
        ...locale,
        DatePicker: momentLocale.DatePicker,
        Calendar: momentLocale.Calendar,
        TimePicker: momentLocale.TimePicker,
      }}
      renderEmpty={renderEmpty}
    >
      {children}
    </ConfigProvider>
  )
}

export default AntdI18n
