import { createGlobalStyle, css } from 'styled-components'

import { SubMenuStyle } from 'src/components/layout/sidebar/components/NavigationMenu'
import { MapboxGeocoderStyle } from 'src/mapbox/components/GeocoderControl'
import { CssScroller } from 'src/storybook/components'
import {
  blue500,
  blue600,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
} from 'src/styles/color-theme'

const legacySass = css`
  body {
    height: 100%;
    margin: 0;
    #main {
      height: 100%;
      & .mainHolder {
        height: 100%;

        @media print {
          display: none;
        }
      }
    }
  }
`

export const GlobalStyles = createGlobalStyle`
  ${MapboxGeocoderStyle}
  ${legacySass}
  ${SubMenuStyle}
  .ant-modal {
    &.variant-transparent {
      & .ant-modal-content {
        background-color: transparent !important;
        box-shadow: unset !important;
      }
    }
  }

  .ant-tag {
    &.variant-round {
      border: none;
      background: ${gray300};
      border-radius: 16px;
    }
  }

  .wh-child-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wh-fill-parent {
    height: 100%;
    width: 100%;
     
  }
 
  .wh-antd-collapse {
    &.header-full {
      .ant-collapse-header-text {
          width: 100%;
        }
    }

    &&&.expand-icon-top-12px {
      .ant-collapse-expand-icon span {
        top: 12px;
      }
    }

    &&&.no-padding-content-box {
       .ant-collapse-content-box {
        padding: 0px;
      }
    }

    &&&.no-horizontal-padding {
      .ant-collapse-header {
        padding-left: 0;
        padding-right: 0;
      }

      .ant-collapse-expand-icon span {
        right: 0;
      }

      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &&&.no-vertical-padding {
      .ant-collapse-header {
        padding-top: 0;
        padding-bottom: 0;
      }

  
      .ant-collapse-content-box {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .whf-ant-card {
    &.variant-secondary {
      &.ant-card {
       border: none;
       .ant-card-body {
        border-radius: 4px;
        background: ${gray300};
       } 
     }
      &.variant-secondary-clickable {
        &.ant-card {
          .ant-card-body {
             &:hover {
              background: ${gray400};
              cursor: pointer;
            }
          }
        } 
      }
    }
  }

  .whf-ant-timeline {
    &.no-last-item-padding {
      .ant-timeline-item-last {
        padding-bottom: 0px; 
      }
    }
    &.overwrite-min-height {
       .ant-timeline-item-content {
        min-height: 0px;
       }
    }
  }

  .mapboxgl-popup-content {
        padding: 0px;
  }

  .mapboxgl-popup {
    .mapboxgl-popup-content {
      box-shadow: 0px 3px 6px -4px #0000001f, 0px 6px 16px #00000014, 0px 9px 28px 8px #0000000d;
    }
  }
  
  // styling for MapV2 - doesn't interfere with MapV3
  .wh-mapbox-container .mapboxgl-popup .mapboxgl-popup-content {
    padding: 10px;
    width: fit-content;
  }
  
  #birdeatsbug-sdk {
    & :not(button, svg, path, input, textarea) {
      cursor: move;
      user-select: none;
    }

    &.draggable > div {
      cursor: grab;
    }
    
    & > div {
      background-color: #001529;
    }
    & button.btn-primary {
      background-color: ${blue600};
    }
  }

  .wh-antd-tabs-fill-height {
    .ant-tabs {
      height: 100%;
      .ant-tabs-content-holder {
        height: calc(100% - 46px);
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }

  .whf-antd-form {
    .hide-optional-mark {
      .ant-form-item-optional {
        display: none;
      }
    }

    &&& {
      .ant-select-disabled.wh-select-as-input-suffix {
        .ant-select-selector {
          border-top-color: ${gray500} !important;
          border-bottom-color: ${gray500} !important;
        }
      }
    }
  }

  .whf-table-expandRow {
    &.bg-white {
      .ant-table-expanded-row-fixed {
        background-color: white;
      }
    }
    &.no-padding {
      .ant-table-expanded-row-fixed {
       padding: 0px;
      }
    }

    &.nochild-table-margin {
      .ant-table {
        margin: 0px !important;
      }
    }
  }

  .quick-select {
    & .ant-select-selector {
      border-color: transparent !important;
      padding-left: 4px !important;

      &:hover {
        background-color: #f8f8f8 !important;
      }
    }

    & .ant-select-selection-search {
      left: 4px !important
    }

    &.qs-8 {
      & .ant-select-selector {
        padding-left: 8px !important;
      }

      & .ant-select-selection-search {
        left: 8px !important
      }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${blue500} !important;
      background-color: ${gray200};
    }

    &.ant-select-single.ant-select-open .ant-select-selection-item {
      color: black !important;
    }
  
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent;
      color: black;
    }

    &.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent;
    }
  }
  
  .wh-tag-light {
    background: ${gray300};
    border-radius: 4px;
    &.size-small {
      padding: 4px 10px;
    }
  }
  
  &.ant-btn-text.variant-text--underlined {
    &:disabled {
      > span {
        color: ${gray600};
      }
    }

    &:not(.ant-btn-text[disabled]) {
      &:hover {
        background: none;
         > span {
           text-decoration: underline;
         }
      }
      
      &:focus {
        background: none;
      }
    }
  }
  
  .whf-center-child { 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .whf-ant-popover {
    &&& {
      &.no-arrow {
        padding: 0 !important;
      }
      &.no-content-padding {
        .ant-popover-inner-content {
          padding: 0px
        }
      }
    }
    .ant-popover-inner {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .wh-ant-input {
    &.variant--soft {
      /* for input without any wrappers */
      &.ant-input, &.ant-input-affix-wrapper {
          border:none;
          background: ${gray300};
          box-shadow: none;
      } 

      /* When input has suffix/affix */
      .ant-input-group-addon {
        border: none;
        background: ${gray300};
      }
      /* When input has suffix/affix */
      .ant-input-affix-wrapper, .ant-input {
        border:none;
        background: ${gray300};
        box-shadow: none;
        padding-left: 0;
      } 
    }
  }

  .fill-view {
    width: 100vw;
    height: 100vh;
  }

  .fill-parent {
    width: 100%;
    height: 100%;
  }
  
  .fill-parent-height {
    height: 100%;
  }
  
  .fill-parent-width {
    width: 100%;
  }

  .center-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-child-horizontal {
    display: flex;
    justify-content: center;
  }
  
  .center-child-vertical {
    display: flex;
    align-items: center;
  }

  .flex {
    display: flex;
    ${[
      ['xs', `0.25rem`],
      ['sm', '0.5rem'],
      ['md', '0.75rem'],
      ['lg', '1rem'],
    ].map(
      ([sizeName, sizeValue]) => `
       &.flex-rg-${sizeName} {
         row-gap: ${sizeValue};
       }
        
       &.flex-cg-${sizeName} {
         column-gap: ${sizeValue};
       }
      `
    )}

    &.flex-column {
      flex-direction: column;
      & > .flex-column--full-child {
        flex: 1;
        min-height: 0;
      }
    }
  }

  ${[
    ['xs', `0.25rem`],
    ['sm', '0.5rem'],
    ['md', '0.75rem'],
    ['lg', '1rem'],
  ].map(
    ([sizeName, sizeValue]) => `
      .pd-${sizeName} {
        padding: ${sizeValue};
      }
    `
  )}

  .wh-antd-form-item {
    &.no-label {
      .ant-form-item-label {
        display: none;
      }
    }
  }
  
  .table-row-dragging {
    display: flex;
    justify-content: space-between;
    background-color: ${gray200};
    td {
      padding: 8px 16px;
      flex: 1;
      background-color: ${gray200};
    }
  }

  .ant-dropdown-menu,
  .ant-popover-inner,
  .ant-modal-content,
  .ant-select-dropdown {
    border-radius: 4px;
  }

  .ant-modal-header {
    border-radius: 4px 4px 0 0;
  }
`

export const GlobalStylesAntFillViewList = createGlobalStyle`
  .wh-ant-fill-view-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-spin-nested-loading {
      flex: 1;
      min-height: 0;
      ${CssScroller}
    }
  }
`
