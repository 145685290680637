import React, { ReactElement, ReactNode, useMemo, useRef } from 'react'
import { T } from '@transifex/react'
import { invert } from 'lodash'
import { Select, SelectProps, Tag, TagProps } from 'src/antd'

import useTicketTypeStatusTransitionMap from 'src/hooks/useTicketTypeStatusTransitionMap'
import {
  SelectFilledStatus,
  TSelectFilledStatusProps,
} from 'src/storybook/components'
import {
  TStatusType,
  statusTypeData,
} from 'src/storybook/components/SelectFilledStatus/data'
import { getTagsColumn } from 'src/storybook/components/table-kit'

export type TicketTicketNotCreated = 'A_null'

export type TicketTicketStatusChoices =
  | `A_1`
  | `A_2`
  | 'A_3'
  | 'A_4'
  | `A_5`
  | `A_6`
  | `A_7`

export type TicketTicketStatusChoicesWithNull =
  | TicketTicketStatusChoices
  | TicketTicketNotCreated

export type TicketTicketStatusChoicesTuple = [
  TicketTicketStatusChoices | TicketTicketNotCreated,
  TicketTicketStatusChoices
]

export type StatusTransitionMap = Record<
  TicketTicketStatusChoicesWithNull,
  TicketTicketStatusChoices[]
>

export const ticketTicketStatusChoicesLabelMap: Record<
  TicketTicketStatusChoices,
  ReactNode
> = {
  A_1: <T _str="Created" />,
  A_2: <T _str="Open" />,
  A_3: <T _str="Pending" />,
  A_4: <T _str="On hold" />,
  A_5: <T _str="In Progress" />,
  A_6: <T _str="Completed" />,
  A_7: <T _str="Rejected" />,
}

export const ticketTicketStatusChoicesTagColorMap: Record<
  TicketTicketStatusChoices,
  TagProps['color']
> = {
  A_1: 'orange',
  A_2: 'red',
  A_3: 'gray',
  A_4: 'yellow',
  A_5: 'blue',
  A_6: 'green',
  A_7: 'red',
}

/**
 * Use this map when you are sending data to server.
 */
export const ticketTicketStatusChoicesMutationValueMap: Record<
  TicketTicketStatusChoices,
  number
> = {
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
  A_5: 5,
  A_6: 6,
  A_7: 7,
}

export const invertTicketTicketStatusChoicesMutationValueMap = invert(
  ticketTicketStatusChoicesMutationValueMap
) as Record<number, TicketTicketStatusChoices>

export const ticketTicketStatusChoicesSelectOptions = Object.keys(
  ticketTicketStatusChoicesLabelMap
)?.map((e) => ({
  value: e as TicketTicketStatusChoices,
  label: (
    <Tag
      color={
        ticketTicketStatusChoicesTagColorMap[e as TicketTicketStatusChoices]
      }
    >
      {ticketTicketStatusChoicesLabelMap[e as TicketTicketStatusChoices]}
    </Tag>
  ),
}))

// eslint-disable-next-line no-underscore-dangle
export const ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus: Record<
  TicketTicketStatusChoices,
  TStatusType
> = {
  A_1: 'created',
  A_2: 'open',
  A_3: 'pending',
  A_4: 'on_hold',
  A_5: 'in_progress',
  A_6: 'completed',
  A_7: 'rejected',
}

export const disabledTicketChoices: TicketTicketStatusChoices[] = ['A_6', 'A_7']
export const resolvedTicketChoices: TicketTicketStatusChoices[] = ['A_6', 'A_7']

export const invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus: Record<
  TStatusType,
  TicketTicketStatusChoices
> = invert(
  ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus
) as Record<TStatusType, TicketTicketStatusChoices>

type TColor = {
  primary: string
  secondary: string
}

export const ticketTicketStatusChoicesColorMap1: Partial<
  Record<TicketTicketStatusChoices, TColor>
> = {
  A_1: {
    primary: '#D46B08',
    secondary: '#FFF7E6',
  },
  A_2: {
    primary: '#F5222D',
    secondary: '#FFF1F0',
  },
  A_3: {
    primary: '#595959',
    secondary: '#F5F5F5',
  },
  A_4: {
    primary: '#595959',
    secondary: '#F5F5F5',
  },
  A_5: {
    primary: '#096DD9',
    secondary: '#E6F7FF',
  },
  A_6: {
    primary: '#739C00',
    secondary: '#F6FFED',
  },
  A_7: {
    primary: '#F5222D',
    secondary: '#FFF1F0',
  },
}

export const ticketTicketStatusChoicesColorMap2: Partial<
  Record<TicketTicketStatusChoices, TColor>
> = {
  A_1: {
    primary: '#FFE7BA',
    secondary: '#612500',
  },
  A_3: {
    primary: '#F5F5F5',
    secondary: '#262626',
  },
  A_5: {
    primary: '#BAE7FF',
    secondary: '#002766',
  },
  A_6: {
    primary: '#D9F7BE',
    secondary: '#135200',
  },
  A_7: {
    primary: '#FFF1F0',
    secondary: '#A8071A',
  },
}

export const SelectTicketTicketStatusTag = ({
  value,
  filterValue,
  onChange,
  ticketId,
  ...props
}: SelectProps & {
  ticketId: string
  filterValue?: TicketTicketStatusChoices
}) => {
  const inputRef = useRef<$TSFixMe>(null)
  const { loading, statusTransitionMap } = useTicketTypeStatusTransitionMap(
    ticketId,
    true
  )

  const valueForFiltering = filterValue !== undefined ? filterValue : value

  return (
    <Select
      loading={loading}
      ref={inputRef}
      mode="multiple"
      showSearch={false}
      value={value}
      onChange={(val, opt) => {
        inputRef.current.blur()
        onChange?.(val[val.length - 1], opt)
      }}
      options={ticketTicketStatusChoicesSelectOptions.filter((opt) => {
        const allowed =
          statusTransitionMap[valueForFiltering as TicketTicketStatusChoices] ||
          []
        return allowed.includes(opt.value)
      })}
      tagRender={({ value: val }) => {
        return (
          <Tag
            color={
              ticketTicketStatusChoicesTagColorMap[
                val as TicketTicketStatusChoices
              ]
            }
          >
            {
              ticketTicketStatusChoicesLabelMap[
                val as TicketTicketStatusChoices
              ]
            }
          </Tag>
        )
      }}
      {...props}
    />
  )
}

export const SelectTicketTicketStatusFilled = ({
  value,
  onChange,
  ticketId,
  ...rest
}: SelectProps<TicketTicketStatusChoices> & {
  omitOptions?: TSelectFilledStatusProps['omitOptions']
  ticketId: string
}) => {
  const { loading, statusTransitionMap } = useTicketTypeStatusTransitionMap(
    ticketId,
    true
  )

  return (
    <SelectFilledStatus
      {...rest}
      popupClassName={`wh-select-hide-selected-option ${
        rest.popupClassName || ``
      }`}
      omitOptions={[
        'solved',
        'new',
        ...((Object.keys(statusTypeData) as TStatusType[]).filter((k) => {
          const allowed =
            statusTransitionMap[value as TicketTicketStatusChoices] || []
          const v =
            invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[
              k as TStatusType
            ]
          return ![...allowed, value].includes(v as TicketTicketStatusChoices)
        }) || []),
      ]}
      {...rest}
      loading={loading || rest?.loading}
      value={
        value &&
        ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[
          value
        ]
      }
      onChange={(val: $TSFixMe, opt) =>
        onChange?.(
          invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[
            val as TStatusType
          ],
          opt
        )
      }
    />
  )
}

type TTagsTicketStatus = {
  ids: number[]
}

export const TagsTicketStatus = ({ ids }: TTagsTicketStatus) => {
  const statuses = useMemo(
    () =>
      ids.map((id) => {
        return Object.entries(ticketTicketStatusChoicesMutationValueMap).find(
          ([_, value]) => value === id
        )?.[0]
      }),
    [ids]
  )

  return (
    (getTagsColumn(
      ticketTicketStatusChoicesTagColorMap,
      (id) => ticketTicketStatusChoicesLabelMap[id as TicketTicketStatusChoices]
    )?.(statuses, {}, 0) as ReactElement) || null
  )
}
