import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { produce } from 'immer'
import { uniqueId } from 'lodash'
import { create, useStore } from 'zustand'

import Modal, { IModal } from 'src/storybook/components/Modal'

type Store = {
  jsxMap: Record<string, JSX.Element>
}

const store = create<Store>(() => ({
  jsxMap: {},
}))

type ModalProps = Omit<IModal, 'onClose'> & { onClose?: () => void }

export type ResolveReject = {
  resolve: (val: unknown) => void
  reject: (reason?: $TSFixMe) => void
  resolveWithoutDestroy?: (val: unknown) => void
}

type BaseModalProps = Omit<ModalProps, 'visible' | 'footer'>

type ChildModalProps = BaseModalProps & {
  children: (args: ResolveReject) => JSX.Element
  _internal: ResolveReject
  modalPropsWithActions?: (args: ResolveReject) => BaseModalProps
}

const CANCEL = `CANCEL`

const ChildModal = ({
  children,
  _internal,
  modalPropsWithActions,
  ...rest
}: ChildModalProps) => {
  return (
    <Modal
      {...rest}
      onClose={() => _internal.reject(CANCEL)}
      {...modalPropsWithActions?.(_internal)}
      visible
      footer={null}
    >
      {children(_internal)}
    </Modal>
  )
}

export type TAsyncModalArgs = Omit<ChildModalProps, '_internal'>

const asyncModal = <T = unknown,>(props: TAsyncModalArgs): Promise<T> => {
  const { setState } = store
  const myId = uniqueId()
  const div = document.createElement('div')
  document.body.appendChild(div)

  function destroy() {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.jsxMap[myId] = <></>
      })
    )
    if (div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  return new Promise((res, rej) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.jsxMap[myId] = createPortal(
          <ChildModal
            {...props}
            _internal={{
              resolve: (val) => {
                destroy()
                res(val as T)
              },
              reject: (err) => {
                destroy()
                rej(err)
              },
              resolveWithoutDestroy: (val) => {
                res({ val, destroy } as T)
              },
            }}
          />,
          div
        )
      })
    )
  })
}

asyncModal.CANCEL = CANCEL

export { asyncModal }

export const AsyncModalPortal = () => {
  const jsxMap = useStore(store, (str) => str.jsxMap)

  return (
    <>
      {Object.values(jsxMap).map((jsx, i) => (
        <Fragment key={i}>{jsx}</Fragment>
      ))}
    </>
  )
}
