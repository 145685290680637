import React from 'react'
import { T } from '@transifex/react'

import { createDriverManagementRouter } from './drivers'
import { createRoutesMapLocationMangementRouter } from './locations'
import { createRoutesMapVehicleManagementRouter } from './vehicles'
import { pathJoin } from '../../../utils'

export const createRoutesMapFleetManagement = (prefix: string) => ({
  vehicles: pathJoin(prefix)('vehicles').chainMapAuto(
    createRoutesMapVehicleManagementRouter,
    {
      title: <T _str="All Vehicles" />,
      breadCrumbTitle: <T _str="All Vehicles" />,
    }
  ),
  'street-sweeper-vehicle': pathJoin(prefix)('street-sweeper-vehicle').chain,
  locations: pathJoin(prefix)('locations').chainMapAuto(
    createRoutesMapLocationMangementRouter,
    {
      title: <T _str="All Locations" />,
      breadCrumbTitle: <T _str="All Locations" />,
    }
  ),
  drivers: pathJoin(prefix)('drivers').chainMapAuto(
    createDriverManagementRouter,
    {
      title: <T _str="All Drivers" />,
      breadCrumbTitle: <T _str="All Drivers" />,
    }
  ),
  'vehicle-planner': pathJoin(prefix)('vehicle-planner').chainWithTitle({
    title: <T _str="Vehicle Planner" />,
    breadCrumbTitle: <T _str="Vehicle Planner" />,
  }),
})
