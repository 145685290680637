import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createPickupSettingsDetailViewRoutesMap } from './id'

export const createPickupSettingsRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createPickupSettingsDetailViewRoutesMap, {
      title: <T _str="Pickup Setting Details" />,
      breadCrumbTitle: <T _str="Pickup Setting Details" />,
    }),
  create: pathJoin(prefix)('create').chainMapAuto(
    (prefix2) => ({
      configuration: pathJoin(prefix2)('configuration').chain,
    }),
    {
      title: <T _str="New Pickup Setting" />,
      breadCrumbTitle: <T _str="New Pickup Setting" />,
    }
  ),
})
