import { ImportCsvDelimiters } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const importDelimiterLabelMap: Record<ImportCsvDelimiters, string> = {
  [ImportCsvDelimiters.COMMA]: ',',
  [ImportCsvDelimiters.SEMICOLON]: ';',
  [ImportCsvDelimiters.TAB]: 'tab',
  [ImportCsvDelimiters.PIPE]: '|',
}

export const importDelimiterOptions = createOptionsFromLabelMap(
  importDelimiterLabelMap
)
