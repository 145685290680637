import { pathJoin } from 'src/reducers/routing/utils'

import { createAreasValueRoutesMap } from './areas'

export const createOperationManagementRoutesMap = (prefix: string) => ({
  'collection-weeks': pathJoin(prefix)('collection-weeks').chain,
  'collection-deviations': pathJoin(prefix)('collection-deviations').chain,
  areas: pathJoin(prefix)('areas').chainMapAuto(createAreasValueRoutesMap),
  haulers: pathJoin(prefix)('haulers').chain,
  'collection-calendar-days': pathJoin(prefix)('collection-calendar-days')
    .chain,
  'pickup-settings': pathJoin(prefix)('pickup-settings').chain,
  'appearance-settings': pathJoin(prefix)('appearance-settings').chain,
})
