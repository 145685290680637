import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createCompanyEmailAccountDetailViewRoutesMap } from './id'

export const createCompanyEmailAccountRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(
      createCompanyEmailAccountDetailViewRoutesMap,
      {
        title: <T _str="Company Email Account Detail" />,
        breadCrumbTitle: <T _str="Company Email Account Detail" />,
      }
    ),
})
