import { gql } from '@apollo/client'

export const GET_ALL_VEHICLES = gql`
  query GetAllVehicles(
    $pageSize: Int
    $offset: Int
    $name: String
    $status: String
    $order: String
    $orderBy: String
    $activeProjects: String
    $Type: String
    $search: String
    $drivers: [ID]
    $vehicleTypes: [ID]
    $primaryDriver: ID
  ) {
    allVehicles(
      first: $pageSize
      offset: $offset
      name: $name
      status_Iexact: $status
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
      Type: $Type
      search: $search
      drivers: $drivers
      vehicleTypes: $vehicleTypes
      primaryDriver: $primaryDriver
    ) {
      totalCount
      edges {
        node {
          id
          logo
          name
          status
          licensePlateNumber
          vehicleType {
            id
            name
            Type
            body
            bodyMechanization
            liftingMechanization
            loadingLocation
          }
          realtimeLocation {
            id
            latitude
            longitude
            name
          }
          primaryDriver {
            id
            user {
              id
              logo
              firstName
              lastName
              username
            }
          }
          drivers {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  username
                }
              }
            }
          }
          project {
            id
            name
          }
          rfidVehicles {
            provider
            enabled
          }
        }
      }
    }
  }
`
