import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdGlassFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3635 9.17343C10.3635 5.84994 8.74033 6.1186 8.69664 3.00348V1.1297H7.30446V3.00348C7.26022 6.1186 5.63701 5.84994 5.63701 9.17343L5.63428 14.8703H10.3657L10.3635 9.17343ZM9.56504 14.6207C9.28267 14.1001 8.68735 13.7407 7.99973 13.7407C7.3121 13.7407 6.71733 14.1006 6.43441 14.6207H6.12965C6.43004 13.9584 7.15481 13.4907 7.99918 13.4907C8.84355 13.4907 9.56832 13.9589 9.86871 14.6207H9.56395H9.56504Z" />
    </svg>
  )
})
