import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdSoilFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.41908 9.43929C6.52272 9.57419 6.77501 9.89627 7.0473 10.1984C7.25745 10.4314 7.43898 10.608 7.58692 10.7231C7.68094 10.7963 7.76106 10.8447 7.83178 10.8714C7.89994 10.897 7.99793 10.9151 8.07801 10.8551C8.22313 10.7464 8.15185 10.5145 8.08563 10.3495C8.01607 10.1761 7.89711 9.95324 7.73219 9.6869C7.51736 9.34003 7.27792 9.00658 7.17855 8.87072L7.99786 8.25725L9.34649 10.0586C9.37228 10.0942 9.39633 10.1317 9.41873 10.1709C9.85828 10.9415 9.64732 12.2334 9.30008 12.4934L9.29766 12.4952C8.95046 12.7551 7.6514 12.5938 7.03578 11.9551C7.00441 11.9225 6.97543 11.8887 6.94856 11.8539L5.59966 10.0528L6.41908 9.43929ZM4.06269 9.42666C3.60308 9.8516 3.04483 9.83377 2.67407 10.5109C2.48124 10.8629 1.20794 13.9055 1.19922 13.927H14.8009C14.5323 13.3237 13.7739 11.6717 13.3343 11.2367C13.0524 10.9578 12.449 10.6074 12.2889 10.3022C12.1288 9.99698 12.1948 10.1229 11.8228 9.10317C11.4509 8.08363 10.7301 7.39287 10.064 6.67205C9.73227 6.3131 9.25455 5.80168 8.68119 5.55474C8.11588 5.31126 7.36667 5.24727 6.51016 6.0834C6.29358 6.31555 6.02175 6.61259 5.74805 7.04129C5.7191 7.08659 5.69223 7.12914 5.66704 7.16942L7.02641 8.98458C7.12459 9.11885 7.36065 9.44569 7.57161 9.78635C8.06241 10.5787 7.96482 10.7039 7.96482 10.7039C7.96482 10.7039 7.82327 10.7769 7.18829 10.0727C6.92095 9.7762 6.67371 9.45903 6.57107 9.32554L5.35094 7.6963C5.23938 7.88452 5.12816 8.0623 4.90292 8.37127C4.34936 9.13059 4.27132 9.23394 4.06269 9.42666Z" />
      <path d="M3.58715 4.39214L3.9514 4.11938L3.78094 3.89172L2.59705 4.77814L2.76751 5.00579L3.1318 4.73307L5.35094 7.6963C5.43801 7.54947 5.5253 7.39622 5.66704 7.16942L3.58715 4.39214Z" />
    </svg>
  )
})
