import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createHaulerDetailViewRoutesMap } from './id'

export const createHaulerRoutesMap = (prefix: string) => ({
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createHaulerDetailViewRoutesMap, {
      title: <T _str="Hauler Details" />,
      breadCrumbTitle: <T _str="Hauler Details" />,
    }),
  create: pathJoin(prefix)('create').chainMapAuto((prefix2) => ({
    configuration: pathJoin(prefix2)('configuration').chainWithTitle({
      title: <T _str="New Hauler" />,
      breadCrumbTitle: <T _str="New Hauler" />,
    }),
  })),
})
