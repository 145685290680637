import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdPlasticFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.00768 7.18841C8.00768 7.17947 8.00768 7.17138 8.00768 7.16328C8.00768 7.15604 8.00768 7.14923 8.00768 7.14199V7.11559C8.00723 6.83877 8.00632 6.26342 8.42389 5.74726L9.69511 4.10254V3.38622C8.63719 3.26017 6.67319 3.2904 5.84439 3.2904C2.1663 3.2904 2.07366 3.11452 2.0714 3.10259L2.23906 6.26256C2.33712 7.12496 2.48806 8.01205 2.97477 8.74029C3.02086 8.80885 3.07148 8.8821 3.06876 8.96387C3.0665 9.02818 3.03171 9.0861 3.00053 9.14274C2.70453 9.67295 2.63132 10.2658 2.56308 10.865C2.51427 11.2947 2.46547 11.7239 2.41621 12.1537C2.37599 12.5063 2.37734 12.7418 2.33712 13.094C2.33396 13.2251 2.2011 13.338 2.11704 13.4406C2.03299 13.5433 2.00723 13.7741 2 13.9619C2.17353 14.0241 3.29517 13.9985 4.24418 13.967C4.84883 13.947 5.39338 13.8789 5.54387 13.8614C6.37041 13.7664 7.22678 13.7498 8.08179 13.7652C8.03298 13.6732 8.00723 13.5735 8.00723 13.4713L8.00768 7.18841ZM7.83369 4.72176C7.83369 4.93086 7.65203 5.10206 7.43014 5.10206H4.77834C4.55645 5.10206 4.37478 4.93086 4.37478 4.72176C4.37478 4.51266 4.55645 4.34146 4.77834 4.34146H7.43014C7.65203 4.34146 7.83369 4.51266 7.83369 4.72176Z" />
      <path d="M8.2368 7.17992C8.2368 7.16374 8.23725 7.14968 8.23725 7.13393C8.23725 6.95719 8.23092 6.65439 8.34706 6.32519C8.23183 6.63778 8.23228 6.93036 8.23228 7.11519V7.14202C8.23228 7.15053 8.23228 7.15863 8.23228 7.16672C8.23228 7.17396 8.23228 7.1812 8.23228 7.18886V13.4713C8.23228 13.5769 8.27611 13.6834 8.35384 13.7711C8.28109 13.6898 8.23725 13.5863 8.23725 13.4743L8.2368 7.17992Z" />
      <path d="M13.4704 5.73362L11.6474 4.1745V2.00468L9.91971 2V4.17152L9.91833 4.17411L9.87224 4.23288L8.60285 5.87458C8.48264 6.02321 8.4022 6.17614 8.34706 6.32519C8.23092 6.65439 8.23725 6.95719 8.23725 7.13393C8.23725 7.14968 8.2368 7.16374 8.2368 7.17992L8.2359 13.4743C8.2359 13.5867 8.28063 13.6898 8.35384 13.7711C8.45236 13.881 8.60285 13.9512 8.77277 13.9512H13.4631C13.7596 13.9512 14 13.7375 14 13.4743V7.16455C14 7.14496 14 7.12622 14 7.10621C14 6.77956 13.9652 6.16886 13.4704 5.73362ZM13.1866 9.68358C13.1866 9.89183 13.0058 10.0622 12.7848 10.0622H12.7817C12.5607 10.0622 12.3799 9.89183 12.3799 9.68358V7.18158C12.3799 6.97333 12.5607 6.80298 12.7817 6.80298H12.7848C13.0058 6.80298 13.1866 6.97333 13.1866 7.18158V9.68358Z" />
    </svg>
  )
})
