import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { ImportHistoryStatuses } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const importStatusLabelMap: Record<ImportHistoryStatuses, ReactNode> = {
  [ImportHistoryStatuses.NEW]: <T _str="New" />,
  [ImportHistoryStatuses.IN_PROGRESS]: <T _str="Importing" />,
  [ImportHistoryStatuses.COMPLETED]: <T _str="Completed" />,
  [ImportHistoryStatuses.FAILED]: <T _str="Failed" />,
}

export const importStatusColorMap: Record<
  ImportHistoryStatuses,
  TagProps['color']
> = {
  [ImportHistoryStatuses.NEW]: 'gray',
  [ImportHistoryStatuses.IN_PROGRESS]: 'blue',
  [ImportHistoryStatuses.COMPLETED]: 'green',
  [ImportHistoryStatuses.FAILED]: 'red',
}

export const importStatusOptions =
  createOptionsFromLabelMap(importStatusLabelMap)
