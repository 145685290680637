import { ReactNode } from 'react'

import { Opt } from './types'

export const getOptsFromValueMap = <T extends string = string>(
  map: Record<T, ReactNode | string | number>,
  setOptionDisabled?: (key: T) => boolean
): Opt<T>[] =>
  (Object.keys(map) as T[]).map((k) => ({
    label: map[k],
    value: k,
    disabled: setOptionDisabled ? setOptionDisabled(k) : false,
  }))
