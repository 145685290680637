import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TRoutesPickUpOrderCreatedFromChoices =
  | `STATIC`
  | `SEMI_DYNAMIC`
  | `DYNAMIC`
  | `MANUAL`

export const routesPickUpOrderCreatedFromChoicesLabelMap: Record<
  TRoutesPickUpOrderCreatedFromChoices,
  ReactNode
> = {
  STATIC: <T _str="Static" />,
  DYNAMIC: <T _str="Dynamic" />,
  MANUAL: <T _str="Manual" />,
  SEMI_DYNAMIC: <T _str="Semi-dynamic" />,
}
