import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TCoreCompanySettingsCrmLoginMethodChoices = `NONE` | `USER`

export const coreCompanySettingsCrmLoginMethodChoicesLabelMap: Record<
  TCoreCompanySettingsCrmLoginMethodChoices,
  ReactNode
> = {
  NONE: <T _str="No authentication" />,
  USER: <T _str="MitID / NemID" />,
}

export const coreCompanySettingsCrmLoginMethodChoicesOptions =
  createOptionsFromLabelMap(coreCompanySettingsCrmLoginMethodChoicesLabelMap)
