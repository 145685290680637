import { ReactNode } from 'react'

import {
  blue100,
  blue700,
  gray100,
  gray500,
  gray900,
  green100,
  green700,
  orange1000,
  orange600,
  red100,
  red700,
} from 'src/styles/color-theme'

import {
  TX_COMPLETED,
  TX_CREATED,
  TX_IN_PROGRESS,
  TX_NEW,
  TX_ON_HOLD,
  TX_OPEN,
  TX_PENDING,
  TX_REJECTED,
  TX_SOLVED,
} from '../../transifex'
import { TOptColors } from '../SelectFilled'

export type TStatusType =
  | `new`
  | `open`
  | `in_progress`
  | `on_hold`
  | `solved`
  | `pending`
  | `rejected`
  | `created`
  | `completed`

export const statusTypeData: Record<
  TStatusType,
  { label: ReactNode; colors: TOptColors }
> = {
  new: {
    label: TX_NEW,
    colors: { primary: orange1000, secondary: orange600 },
  },
  in_progress: {
    label: TX_IN_PROGRESS,
    colors: { primary: blue700, secondary: blue100 },
  },
  on_hold: {
    label: TX_ON_HOLD,
    colors: { primary: gray900, secondary: gray500 },
  },
  open: {
    label: TX_OPEN,
    colors: { primary: red700, secondary: red100 },
  },
  solved: {
    label: TX_SOLVED,
    colors: { primary: green700, secondary: green100 },
  },
  created: {
    label: TX_CREATED,
    colors: { primary: orange1000, secondary: orange600 },
  },
  completed: {
    label: TX_COMPLETED,
    colors: { primary: green700, secondary: green100 },
  },
  pending: {
    label: TX_PENDING,
    colors: { primary: gray900, secondary: gray100 },
  },
  rejected: {
    label: TX_REJECTED,
    colors: { primary: red700, secondary: red100 },
  },
}
