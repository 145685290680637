import React, { PropsWithChildren } from 'react'
import {
  DownCircleFilled,
  LeftCircleFilled,
  RightCircleFilled,
  UpCircleFilled,
} from '@ant-design/icons'
import { T } from '@transifex/react'
import { Empty as AntdEmpty, EmptyProps, Space, Typography } from 'antd'

import Img from '../images/empty/Exclamation.png'
import List from '../images/empty/List.png'

const EmptyWrapper = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}

type TEmptyProps = Omit<EmptyProps, 'image' | 'imageStyle'> & {
  withoutWrapper?: boolean
}

export const Empty = ({ withoutWrapper = false, ...props }: TEmptyProps) => {
  const component = (
    <AntdEmpty
      description={
        <span>
          <T _str="No data found" />
        </span>
      }
      {...props}
      image={Img}
      imageStyle={{
        height: 50,
      }}
    />
  )
  if (!withoutWrapper) {
    return <EmptyWrapper>{component}</EmptyWrapper>
  }
  return component
}

type EmptySelectItemProps = {
  direction?: 'top' | 'left' | 'right' | 'bottom'
  size?: 'default'
}

const sizeMap: Record<
  Exclude<EmptySelectItemProps['size'], undefined>,
  number
> = {
  default: 80,
}

export const EmptySelectItem = ({
  direction = 'left',
  size = 'default',
}: EmptySelectItemProps) => {
  return (
    <AntdEmpty
      image={List}
      imageStyle={{
        height: sizeMap[size],
      }}
      description={
        <Typography.Text type="secondary">
          <Space>
            {direction === 'bottom' && (
              <>
                <DownCircleFilled /> <T _str="Select an item at the bottom" />
              </>
            )}
            {direction === 'left' && (
              <>
                <LeftCircleFilled /> <T _str="Select an item on the left" />
              </>
            )}
            {direction === 'right' && (
              <>
                <RightCircleFilled /> <T _str="Select an item on the right" />
              </>
            )}
            {direction === 'top' && (
              <>
                <UpCircleFilled /> <T _str="Select an item at the top" />
              </>
            )}
          </Space>
        </Typography.Text>
      }
    />
  )
}
