import React from 'react'
import { Skeleton, SkeletonProps } from 'antd'
import styled from 'styled-components'

import { toCssUnit } from 'src/utils/others'

export const StyledSkeletonInput = styled(Skeleton.Input)<{
  width?: number | string
}>`
  &.ant-skeleton-width-full {
    width: 100% !important;
  }

  &.ant-skeleton-bordered {
    border-radius: 4px;
  }

  &.ant-skeleton-element {
    display: flex;
  }

  ${({ width }) =>
    width
      ? `
    &.ant-skeleton-element {
      width: ${toCssUnit(width)};
      .ant-skeleton-input {
        width: ${toCssUnit(width)};
        min-width: ${toCssUnit(width)};
      }
    }
  `
      : ``}
`

type TSize = `large` | 'default' | 'small'

const sizeValue: Record<TSize, string> = {
  default: '32px',
  small: '24px',
  large: '40px',
}

type TStyledSkeletonProps = {
  /**
   * Size props
   * Set heights of the following elements
   * ant-skeleton-title, and ant-skeleton-paragraph > li
   */
  size?: TSize
  sizeOverwrite?: string
}

const SkeletonStyle = styled(Skeleton)<TStyledSkeletonProps>`
  &.ant-skeleton-width-full {
    width: 100% !important;
  }
  .ant-skeleton-title {
    height: ${({ size = 'default' }) => sizeValue[size]};
    ${({ sizeOverwrite }) =>
      sizeOverwrite ? `height: ${toCssUnit(sizeOverwrite)};` : ``}
  }
  .ant-skeleton-paragraph > li {
    height: ${({ size = 'default' }) => sizeValue[size]};
    ${({ sizeOverwrite }) =>
      sizeOverwrite ? `height: ${toCssUnit(sizeOverwrite)};` : ``}
  }
`

export const StyledSkeleton = ({
  ...props
}: TStyledSkeletonProps & SkeletonProps) => {
  return <SkeletonStyle {...props} />
}
