import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { enableMapSet } from 'immer'
import Helmet from 'react-helmet'
import { Router } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import { GlobalStyles as StorybookStyles } from 'src/storybook/styles'

import AntdI18n from './AntdI18n'
import { GlobalStyles, GlobalStylesAntFillViewList } from './global.styles'
import MomentI18n from './MomentI18n'
import { createConfig } from './route-config'
import MainRouter from './router'
import { BirdsEatBug } from './setupBirdsEatBug'
import Transifex from './Transifex'
import { WsDisconnectNotification } from '../components/graphql/ws'
import history from '../history'
import FavIcon from '../images/favicon-32x32.png'
import RouterFactory, {
  useRouting,
} from '../reducers/routing/child-router-factory'
import { useHistoryStoreInit } from '../reducers/routing/store'
import { AntdMessageWithContextInit } from '../utils/antdMessageContext'
import { AsyncModalPortal } from '../utils/asyncModalAction'

/**
 * See: Immer docs on why we need this.
 * Note: Don't remove this until/unless you are sure that we are not using JS Map in any immer produce function
 * through out our app. If you remove it, then it will break those actions
 */
enableMapSet()

const App = () => {
  const { routingMap: map } = useRouting()

  BirdsEatBug.useInit()
  useHistoryStoreInit()

  return (
    <>
      <div className="wh-print-insert" />
      <div className="mainHolder">
        <Helmet>
          <title>WasteHero Platform</title>
          <meta name="description" content="WasteHero" />
          <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
        </Helmet>
        <StorybookStyles />
        <GlobalStyles />
        <GlobalStylesAntFillViewList defaultYScroller />
        <MomentI18n />
        <Transifex />
        <Router history={history}>
          <>
            <AntdI18n>
              <MainRouter>
                <AsyncModalPortal />
                <AntdMessageWithContextInit />
                <Suspense
                  fallback={
                    <div className="fill-parent center-child">
                      <Spin size="large" />
                    </div>
                  }
                >
                  <RouterFactory
                    config={createConfig(map)}
                    defaultRedirect="/app/dashboard"
                  />
                </Suspense>
              </MainRouter>
            </AntdI18n>
            <WsDisconnectNotification />
            <ToastContainer
              enableMultiContainer
              position={toast.POSITION.BOTTOM_RIGHT}
            />
          </>
        </Router>
      </div>
    </>
  )
}

export default App
