import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

export const createNotificationRouteMap = (prefix: string) => ({
  '/:id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(
      (prefixTwo: string) => ({
        overview: pathJoin(prefixTwo)('overview').chain,
        'notification-details': pathJoin(prefixTwo)('notification-details')
          .chain,
      }),
      {
        title: <T _str="Route Details" />,
        breadCrumbTitle: <T _str="Details" />,
      }
    ),
})
