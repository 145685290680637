import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdGardenWasteFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.66772 8.88065C4.7664 9.57233 5.05724 10.9148 6.08287 12.0577C6.79695 12.8535 7.77782 13.3876 7.87649 13.4402V11.2821L4.66772 8.88065Z" />
      <path d="M10.3781 4.35353C10.0521 3.87846 9.60548 3.41113 9.27375 3.00037C8.96961 2.62383 8.56321 2.24095 8.35275 1.71618L8.12341 1.09061V6.04093L10.3781 4.35353Z" />
      <path d="M7.87657 8.81508L4.74068 6.46819L4.73943 6.47409C4.61564 7.08185 4.57576 8.04772 4.62633 8.54118L7.87657 10.9737V8.81508Z" />
      <path d="M7.87656 6.34933L5.48819 4.56185L5.48718 4.56355C5.1689 5.09441 4.9312 5.7271 4.8031 6.20647L7.87656 8.50667V6.34933Z" />
      <path d="M7.87654 1.09187L7.6473 1.71695C7.43684 2.24172 7.03044 2.62465 6.72629 3.00115C6.39466 3.41176 5.94824 3.87894 5.62231 4.35386L7.87654 6.04093V1.09187Z" />
      <path d="M8.12341 11.2821V13.4395C8.22155 13.3871 9.20281 12.853 9.91713 12.057C10.9424 10.9145 11.2334 9.57253 11.3322 8.88066L8.12341 11.2821Z" />
      <path d="M8.12341 10.9737L11.3737 8.54118C11.4244 8.04791 11.3845 7.08137 11.2607 6.47332L11.2595 6.46799L8.12341 8.81508V10.9737Z" />
      <path d="M8.12341 8.50667L11.1972 6.20628C11.0691 5.72681 10.8313 5.09388 10.5129 4.56277L10.5122 4.56156L8.12341 6.34933V8.50667Z" />
      <path d="M8.12349 15.9094H7.87659L7.87649 13.4402L8.12341 13.4395L8.12349 15.9094Z" />
    </svg>
  )
})
