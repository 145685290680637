/**
 * Extracts `PossibleTypesMap` as accepted by `@apollo/client` from GraphQL introspection query result
 */
/* eslint-disable no-underscore-dangle */

const introspectionToPossibleTypes = (
  introspectionQueryResultData: $TSFixMe
) => {
  const possibleTypes = {}

  introspectionQueryResultData.__schema.types.forEach((supertype: $TSFixMe) => {
    if (supertype.possibleTypes) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        (subtype: $TSFixMe) => subtype.name
      )
    }
  })

  return possibleTypes
}

export default introspectionToPossibleTypes
