import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createDetailViewRouteMap } from './detailed-view'

export const createPropertyGroupsRouteMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createDetailViewRouteMap, {
      title: <T _str="Property Group Detail" />,
      breadCrumbTitle: <T _str="Detail" />,
    }),
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="New Property Group" />,
    breadCrumbTitle: <T _str="New" />,
  }),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainWithTitle({
      title: <T _str="Edit" />,
      breadCrumbTitle: <T _str="Edit" />,
    }),
})
