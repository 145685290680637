import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdBatteryFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M11 14.132C11 14.1872 10.9552 14.232 10.9 14.232H5.1C5.04477 14.232 5 14.1872 5 14.132V3.18651C5 3.13128 5.04477 3.08651 5.1 3.08651H10.9C10.9552 3.08651 11 3.13128 11 3.18651V14.132ZM9.00026 4.77984H8.20068V4.23387H7.80089V4.77984H7.00131V5.09062H7.80089V5.66178H8.20068V5.09062H9.00026V4.77984ZM9.00026 12.7996H7.00052V13.0852H9.00026V12.7996Z" />
      <path d="M6.59994 1.86799C6.59994 1.81276 6.64472 1.76799 6.69994 1.76799H9.30005C9.35528 1.76799 9.40005 1.81276 9.40005 1.86799V2.80037H6.69994C6.64472 2.80037 6.59994 2.7556 6.59994 2.70037V1.86799Z" />
    </svg>
  )
})
