import { ReactNode } from 'react'
import { TagProps } from 'src/antd'

import { TX_COMPLETED, TX_ERROR, TX_IN_PROGRESS } from 'src/storybook/transifex'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TImportContainerStatus = 'in_progress' | 'error' | 'completed'

export const importContainerStatusLabelMap: Record<
  TImportContainerStatus,
  ReactNode
> = {
  in_progress: TX_IN_PROGRESS,
  error: TX_ERROR,
  completed: TX_COMPLETED,
}

export const importContainerStatusOptions = createOptionsFromLabelMap(
  importContainerStatusLabelMap
)

export const importContainerStatusColorMap: Record<
  TImportContainerStatus,
  TagProps['color']
> = {
  in_progress: 'blue',
  error: 'red',
  completed: 'green',
}
