import React, { useEffect } from 'react'
import { T } from '@transifex/react'
import { createBrowserHistory } from 'history'

import { useHistoryStore } from './reducers/routing/store'
import { asyncAlertAction } from './storybook/components'

let confirmBeforeNavigateCount = 0
let openAllLinksInNewTabCount = 0

export const ConfirmBeforeNavigate = () => {
  useEffect(() => {
    confirmBeforeNavigateCount += 1
    return () => {
      confirmBeforeNavigateCount -= 1
    }
  }, [])

  return <></>
}

export const OpenAllLinksInNewTab = () => {
  useEffect(() => {
    openAllLinksInNewTabCount += 1
    return () => {
      openAllLinksInNewTabCount -= 1
    }
  }, [])

  return <></>
}

const history = createBrowserHistory()
// Todo: Remvoe prevPath code and see it's not being used anywhere.
let prevPath: string | null = null

const originalHistoryPush = history.push

history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname
  }
})

history.push = (...args) => {
  const { commandKeyDown } = useHistoryStore.getState()

  const openLinkInNewTab = () => {
    const location =
      typeof args?.[0] === 'string' ? args?.[0] : args?.[0]?.pathname
    window.open(location, '_blank', 'noreferrer')
  }

  const fn = () => {
    if (commandKeyDown || openAllLinksInNewTabCount > 0) openLinkInNewTab()
    else originalHistoryPush(...args)
  }

  // check if confirm before navigate is set somewhere.
  if (confirmBeforeNavigateCount > 0) {
    asyncAlertAction({
      confirmProps: {
        title: <T _str="Are you sure you want to navigate?" />,
        variant: 'default',
        children: <T _str="You have unsaved changes." />,
      },
      asyncCall: () => Promise.resolve(fn()),
      modalProps: {
        title: <T _str="Confirm before navigate" />,
      },
    })
  } else {
    fn()
  }
}

export default history
