import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdCardboardFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9691 3.60595C13.0243 3.55267 12.9782 3.4707 12.8929 3.4707H6.0704C6.01517 3.4707 5.9704 3.50744 5.9704 3.55276V6.42243C5.9704 6.46775 6.01517 6.50449 6.0704 6.50449H9.91483C9.94413 6.50449 9.97197 6.49394 9.99096 6.47563L12.9691 3.60595Z" />
      <path d="M5.64119 6.50448C5.69642 6.50448 5.74119 6.46775 5.74119 6.42243V3.77179C5.74119 3.69548 5.62535 3.6605 5.56505 3.7186L2.81416 6.36923C2.75886 6.42251 2.80501 6.50448 2.89029 6.50448H5.64119Z" />
      <path d="M2.56398 9.52001V13.4473C2.56398 13.4926 2.60876 13.5293 2.66399 13.5293L9.70409 13.5289C9.75932 13.5289 9.80408 13.4922 9.80408 13.4469V7.18107L9.8041 7.16918L8.9079 9.52001H2.56398Z" />
      <path d="M9.34661 7.73268L9.71854 6.7572L2.49306 6.76015L1.72083 8.77907L1.52869 9.28109L8.75691 9.27859L9.34661 7.73268Z" />
      <path d="M13.216 7.56811C13.216 7.49181 13.1002 7.45682 13.0399 7.5149L11.3016 9.18916C11.2537 9.23527 11.1636 9.22439 11.1339 9.16892L10.2468 7.5098C10.2033 7.42844 10.0553 7.45392 10.0553 7.54276V13.2155C10.0553 13.2918 10.1711 13.3268 10.2314 13.2687L13.1921 10.4163C13.2075 10.4015 13.216 10.3826 13.216 10.3631V7.56811Z" />
      <path d="M14.4494 5.7635L13.3657 3.73675C13.336 3.68127 13.2458 3.67039 13.198 3.71652L10.1269 6.67582C10.1021 6.69975 10.0961 6.7332 10.1115 6.76198L11.1915 8.78248C11.2212 8.83796 11.3114 8.84885 11.3592 8.80273L14.4361 5.83827C14.4576 5.8175 14.4628 5.78845 14.4494 5.7635Z" />
    </svg>
  )
})
