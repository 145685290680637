import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { CoreExportStatusChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const classicExportStatusLabelMap: Record<
  CoreExportStatusChoices,
  ReactNode
> = {
  [CoreExportStatusChoices.NEW]: <T _str="New" />,
  [CoreExportStatusChoices.IN_PROGRESS]: <T _str="In progress" />,
  [CoreExportStatusChoices.FAILED]: <T _str="Failed" />,
  [CoreExportStatusChoices.COMPLETED]: <T _str="Completed" />,
}

export const classicExportStatusOptions = createOptionsFromLabelMap(
  classicExportStatusLabelMap
)

export const classicExportStatusColorMap: Record<
  CoreExportStatusChoices,
  TagProps['color']
> = {
  [CoreExportStatusChoices.NEW]: 'gray',
  [CoreExportStatusChoices.IN_PROGRESS]: 'blue',
  [CoreExportStatusChoices.FAILED]: 'red',
  [CoreExportStatusChoices.COMPLETED]: 'green',
}
