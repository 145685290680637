import { gql } from '@apollo/client'

export const GET_ALL_CLASSIC_EXPORTS = gql`
  query GetAllClassicExports(
    $activeProjects: String!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: String
    $order: String
    $offset: Int
    $status: String
    $exportType: String
  ) {
    allExports(
      activeProjects: $activeProjects
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      order: $order
      offset: $offset
      status: $status
      exportType: $exportType
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          completedAt
          exportType
          status
          file
          progressPercent
        }
      }
    }
  }
`
