import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdClothingFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.59262 2.69888C9.30716 3.29086 8.70129 3.69933 8.00011 3.69933C7.29889 3.69933 6.69306 3.29086 6.40756 2.69888H2.10999V5.73821H4.46601V13.3011H11.5357L11.5342 5.73821H13.8902V2.69888H9.59262Z" />
    </svg>
  )
})
