import { pathJoin } from 'src/reducers/routing/utils'

export const createPricingRoutesMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chain,
  services: pathJoin(prefix)('services').chain,
  recurring: pathJoin(prefix)('recurring').chain,
  'agreement-templates': pathJoin(prefix)('agreement-templates').chainMapAuto(
    (p2) => ({
      ':id': (id = ':id') => pathJoin(p2)(id).chain,
    })
  ),
})
