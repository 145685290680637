import { gql } from '@apollo/client'

export const FrgRouteDrivers = gql`
  fragment FrgRouteDrivers on RouteType {
    routeVehicles {
      edges {
        node {
          driver {
            id
          }
          routeVehicleSessions {
            startedAt
            isActive
            driver {
              id
            }
          }
        }
      }
    }
  }
`

export const GET_ROUTE_DRIVERS = gql`
  ${FrgRouteDrivers}
  query GetRouteDrivers($routeId: ID!) {
    route(id: $routeId) {
      ...FrgRouteDrivers
    }
  }
`
