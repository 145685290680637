import { gql } from '@apollo/client'

export const GET = gql`
  query GetVehicleInfoPopover($id: ID!) {
    vehicle(id: $id) {
      id
      name
      logo
      status
      vehicleType {
        id
        name
      }
      activeDepot {
        id
        name
      }
    }
  }
`
