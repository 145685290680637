import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdHardPlasticFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8563 5.56125L8.64238 3.86532V1.5051L6.5421 1.5V3.86532L4.97746 5.65807C4.48354 6.19821 4.50055 6.79672 4.50055 7.08486C4.50055 7.102 4.5 7.11729 4.5 7.13489V13.9812C4.5 14.2679 4.79196 14.5 5.15198 14.5H10.848C11.208 14.5 11.5 14.2675 11.5 13.9812V7.11775C11.5 7.09644 11.5 7.07606 11.5 7.05429C11.5 6.69898 11.4572 6.03469 10.8563 5.56125ZM10.5116 9.85784C10.5116 10.0844 10.2921 10.2697 10.0237 10.2697H10.0199C9.75151 10.2697 9.53199 10.0844 9.53199 9.85784V7.13628C9.53199 6.90976 9.75151 6.72446 10.0199 6.72446H10.0237C10.2921 6.72446 10.5116 6.90976 10.5116 7.13628V9.85784Z" />
    </svg>
  )
})
