import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createCustomersRouteMap } from './customers'
import { createOffersRoutesMap } from './offers'

export const createControlCenterRoutesMap = (prefix: string) => ({
  leads: pathJoin(prefix)('leads').chainWithTitle({
    title: <T _str="Leads" />,
    breadCrumbTitle: <T _str="Leads" />,
  }),
  customers: pathJoin(prefix)('customers').chainMapAuto(
    createCustomersRouteMap,
    {
      title: <T _str="Customers" />,
      breadCrumbTitle: <T _str="Customers" />,
    }
  ),
  offers: pathJoin(prefix)('offers').chainMapAuto(createOffersRoutesMap, {
    title: <T _str="Offers" />,
    breadCrumbTitle: <T _str="Offers" />,
  }),
  'test-sockets': pathJoin(prefix)('test-sockets').chain,
  'error-test': pathJoin(prefix)('error-test').chain,
})
