import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { MapboxProps } from 'react-map-gl/dist/esm/mapbox/mapbox'

export type TStyles = 'satellite' | 'streets'

export const stylesLabelMap: Record<TStyles, ReactNode> = {
  satellite: <T _str="Satellite" />,
  streets: <T _str="Streets" />,
}

export const stylesValueMap = {
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  streets: 'mapbox://styles/mapbox/streets-v12',
}

export const getMapboxBaseProps = (): {
  mapboxAccessToken: Exclude<MapboxProps['mapboxAccessToken'], undefined>
  primaryStyle: string
} => ({
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
  primaryStyle: stylesValueMap.streets,
})
