import { pathJoin } from 'src/reducers/routing/utils'

export const createFleetManagementRoutesMap = (prefix: string) => ({
  'gps-integrations': pathJoin(prefix)('gps-integrations').chain,
  'gps-integration-vehicles': pathJoin(prefix)('gps-integration-vehicles')
    .chain,
  'vehicle-types': pathJoin(prefix)('vehicle-types').chainMapAuto(
    (prefix2) => ({
      ':id': (id = ':id') => pathJoin(prefix2)(id).chain,
    })
  ),
})
