import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import pick from 'lodash/pick'
import { TagProps } from 'src/antd'

import { ExportTypes } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const exportTypeLabelMap: Record<ExportTypes, ReactNode> = {
  [ExportTypes.CONTAINER]: <T _str="Containers" />,
  [ExportTypes.TICKET]: <T _str="Tickets" />,
  [ExportTypes.ROUTE]: <T _str="Routes" />,
  [ExportTypes.PROPERTY]: <T _str="Properties" />,
  [ExportTypes.AGREEMENT]: <T _str="Agreements" />,
  [ExportTypes.PRODUCT]: <T _str="Products" />,
  [ExportTypes.HAULER_PRICE]: <T _str="Hauler prices" />,
  [ExportTypes.TICKET_TYPE]: <T _str="Ticket types" />,
  [ExportTypes.SERVICE]: <T _str="Services" />,
  [ExportTypes.COLLECTION_CALENDAR]: <T _str="Collection calendars" />,
  [ExportTypes.COLLECTION_CALENDAR_DATE]: (
    <T _str="Collection calendar dates" />
  ),
  [ExportTypes.CONTAINER_TYPE]: <T _str="Container types" />,
  [ExportTypes.PICKUP_SETTING]: <T _str="Pickup settings" />,
  [ExportTypes.WASTE_FRACTION]: <T _str="Waste fractions" />,
  [ExportTypes.VEHICLE]: <T _str="Vehicles" />,
  [ExportTypes.PICKUP_ORDER]: <T _str="Pickup orders" />,
}

export const exportTypesOptions = createOptionsFromLabelMap(exportTypeLabelMap)

enum AllowedExportTypeColor {
  CONTAINER = ExportTypes.CONTAINER,
  TICKET = ExportTypes.TICKET,
  ROUTE = ExportTypes.ROUTE,
  PROPERTY = ExportTypes.PROPERTY,
  AGREEMENT = ExportTypes.AGREEMENT,
  WASTE_FRACTION = ExportTypes.WASTE_FRACTION,
  PICKUP_SETTING = ExportTypes.PICKUP_SETTING,
  COLLECTION_CALENDAR = ExportTypes.COLLECTION_CALENDAR,
  COLLECTION_CALENDAR_DATE = ExportTypes.COLLECTION_CALENDAR_DATE,
  CONTAINER_TYPE = ExportTypes.CONTAINER_TYPE,
  VEHICLE = ExportTypes.VEHICLE,
  PICKUP_ORDER = ExportTypes.PICKUP_ORDER,
}

export const allowedExportTypesOptions =
  createOptionsFromLabelMap<AllowedExportTypeColor>(
    pick(exportTypeLabelMap, Object.keys(AllowedExportTypeColor)) as Record<
      AllowedExportTypeColor,
      ReactNode
    >
  )

export const exportTypeColorMap: Record<ExportTypes, TagProps['color']> = {
  [ExportTypes.CONTAINER]: 'orange',
  [ExportTypes.TICKET]: 'blue',
  [ExportTypes.ROUTE]: 'lime',
  [ExportTypes.PROPERTY]: 'purple',
  [ExportTypes.AGREEMENT]: 'geekblue',
  [ExportTypes.PRODUCT]: 'gray',
  [ExportTypes.HAULER_PRICE]: 'gray',
  [ExportTypes.TICKET_TYPE]: 'gray',
  [ExportTypes.SERVICE]: 'gray',
  [ExportTypes.COLLECTION_CALENDAR]: 'cyan',
  [ExportTypes.COLLECTION_CALENDAR_DATE]: 'green',
  [ExportTypes.CONTAINER_TYPE]: 'volcano',
  [ExportTypes.PICKUP_SETTING]: 'yellow',
  [ExportTypes.WASTE_FRACTION]: 'gold',
  [ExportTypes.VEHICLE]: 'pink',
  [ExportTypes.PICKUP_ORDER]: 'red',
}
