import { gql } from '@apollo/client'

import { FrgUserForLink } from 'src/api'

export const GET_TICKET_POPUP_INFO = gql`
  ${FrgUserForLink}
  query GetTicketPopupInfo($id: ID!) {
    ticket(id: $id) {
      id
      status
      priority
      ticketType {
        id
        name
      }
      location {
        name
      }
      assignee {
        ...FrgUserForLink
      }
      container {
        containerId
        containerType {
          name
        }
        wasteFraction {
          name
        }
        pickupSetting {
          name
        }
      }
    }
  }
`
