import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { CoreCompanyEmailAccountBillingStateChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const coreCompanyEmailAccountBillingStateChoicesLabelMap: Record<
  CoreCompanyEmailAccountBillingStateChoices,
  ReactNode
> = {
  CANCELLED: <T _str="Cancelled" />,
  DELETED: <T _str="Deleted" />,
  PAID: <T _str="Paid" />,
}
export const coreCompanyEmailAccountBillingStateChoicesTagColorMap: Record<
  CoreCompanyEmailAccountBillingStateChoices,
  TagProps['color']
> = {
  CANCELLED: 'orange',
  DELETED: 'red',
  PAID: 'green',
}

export const coreCompanyEmailAccountBillingStateChoicesOptions =
  createOptionsFromLabelMap(coreCompanyEmailAccountBillingStateChoicesLabelMap)
