import React, { FC, ReactNode } from 'react'
import Icon, * as icons from '@ant-design/icons'
import { T } from '@transifex/react'
import { Space } from 'antd'

import {
  WhIconAntdBatteryFilled,
  WhIconAntdCardboardFilled,
  WhIconAntdFoodAndDrinkFilled,
  WhIconAntdFoodFilled,
  WhIconAntdGlassFilled,
  WhIconAntdHardMetalFilled,
  WhIconAntdHardPlasticFilled,
  WhIconAntdPaperFilled,
  WhIconAntdPlasticFilled,
  WhIconAntdResidualFilled,
  WhIconAntdSoftPlasticFilled,
  WhIconAntdBookFilled,
  WhIconAntdBicycleFilled,
  WhIconAntdHazardousFilled,
  WhIconAntdFlatGlassFilled,
  WhIconAntdReuseFilled,
  WhIconAntdGardenWasteFilled,
  WhIconAntdSoilFilled,
  WhIconAntdCoffeeGroundFilled,
  WhIconAntdCompostFilled,
  WhIconAntdNaturalStoneFilled,
  WhIconAntdPaperForShreddingFilled,
  WhIconAntdRepairFilled,
  WhIconAntdShoesFilled,
  WhIconAntdClothingFilled,
  WhIconAntdStumpsAndRootsFilled,
  TWhAntdIconProps,
} from 'src/storybook/wh-icons-antd'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TDenmarkIconChoices =
  | 'Plastic'
  | 'Cardboard'
  | 'HardPlastic'
  | 'Metal'
  | 'Battery'
  | 'Food'
  | 'FoodAndDrink'
  | 'Residual'
  | 'Glass'
  | 'Paper'
  | 'SoftPlastic'
  | 'Book'
  | 'Bicycle'
  | 'Hazardous'
  | 'FlatGlass'
  | 'Reuse'
  | 'GardenWaste'
  | 'Soil'
  | 'CoffeeGround'
  | 'Compost'
  | 'NaturalStone'
  | 'PaperForShredding'
  | 'Repair'
  | 'Shoes'
  | 'Clothing'
  | 'StumpsAndRoots'

export const denmarkIconMap: Record<
  TDenmarkIconChoices,
  FC<TWhAntdIconProps>
> = {
  Plastic: WhIconAntdPlasticFilled,
  Residual: WhIconAntdResidualFilled,
  Battery: WhIconAntdBatteryFilled,
  Cardboard: WhIconAntdCardboardFilled,
  HardPlastic: WhIconAntdHardPlasticFilled,
  Book: WhIconAntdBookFilled,
  Paper: WhIconAntdPaperFilled,
  FoodAndDrink: WhIconAntdFoodAndDrinkFilled,
  Food: WhIconAntdFoodFilled,
  Glass: WhIconAntdGlassFilled,
  Metal: WhIconAntdHardMetalFilled,
  SoftPlastic: WhIconAntdSoftPlasticFilled,
  Bicycle: WhIconAntdBicycleFilled,
  Hazardous: WhIconAntdHazardousFilled,
  FlatGlass: WhIconAntdFlatGlassFilled,
  Reuse: WhIconAntdReuseFilled,
  GardenWaste: WhIconAntdGardenWasteFilled,
  Soil: WhIconAntdSoilFilled,
  CoffeeGround: WhIconAntdCoffeeGroundFilled,
  Compost: WhIconAntdCompostFilled,
  NaturalStone: WhIconAntdNaturalStoneFilled,
  PaperForShredding: WhIconAntdPaperForShreddingFilled,
  Repair: WhIconAntdRepairFilled,
  Shoes: WhIconAntdShoesFilled,
  Clothing: WhIconAntdClothingFilled,
  StumpsAndRoots: WhIconAntdStumpsAndRootsFilled,
}

export const denmarkIconLabelMap: Record<TDenmarkIconChoices, ReactNode> = {
  Plastic: <T _str="Plastic" />,
  Residual: <T _str="Residual" />,
  Battery: <T _str="Battery" />,
  Cardboard: <T _str="Cardboard" />,
  HardPlastic: <T _str="Hard plastic" />,
  SoftPlastic: <T _str="Soft plastic" />,
  Book: <T _str="Book" />,
  Paper: <T _str="Paper" />,
  FoodAndDrink: <T _str="Food and drink" />,
  Food: <T _str="Food" />,
  Glass: <T _str="Glass" />,
  Metal: <T _str="Metal" />,
  Bicycle: <T _str="Bicycle" />,
  Hazardous: <T _str="Hazardous waste" />,
  FlatGlass: <T _str="Flat glass" />,
  Reuse: <T _str="Reuse" />,
  GardenWaste: <T _str="Garden waste" />,
  Soil: <T _str="Soil" />,
  CoffeeGround: <T _str="Coffee ground" />,
  Compost: <T _str="Compost" />,
  NaturalStone: <T _str="Natural stone" />,
  PaperForShredding: <T _str="Paper for shredding" />,
  Repair: <T _str="Repair" />,
  Shoes: <T _str="Shoes" />,
  Clothing: <T _str="Clothing" />,
  StumpsAndRoots: <T _str="Stumps & roots" />,
}

export const denmarkIconOptions = createOptionsFromLabelMap(
  denmarkIconLabelMap
).map((option) => {
  const I = denmarkIconMap[option.value]
  return {
    ...option,
    label: (
      <Space>
        <I />
        {option.label}
      </Space>
    ),
  }
})

export const wasteHeroIconOptions = Object.entries(icons)
  .filter((e) => e?.[0].includes('Filled'))
  ?.map((j) => {
    const I = j[1] as typeof Icon
    return {
      id: j[0],
      label: (
        <Space>
          <I />
          {j[0]}
        </Space>
      ),
      value: j[0],
    }
  })
