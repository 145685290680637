import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { Row, Tag } from 'src/antd'

import { LevelChoices } from 'src/gen-types'
import { getOptsFromValueMap } from 'src/new-components/select/utils'

import {
  ticketTypeLevelTypeColorMap,
  ticketTypeLevelTypeLabelMap,
} from '../consts-client'

export type TCrmServiceServiceTypeChoices =
  | 'EXTRA_EMPTYING'
  | 'BULK_WASTE_PICKUP'
  | 'ADD_CONTAINER'
  | 'CHANGE_CONTAINER_PICKUP_SETTING'
  | `CHANGE_CONTAINER_ROUTE_SCHEME`
  | `CHANGE_CONTAINER_COLLECTION_CALENDAR`
  | 'CHANGE_CONTAINER_TYPE'
  | 'REMOVE_CONTAINER'
  | 'NEW_PROPERTY'
  | 'CONTACT_SYNC'
  | 'MISSING_COLLECTION'

export type TCrmServiceServiceTypeChoicesBulkTicketLegacyFlowSupport =
  OmitUnionString<
    TCrmServiceServiceTypeChoices,
    'CHANGE_CONTAINER_ROUTE_SCHEME' | 'CHANGE_CONTAINER_COLLECTION_CALENDAR'
  >

export enum TCrmServiceServiceTypeNoService {
  NO_SERVICE = 'NO_SERVICE',
}

export const crmServiceServiceTypeChoicesForManagedTicketMutation: TCrmServiceServiceTypeChoices[] =
  [
    'ADD_CONTAINER',
    'BULK_WASTE_PICKUP',
    'CHANGE_CONTAINER_PICKUP_SETTING',
    'CHANGE_CONTAINER_COLLECTION_CALENDAR',
    'CHANGE_CONTAINER_ROUTE_SCHEME',
    'CHANGE_CONTAINER_TYPE',
  ]

export type TCrmServiceServiceTypeChoicesFormSingleFlowSupport =
  TCrmServiceServiceTypeChoices

export const crmServiceServiceTypeChoicesLabelMap: Record<
  TCrmServiceServiceTypeChoices,
  ReactNode
> = {
  ADD_CONTAINER: <T _str="Add Container" />,
  BULK_WASTE_PICKUP: <T _str="Bulk Waste Pickup" />,
  CHANGE_CONTAINER_PICKUP_SETTING: <T _str="Change Container Pickup Setting" />,
  CHANGE_CONTAINER_TYPE: <T _str="Change Container Type" />,
  EXTRA_EMPTYING: <T _str="Extra Emptying" />,
  REMOVE_CONTAINER: <T _str="Remove Container" />,
  CHANGE_CONTAINER_COLLECTION_CALENDAR: (
    <T _str="Change container collection calendar" />
  ),
  CHANGE_CONTAINER_ROUTE_SCHEME: <T _str="Change container route scheme" />,
  CONTACT_SYNC: <T _str="Contact sync" />,
  NEW_PROPERTY: <T _str="New property" />,
  MISSING_COLLECTION: <T _str="Missing collection" />,
}

export const crmServiceServiceTypeChoicesOptions = getOptsFromValueMap(
  crmServiceServiceTypeChoicesLabelMap
)

export const crmServiceServiceTypeChoicesOptionsWithNoService = [
  {
    label: <T _str="No service" />,
    value: TCrmServiceServiceTypeNoService.NO_SERVICE,
  },
  ...crmServiceServiceTypeChoicesOptions,
]

export const crmServiceServiceTypeChoicesLevel: Record<
  TCrmServiceServiceTypeChoices,
  LevelChoices
> = {
  ADD_CONTAINER: LevelChoices.ONLY_CRM,
  BULK_WASTE_PICKUP: LevelChoices.ONLY_CRM,
  CHANGE_CONTAINER_PICKUP_SETTING: LevelChoices.ALL,
  CHANGE_CONTAINER_TYPE: LevelChoices.ALL,
  EXTRA_EMPTYING: LevelChoices.ALL,
  REMOVE_CONTAINER: LevelChoices.ALL,
  CHANGE_CONTAINER_COLLECTION_CALENDAR: LevelChoices.ALL,
  CHANGE_CONTAINER_ROUTE_SCHEME: LevelChoices.ALL,
  CONTACT_SYNC: LevelChoices.ONLY_CRM,
  NEW_PROPERTY: LevelChoices.ONLY_CRM,
  MISSING_COLLECTION: LevelChoices.ALL,
}

export const crmServiceServiceTypeChoicesOptionsWithTag =
  crmServiceServiceTypeChoicesOptions.map((option) => ({
    ...option,
    label: (
      <Row justify="space-between">
        {option.label}
        <Tag
          color={
            ticketTypeLevelTypeColorMap[
              crmServiceServiceTypeChoicesLevel[option.value]
            ]
          }
        >
          {
            ticketTypeLevelTypeLabelMap[
              crmServiceServiceTypeChoicesLevel[option.value]
            ]
          }
        </Tag>
      </Row>
    ),
    children: option.label,
  }))

export const crmServiceServiceTypeRequiringContainerSourceField: TCrmServiceServiceTypeChoices[] =
  ['ADD_CONTAINER', 'CHANGE_CONTAINER_TYPE']
export const crmServiceServiceTypeRequiringContainerDestinationField: TCrmServiceServiceTypeChoices[] =
  ['REMOVE_CONTAINER', 'CHANGE_CONTAINER_TYPE']
