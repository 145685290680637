import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { GenericAddRemoveEventTypesGraphene } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const ticketAttachmentsTypeEventLabelMap: Record<
  GenericAddRemoveEventTypesGraphene,
  ReactNode
> = {
  ADDED: <T _str="Added attachment" />,
  REMOVED: <T _str="Deleted attachment" />,
}

export const ticketAttachmentsTypeEventOptions = createOptionsFromLabelMap(
  ticketAttachmentsTypeEventLabelMap
)
