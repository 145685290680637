import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'src/antd'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TDepotStatus = 'open' | 'closed' | 'unknown'

export const depotStatusLabelMap: Record<TDepotStatus, ReactNode> = {
  open: <T _str="Open" />,
  closed: <T _str="Closed" />,
  unknown: <T _str="Unknown" />,
}

export const depotStatusTagColorMap: Record<TDepotStatus, TagProps['color']> = {
  open: 'green',
  closed: 'red',
  unknown: 'gray',
}

export const depotStatusOptions = createOptionsFromLabelMap(depotStatusLabelMap)
