import { pathJoin } from 'src/reducers/routing/utils'

export const createDetailRoutesMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chain,
  'collection-log': pathJoin(prefix)('collection-log').chain,
  history: pathJoin(prefix)('history').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  overview: pathJoin(prefix)('overview').chain,
  calendar: pathJoin(prefix)('calendar').chain,
  subscriptions: pathJoin(prefix)('subscriptions').chain,
  'export-runs': pathJoin(prefix)('export-runs').chain,
  contacts: pathJoin(prefix)('contacts').chain,
  agreements: pathJoin(prefix)('agreements').chain,
})
