import React from 'react'
import { ApolloProvider } from '@apollo/client'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'animate.css'
import 'antd/dist/antd.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { createRoot } from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'typeface-roboto'

import * as colors from 'src/styles/color-theme'

import App from './App'
import client from './components/graphql/apollo'
import './fonts.css'
import * as serviceWorker from './serviceWorker'
import { ErrorBoundary } from './setupSentry'
import './setupTransifex'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.mapstyles = process.env.REACT_APP_MAPBOX_STYLES

const root = createRoot(document.getElementById('main')!)
root.render(
  <ApolloProvider client={client}>
    <ErrorBoundary>
      <ThemeProvider theme={colors}>
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </ApolloProvider>
)

serviceWorker.unregister()
