import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { CoreCompanyEmailAccountSyncStateChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const coreCompanyEmailAccountSyncStateChoicesLabelMap: Record<
  CoreCompanyEmailAccountSyncStateChoices,
  ReactNode
> = {
  DOWNLOADING: <T _str="Downloading" />,
  EXCEPTION: <T _str="Exception" />,
  INITIALIZING: <T _str="Initializing" />,
  INVALID: <T _str="Invalid" />,
  INVALID_CREDENTIALS: <T _str="InvalidCredentials" />,
  PARTIAL: <T _str="Partial" />,
  RUNNING: <T _str="Running" />,
  STOPPED: <T _str="Stopped" />,
  SYNC_ERROR: <T _str="SyncError" />,
  VALID: <T _str="Valid" />,
}

export const coreCompanyEmailAccountSyncStateChoicesOptions =
  createOptionsFromLabelMap(coreCompanyEmailAccountSyncStateChoicesLabelMap)
