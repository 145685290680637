import React from 'react'

import { createAntdIcon } from '../createIcon'

export const WhIconAntdResidualFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.33066 5.05804L11.6722 3.30954C11.6722 3.30954 10.8349 3.61851 10.4081 3.04237C10.1807 2.73553 10.0121 2.46307 9.88653 2.28901C9.48126 1.72611 9.0632 2.30065 9.0632 2.30065L8.15497 4.43006L7.8532 1.41397C7.2607 3.00852 4.76685 1.72928 5.27272 2.71278L6.67401 5.05804H9.33066Z" />
      <path d="M13.3444 15.1374C13.3217 15.1141 13.1775 14.9602 13.0322 14.6867C12.8763 14.393 12.7269 13.9545 12.7164 13.3804C12.7153 13.354 12.7095 13.2339 12.7112 13.1979C12.7159 13.0762 12.7199 12.9561 12.7234 12.8376C12.7833 11.359 12.7566 8.7079 11.9117 7.27154C11.2861 6.20763 10.0447 5.53204 9.33181 5.32889H6.674C5.9594 5.53521 4.70753 6.21239 4.08422 7.27154C3.23937 8.7079 3.21262 11.359 3.27251 12.8376L3.28298 13.1C3.2853 13.1699 3.2853 13.2371 3.28356 13.3037C3.28356 13.3286 3.28239 13.3529 3.28123 13.3773C3.24867 14.4846 2.69048 15.184 2.64163 15.2432C2.63989 15.2459 2.62826 15.2628 2.62826 15.2628C2.60151 15.2961 2.58582 15.3358 2.58582 15.3792C2.58582 15.4934 2.69455 15.586 2.82886 15.586C2.86782 15.586 2.90678 15.5776 2.94632 15.5649C3.14517 15.5109 4.13596 15.2533 5.20351 15.2533C5.81578 15.2533 6.15942 15.3136 6.49142 15.3718C6.57922 15.3871 7.17056 15.4913 7.7305 15.4913C8.42184 15.4913 9.1161 15.4501 10.0057 14.9612C10.3494 15.0887 10.7087 15.1855 11.0576 15.2591C11.6884 15.3919 12.2827 15.449 12.6827 15.4728C12.9688 15.4897 13.1554 15.4903 13.1816 15.4897H13.1845C13.3113 15.4897 13.4142 15.3977 13.4142 15.2845C13.4142 15.2268 13.3851 15.1818 13.3444 15.1374Z" />
    </svg>
  )
})
