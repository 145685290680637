import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createCustomerCreateRouteMap } from './create'
import { createCustomerDetailRouteMap } from './id'

export const createCustomersRouteMap = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(
    createCustomerCreateRouteMap,
    {
      title: <T _str="Create Customer" />,
      breadCrumbTitle: <T _str="Create Customer" />,
    }
  ),
  ':id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createCustomerDetailRouteMap, {
      title: <T _str="Customer Detail" />,
      breadCrumbTitle: <T _str="Customer Detail" />,
    }),
})
