import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type TWasteFractionStyle = 'subtle' | 'bold' | 'minimal'

export const wasteFractionStyleLabelMap: Record<
  TWasteFractionStyle,
  ReactNode
> = {
  subtle: <T _str="Solid light" />,
  bold: <T _str="Solid dark" />,
  minimal: <T _str="Outline" />,
}

export const wasteFractionStyleOptions = createOptionsFromLabelMap(
  wasteFractionStyleLabelMap
)
