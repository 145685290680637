import { gql } from '@apollo/client'
import { message } from 'src/antd'

import apolloClient from '../components/graphql/apollo'

const MUTATION_IMPERSONATE_USER = gql`
  mutation mutationImpersonateUser($impersonatedId: ID!) {
    impersonate(impersonatedId: $impersonatedId) {
      token
    }
  }
`

export const impersonatorTokenKey = 'impersonatorToken'
export const impersonatorTokenActiveProjectsKey = 'impersonatorActiveProjects'

const removeImpersonatorKey = () => {
  localStorage.removeItem(impersonatorTokenKey)
  localStorage.removeItem(impersonatorTokenActiveProjectsKey)
}

export const impersonateUser = (impersonatedId: string) =>
  apolloClient
    .mutate({
      mutation: MUTATION_IMPERSONATE_USER,
      variables: {
        impersonatedId,
      },
      context: {
        useImpersonatorTokenIfExist: true,
      },
    })
    .then(({ data }) => {
      const isCurrentImpersonating =
        !!localStorage.getItem(impersonatorTokenKey)
      const token = data?.impersonate?.token
      // set this token to the new reactQLToken and current token to impersonator token
      const currentToken = localStorage.getItem('reactQLJWT')
      if (currentToken) {
        localStorage.setItem('reactQLJWT', token)
        // check if the user is already impersonating then do not set the impersonator token again.
        if (!isCurrentImpersonating)
          localStorage.setItem(impersonatorTokenKey, currentToken)
        // before next impersonation, clean previous active projects for this imperonator
        localStorage.removeItem(impersonatorTokenActiveProjectsKey)
        window.location.reload() // reload the whole app.
      }
    })
    .catch((e) => {
      message.error(e?.message)
    })

export const endImpersonatingUser = () => {
  const impersonatorToken = localStorage.getItem(impersonatorTokenKey)
  if (impersonatorToken) {
    localStorage.setItem('reactQLJWT', impersonatorToken)
    removeImpersonatorKey()

    window.location.reload() // reload the whole app.
  }
}

export const endImpersonatingBeforeLogout = () => {
  removeImpersonatorKey()
}
