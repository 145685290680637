import { gql } from '@apollo/client'

export const GET = gql`
  query GetUserInfoPopover($id: ID!) {
    user(id: $id) {
      id
      logo
      firstName
      lastName
      phoneNumber
      email
      companyRole {
        id
        name
      }
      isMaster
    }
  }
`
