import React, { FC } from 'react'
import { Dropdown as AntdDropdown, DropdownProps } from 'antd'
import DropdownButton from 'antd/lib/dropdown/dropdown-button'

const Dropdown: FC<DropdownProps> & {
  Button: typeof DropdownButton
} = ({ children, ...props }) => (
  <AntdDropdown
    getPopupContainer={(trigger) => trigger.parentElement!}
    {...props}
  >
    {children}
  </AntdDropdown>
)

Dropdown.Button = AntdDropdown.Button

export { Dropdown }
