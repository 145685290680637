import Color from 'color'

import {
  blue600,
  cyan500,
  geekBlue600,
  gold600,
  gray100,
  gray700,
  green500,
  lime600,
  magenta600,
  orange600,
  purple600,
  red600,
  volcano600,
  yellow600,
} from 'src/styles/color-theme'

import { TagColorType } from './types'

export const PRESET_COLORS: Record<TagColorType, string> = {
  gray: gray700,
  red: red600,
  orange: orange600,
  volcano: volcano600,
  yellow: yellow600,
  gold: gold600,
  green: green500,
  lime: lime600,
  cyan: cyan500,
  blue: blue600,
  geekblue: geekBlue600,
  purple: purple600,
  pink: magenta600,
}

export const generateBoldHoverStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  const darkenColor = Color(hexColor).darken(0.3).hex()
  return `
    &:hover {
      background-color: ${darkenColor};
      border-color: ${darkenColor};
      color: ${gray100};
    }
  `
}

export const generateBoldStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  return `
    background-color: ${hexColor};
    border-color: ${hexColor};
    color: ${gray100};
  `
}

export const generateSubtleHoverStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  const lightenColor = Color(hexColor).lighten(0.6).hex()
  const darkenColor = Color(hexColor).darken(0.6).hex()
  return `
    &:hover {
      background-color: ${lightenColor};
      border-color: ${lightenColor};
      color: ${darkenColor};
    }
  `
}

export const generateSubtleStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  const lightenColor = Color(hexColor).lighten(0.7).hex()
  const darkenColor = Color(hexColor).darken(0.6).hex()
  return `
    background-color: ${lightenColor};
    border-color: ${lightenColor};
    color: ${darkenColor};
  `
}

export const generateMinimalHoverStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  const lightenColor = Color(hexColor).lighten(0.9).hex()
  return `
    &:hover {
      background-color: ${lightenColor};
      border-color: ${hexColor};
      color: ${hexColor};
    }
  `
}

export const generateMinimalStyles = (color?: TagColorType) => {
  const hexColor = PRESET_COLORS[color || 'gray'] || color || PRESET_COLORS.gray
  return `
    background-color: transparent;
    border-color: ${hexColor};
    color: ${hexColor};
  `
}
