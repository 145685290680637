import React from 'react'
import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon'

const svg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" rx="2" fill="#FF4D4F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.134 3.1553C6.05574 3.09269 5.94454 3.09269 5.86628 3.1553L3.0684 5.39361C2.79116 5.6154 2.38661 5.57045 2.16482 5.29321C1.94303 5.01597 1.98798 4.61142 2.26522 4.38963L5.0631 2.15133C5.61093 1.71307 6.38936 1.71307 6.93718 2.15133L9.73506 4.38963C10.0123 4.61142 10.0573 5.01597 9.83546 5.29321C9.61367 5.57045 9.20912 5.6154 8.93188 5.39361L6.134 3.1553ZM6.134 7.3113C6.05574 7.2487 5.94454 7.2487 5.86628 7.3113L3.0684 9.54961C2.79116 9.7714 2.38661 9.72645 2.16482 9.44921C1.94303 9.17197 1.98798 8.76743 2.26522 8.54563L5.0631 6.30733C5.61093 5.86907 6.38936 5.86907 6.93718 6.30733L9.73506 8.54563C10.0123 8.76743 10.0573 9.17197 9.83546 9.44921C9.61367 9.72645 9.20912 9.7714 8.93188 9.54961L6.134 7.3113Z"
      fill="#F5F5F5"
    />
  </svg>
)

export const HighestPriority = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={svg} {...props} />
)
