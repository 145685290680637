import React from 'react'
import { T } from '@transifex/react'
import { PRESET_COLORS } from 'src/antd'

const presetColors = [
  {
    label: <T _str="Grey (default)" />,
    value: PRESET_COLORS.gray,
  },
  {
    label: <T _str="Red" />,
    value: PRESET_COLORS.red,
  },
  {
    label: <T _str="Orange" />,
    value: PRESET_COLORS.orange,
  },
  {
    label: <T _str="Yellow" />,
    value: PRESET_COLORS.yellow,
  },
  {
    label: <T _str="Green" />,
    value: PRESET_COLORS.green,
  },
  {
    label: <T _str="Cyan" />,
    value: PRESET_COLORS.cyan,
  },
  {
    label: <T _str="Blue" />,
    value: PRESET_COLORS.blue,
  },
  {
    label: <T _str="Purple" />,
    value: PRESET_COLORS.purple,
  },
]

export default presetColors
