import React from 'react'

import presetColors from './preset'
import { PresetName, PresetRow, PresetWrapper, StyledCircle } from './ui'

type TChromePresetsProps = {
  onChange: (color: string) => void
}

export default function ChromePresets({ onChange }: TChromePresetsProps) {
  return (
    <PresetWrapper>
      {presetColors?.map((preset) => (
        <PresetRow align="middle" onClick={() => onChange(preset.value)}>
          <StyledCircle $color={preset.value} />
          <PresetName>{preset.label}</PresetName>
        </PresetRow>
      ))}
    </PresetWrapper>
  )
}
