export default function replaceStringWithLetters(text: string): string {
  // Split the text into words
  const words = text.split(' ')
  let response = ''

  // Check if there are at least two words to get the letters from
  if (words.length >= 2) {
    // Get the first letter of the first word and the first letter of the last word
    const firstLetter = words[0][0]
    const lastLetter = words[words.length - 1][0]
    // Combine the two letters
    response = firstLetter + lastLetter
  }
  if (words.length === 1) {
    // If there's only one word, return the first two letters of that word
    response = words[0].substring(0, 2).toUpperCase()
  }

  return response.toUpperCase()
}
