import React from 'react'
import { Popover as AntdPopover, PopoverProps } from 'antd'

export const Popover = ({ children, ...props }: PopoverProps) => (
  <AntdPopover
    getPopupContainer={(trigger) => trigger.parentElement!}
    {...props}
  >
    {children}
  </AntdPopover>
)
