import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { ImportFormats } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const importFormatLabelMap: Record<ImportFormats, ReactNode> = {
  [ImportFormats.CSV]: <T _str="CSV" />,
  [ImportFormats.XLSX]: <T _str="XLSX" />,
}

export const importFormatOptions =
  createOptionsFromLabelMap(importFormatLabelMap)
